import { useEffect, useState } from 'react';

function useWindowSize() {
  const isClient = typeof window === 'object';

  const getSize = () => {
    return {
      vwWidth: isClient ? window.innerWidth : 0,
      vwHeight: isClient ? window.innerHeight : 0
    };
  }

  const [windowSize, setWindowSize] = useState(getSize());

  function handleResize() {
    const newSize = getSize()
    if (newSize.vwWidth === windowSize.vwWidth) { // fix for change of height when scrolling mobile chrome 
      return
    }
    setWindowSize(newSize);
  }

  useEffect(() => {

    if (!isClient) {
      return;
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount
  return windowSize;
}

export default useWindowSize;
