import { endpoints } from '@domain/constants';
import { convertKeys, pascalToCamel } from '@domain/helpers';
import { ServerResponseVIP } from '@domain/interfaces';

async function getCase(uuid: string): Promise<ServerResponseVIP> {

  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.append('Content-Type', 'application/json');
  requestHeaders.append('uuid', uuid);

  const config = {
    method: 'GET',
    headers: requestHeaders,
  }

  const res = (await fetch(endpoints.getCase, config))
    .json()
    .then(body => convertKeys(body, pascalToCamel));
  return res as Promise<ServerResponseVIP>;
}

export default getCase;
