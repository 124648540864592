import { useState, useEffect } from 'react';

function useSubdomain() {
  const [subdomain, setSubdomain] = useState<string | null>(null);
  const host = window.location.host;

  function extractSubdomain(splittedUrl: string[]) {

    if (splittedUrl.length < 3) {
      return '';
    }
    return splittedUrl[0] === 'www' ? splittedUrl[1] : splittedUrl[0];
  }

  useEffect(() => {
    const parts = host.split(".");
    const subd = extractSubdomain(parts)
    // If we get more than 3 parts, then we have a subdomain
    // INFO: This could be 4, if you have a co.uk TLD or something like that.


    if (subd === '') {
      setSubdomain(subd);
      return;
    }
    const subdomainValid =
      subd === ''
        || subd === '192'
        || subd === 'undefined'
        || subd === 'test'
        || subd === 'accp'
        || subd === 'www'
        || subd === 'testing'
        || subd === 'steering-portal-test'
        || subd === 'steering-portal-testing'
        || subd === 'steering-portal-accp'
        || subd === 'steering-portal'
        || subd === 'vindmijnhersteller'
        || subd === 'mijnschade'
        || subd === 'mijnschades'
        ? false : true;

    if (subdomainValid) {
      setSubdomain(subd)
      localStorage.setItem('subdomain', subd);
    } else {
      setSubdomain('');
    }
    return;

  }, [host, subdomain]);

  return subdomain;
}

export default useSubdomain;
