import { GET_BRANDING } from '../shared/action-names';
import { brandingActionTypes, BrandingState } from './types';

const initialState: BrandingState = null;

export default function (
  state = initialState,
  action: brandingActionTypes,
): BrandingState {
  switch (action.type) {
    case GET_BRANDING:
      return { ...action.payload };

    default:
      return state;
  }
}
