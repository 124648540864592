interface Error {
  path: string;
  message: string;
}

function mapYupValidationSchemaErrors(errors: Error[]): Record<string, string> {
  return errors.reduce(
    (acc, error) => ({ ...acc, [error.path]: error.message }),
    {},
  );
}

export default mapYupValidationSchemaErrors;
