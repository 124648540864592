import { convertKeys, pascalToCamel, camelToPascal } from '@domain/helpers';

interface GetConfig {
  method: 'GET';
  headers?: any,
}

type postHeaders = {
  'Content-Type': 'application/json';
};

type postHeadersDI = {
  'Content-Type': 'application/json';
} & ({ PID: String } | { PID: String; FID: String });

type postHeadersLP = {
  'Content-Type': 'application/json';
} & ({ PID: String } | { PID: String; FID: String });

interface PostConfigBase {
  method: 'POST';
  headers: postHeaders;
}

interface PostConfigBaseDI {
  method: 'POST';
  headers: postHeadersDI;
}

interface PostConfigBaseLP {
  method: 'POST';
  headers: postHeadersLP;
}

interface PostConfigFetch extends PostConfigBase {
  body: string;
}

interface PostConfigFetchDI extends PostConfigBaseDI {
  body: string;
}

interface PostConfigFetchLP extends PostConfigBaseLP {
  body: string;
}

interface PostConfig extends PostConfigBase {
  body: object;
}

interface PostConfigDI extends PostConfigBaseDI {
  body: object;
}

interface PostConfigLP extends PostConfigBaseLP {
  body: object;
}

async function makeCall<T>(
  url: string,
  config: GetConfig | PostConfig | PostConfigDI | PostConfigLP
): Promise<T> {
  const fetchConfig:
    GetConfig
    | PostConfigFetch
    | PostConfigFetchDI
    | PostConfigFetchLP =
    config.method === 'GET'
      ? config
      : {
        ...config,
        body: JSON.stringify(convertKeys(config.body, camelToPascal)),
      };

  const res = await fetch(url, fetchConfig);
  if (res.ok && res.status === 200) {
    const body = await res
      .json()
      .then((body) => convertKeys(body, pascalToCamel));
    return body as Promise<T>;
  } else {
    const stringifiedErrors = await res.text();
    const error = JSON.parse(stringifiedErrors);
    throw error;
  }
}

export default makeCall;
