
import {LogoSizeOptions} from '@domain/interfaces'

export default function chooseLogoSize(size?: LogoSizeOptions) {
    switch (size) {
        case 'XS':
          return 80;
          case 'S':
            return 95;
            case 'M':
          return 110;
          case 'L':
          return 125;
          case 'XL':
          return 140;
        default:
          return 85;
      }

  }