import React, { FC } from 'react';
import { styled } from '@mui/material';

import { Colors, Scaling } from '@domain/theming';
import Color from 'color';
import { useWindowSize } from '@domain/hooks';

interface Props {
  className?: string,
  children?: React.ReactNode
  dark?: boolean;
  size?: 'sm' | 'lg';
  justifyContent?: string;
  alignItems?: string;
  transparent?: boolean;
  vwWidth?: number;
}

interface StyledProps {
  className?: string,
  $dark?: boolean;
  $size?: 'sm' | 'lg';
  $justifyContent?: string;
  $alignItems?: string;
  $transparent?: boolean;
  $vwWidth?: number;
}

const SectionContainer = styled('section', {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
}) <StyledProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  width: 100%;
  background: ${props => props.$transparent ? 'transparent' : !props.$dark ? props.theme.isThemed ? Color(props.theme.palette.secondary.main).lightness(92).hex() : Colors.GREY_LIGHTER : props.theme.isThemed ? Color(props.theme.palette.secondary.main).lightness(96).hex() : Colors.WHITE};
  padding: 60px 80px;
  @media screen and (max-width: 900px) {
    padding: 40px;
  }
  @media screen and (max-width: 420px) {
    padding: 40px 20px;
  }
`;

const SectionInner = styled('div') <StyledProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${props => (props.$size === 'lg' ? Scaling.scaleUpTo4K(1200, props.$vwWidth) + 'px' : '900px')};
  justify-content: ${props => props.$justifyContent};
  align-items: ${props => props.$alignItems};
`;

const Section: FC<Props> = ({
  className,
  dark,
  size,
  children,
  transparent
}) => {

  const { vwWidth } = useWindowSize();
  return (
    <SectionContainer
      $dark={dark}
      className={className}
      $transparent={transparent}
    >
      <SectionInner
        $size={size}
        $vwWidth={vwWidth}
      >
        {children}
      </SectionInner>
    </SectionContainer>
  )
}
export default Section;
