import React, { FC } from 'react';
import { styled } from '@mui/material';
import { Colors } from '@domain/theming';
import { isMobile } from 'react-device-detect';
import { useWindowSize } from '@domain/hooks';
import { Scaling } from '@domain/theming';

interface StyledErrorMessageProps {
  $dynamicFontSize?: boolean,
  $vwWidth: number
}

interface Props {
  dynamicFontSize?: boolean,
  children: any,
}
const StyledErrorMessage = styled('span', {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
}) <StyledErrorMessageProps>`
  color: ${Colors.RED};
  font-size: ${props => props.$dynamicFontSize ? (isMobile
    ? Scaling.textFontSizeMobile(12, props.$vwWidth)
    : Scaling.textFontSize(12, props.$vwWidth)) + 'px' : '12px'};
  line-height: ${props => props.$dynamicFontSize ? (isMobile
    ? Scaling.textFontSizeMobile(14, props.$vwWidth)
    : Scaling.textFontSize(14, props.$vwWidth)) + 'px' : '14px'};
`;

const ErrorMessage: FC<Props> = ({
  dynamicFontSize,
  children
}) => {

  const { vwWidth } = useWindowSize();
  return (
    <StyledErrorMessage
      $dynamicFontSize={dynamicFontSize}
      $vwWidth={vwWidth}
    >
      {React.Children.toArray(children)}
    </StyledErrorMessage>
  );
};
export default ErrorMessage;
