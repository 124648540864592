import React, { FC, useContext } from 'react';
import { useSelector } from 'react-redux';

import { FormWrapperDI, FormWrapperIntake, FormWrapperIntakePlus, FormWrapperIntakeSpecial, FormWrapperSG, InfoBlock } from '@domain/blocks'
import { Background, Container, Header, Loader, Logo, Paragraph } from '@domain/components';
import { companyNames } from '@domain/constants';
import { chooseLogoSize, generateContent } from '@domain/helpers';
import { useWindowSize } from '@domain/hooks';
import { ContentOptions, ContentStructureFormBlock, ContentStructureInfoBlock, LayoutOptions } from '@domain/interfaces';
import { RootState } from '@domain/root-reducer';
import { Colors, Gradients, Scaling, Shadows } from '@domain/theming';
import Color from 'color'
import { motion } from 'framer-motion';
import { styled, useTheme } from '@mui/material';
import { isIE } from 'react-device-detect';

interface Props {
  contentType: ContentOptions,
  layoutType: LayoutOptions,
}

const StyledDiv = styled('div')`
min-height: 100vh;
.screen-ie & {
  .landing__card-container {
    &--inner {
      min-height: 100vh;
    }
  }
}

`

const StyledContainer = styled(
  motion(Container) as any, {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
}
)`
  .landing__intro-text {
    position: relative;
    &--inner {
      // min-height: 100%;
      top: 0px;
    }
    &--content {
      width: 100%;
      padding-bottom: ${props => Scaling.scaleUpTo4K(20, props.$vwWidth)}px;
    }
    &--signature {
      p {
        padding-bottom: 2px;
      }
      .logo {
        margin: 8px 8px 0;
      }
    }
    h1 {
      color: ${props => props.theme.isCustomThemed ? Colors.GREY_DARK : props.theme.isThemed && Colors.WHITE};
      text-shadow: ${props => props.theme.isCustomThemed ? 'none' : props.theme.isThemed && `-${Scaling.scaleUpTo4K(1, props.$vwWidth)}px ${Scaling.scaleUpTo4K(1, props.$vwWidth)}px ${Scaling.scaleUpTo4K(2, props.$vwWidth)}px` + Color(props.theme.palette.primary.main).rgb().alpha(0.5)};
    }
    p {
      margin: 0;
      color: ${props => props.theme.isCustomThemed ? Colors.GREY_DARK : props.theme.isThemed && Colors.WHITE};
      text-shadow: ${props => props.theme.isCustomThemed ? 'none' : props.theme.isThemed && `-${Scaling.scaleUpTo4K(1, props.$vwWidth)}px ${Scaling.scaleUpTo4K(1, props.$vwWidth)}px ${Scaling.scaleUpTo4K(2, props.$vwWidth)}px` + Color(props.theme.palette.primary.main).rgb().alpha(0.3).string()};
    }
    hr {
    margin-top: 20px;
    background-color: ${props => props.theme.isCustomThemed ? Colors.GREY_DARK : props.theme.isThemed && Colors.WHITE};
    }
    a {
      text-decoration: none;
      color: ${props => props.theme.isCustomThemed ? Colors.GREY_DARK : props.theme.isThemed && Colors.WHITE};
    }
    ul {
      margin: ${props => Scaling.scaleUpTo4K(15, props.$vwWidth)}px 0;
      font-size: ${props => Scaling.scaleUpTo4K(16, props.$vwWidth)}px;
      list-style: none;
      padding-left: ${props => Scaling.scaleUpTo4K(30, props.$vwWidth)}px;
      padding-inline: 0;
      li {
        margin-bottom: ${props => Scaling.scaleUpTo4K(8, props.$vwWidth)}px;
        display: flex;
        align-items: center;
        &::before {
          content: "•";
          color: ${props => props.theme.isCustomThemed ? Colors.GREY_DARK : props.theme.isThemed && Colors.WHITE};
          font-weight: bold;
          text-align:center;
          flex: 0 0 ${props => Scaling.scaleUpTo4K(30, props.$vwWidth)}px;
        }
        p {
          text-align: left;
          flex: 1 1 auto;
        }
      }
    }
  }
  .landing__card-container {
    position: relative;
    &--inner {
     
      btn.main__button--redirect {
        color: ${props => props.theme.colorAsBackground ? Colors.BLACK : props.theme.palette.primary.main}
      }
    }
  }

  &.spl1 {
    min-height: 100vh;
    .header {
      height: ${props => Scaling.scaleUpTo4K(120, props.$vwWidth)}px;
    }
    .landing__intro-text {
      &--content {
        padding-bottom: 0;
        h1 {
          color: ${props => props.theme.colorAsBackground ? Colors.WHITE : props.theme.palette.primary.main};
          text-shadow: ${props => props.theme.colorAsBackground ? 'none' : `-${Scaling.scaleUpTo4K(1, props.$vwWidth)}px ${Scaling.scaleUpTo4K(1, props.$vwWidth)}px ${Scaling.scaleUpTo4K(2, props.$vwWidth)}px` + Color(props.theme.palette.primary.main).rgb().alpha(0.5)};
        }
        p {
          margin: 0;
          color: ${props => props.theme.colorAsBackground ? Colors.WHITE : Colors.GREY_DARK};
          text-shadow: ${props => props.theme.colorAsBackground ? 'none' : `-${Scaling.scaleUpTo4K(1, props.$vwWidth)}px ${Scaling.scaleUpTo4K(1, props.$vwWidth)}px ${Scaling.scaleUpTo4K(2, props.$vwWidth)}px` + Color(Colors.WHITE).rgb().alpha(0.3).string()};
        }
        a {
          color: ${props => props.theme.colorAsBackground ? Colors.WHITE : props.theme.palette.primary.main};
          text-shadow: ${props => props.theme.colorAsBackground ? 'none' : `-${Scaling.scaleUpTo4K(1, props.$vwWidth)}px ${Scaling.scaleUpTo4K(1, props.$vwWidth)}px ${Scaling.scaleUpTo4K(2, props.$vwWidth)}px` + Color(Colors.WHITE).rgb().alpha(0.3).string()};
        }
        svg {
          color: ${props => props.theme.colorAsBackground ? Colors.WHITE : Colors.GREY_DARK};
        }
        ul {
          li {
            &::before {
              color: ${props => props.theme.colorAsBackground ? Colors.WHITE : Colors.GREY_DARK};
            }
            p {
              color: ${props => props.theme.colorAsBackground ? Colors.WHITE : Colors.GREY_DARK};
            }
            svg {
              color: ${props => props.theme.colorAsBackground ? Colors.WHITE : Colors.GREY_DARK};
              fill: ${props => props.theme.colorAsBackground ? Colors.WHITE : Colors.GREY_DARK};
            }
          }
        }
      }
    }
    .landing__card-container {
      &--inner {
        flex-grow: 1;
        width: 100%;
        .main__button--redirect {
          color: ${props => props.theme.colorAsBackground ? Colors.BLACK : props.theme.palette.primary.main};
          svg {
            fill: ${props => props.theme.colorAsBackground ? Colors.BLACK : props.theme.palette.primary.main};
            color: ${props => props.theme.colorAsBackground ? Colors.BLACK : props.theme.palette.primary.main};
          }
        }
        h3 {
          color: ${props => props.theme.isCustomThemed ? Colors.GREY_DARK : props.theme.colorAsBackground ? Colors.WHITE : Colors.GREY_DARK};
        }
        p {
          color: ${props => props.theme.isCustomThemed ? Colors.GREY_DARK : props.theme.colorAsBackground ? Colors.WHITE : Colors.GREY_DARK};
        }
        ul {
          li {
            &:before {
              color: ${props => props.theme.isCustomThemed ? Colors.GREY_DARK : props.theme.colorAsBackground ? Colors.WHITE : Colors.GREY_DARK};
            }
            svg {
              color: ${props => props.theme.isCustomThemed ? Colors.GREY_DARK : props.theme.colorAsBackground ? Colors.WHITE : Colors.GREY_DARK};
              fill: ${props => props.theme.colorAsBackground ? Colors.WHITE : Colors.GREY_DARK};
            }
          }
        }
        .volgende-button {
          background: ${props => !props.theme.colorAsBackground && props.theme.palette.primary.main};
          transition: background ease-in-out .25s;
          &.disabled {
            background: ${props => !props.theme.colorAsBackground && Colors.WHITE}
          }
        }
      }
    }
  }
  &.spl2 {
    .landing__intro-text {
      &--inner {
        // padding: ${props => Scaling.scaleUpTo4K(60, props.$vwWidth)}px;
      }
    }
  }
  &.spl3 {
    min-height: 100vh;
    .landing__card-container {
      position: relative;
      box-shadow:  ${props => !props.theme.isCustomThemed && Shadows().Shadow6};
    }
  }

  &.spl4 {
    min-height: 100vh;
  .landing__intro-text {
    &--content {
      h1 {
        color: ${props => props.theme.colorAsBackground ? Colors.WHITE : props.theme.palette.primary.main};
        text-shadow: ${props => props.theme.colorAsBackground ? 'none' : `-${Scaling.scaleUpTo4K(1, props.$vwWidth)}px ${Scaling.scaleUpTo4K(1, props.$vwWidth)}px ${Scaling.scaleUpTo4K(2, props.$vwWidth)}px` + Color(props.theme.palette.primary.main).rgb().alpha(0.5)};
      }
      p {
        margin: 0;
        color: ${props => props.theme.colorAsBackground ? Colors.WHITE : Colors.GREY_DARK};
        text-shadow: ${props => props.theme.colorAsBackground ? 'none' : `-${Scaling.scaleUpTo4K(1, props.$vwWidth)}px ${Scaling.scaleUpTo4K(1, props.$vwWidth)}px ${Scaling.scaleUpTo4K(2, props.$vwWidth)}px` + Color(Colors.WHITE).rgb().alpha(0.3).string()};
      }
      svg {
        color: ${props => props.theme.colorAsBackground ? Colors.WHITE : Colors.GREY_DARK};
      }
      a {
        color: ${props => props.theme.colorAsBackground ? Colors.WHITE : props.theme.palette.primary.main};
        text-shadow: ${props => props.theme.colorAsBackground ? 'none' : `-${Scaling.scaleUpTo4K(1, props.$vwWidth)}px ${Scaling.scaleUpTo4K(1, props.$vwWidth)}px ${Scaling.scaleUpTo4K(2, props.$vwWidth)}px` + Color(Colors.WHITE).rgb().alpha(0.3).string()};
      }
    }
   }
   .landing__card-container {
    &--inner {
      .single-card {
        border:none;
        border-radius:20px;
        background: rgba(255,255,255, 0.55);
        h4 {
          font-size: ${props => Scaling.textFontSize(20, props.$vwWidth)}px;
          color: ${Colors.GREY_DARK}
        }
      }
    }

  }
  }

  .screen-ie & {
    .landing__card-container {
      &--inner {
        height: 0px;
      }
    }
    &.spl1 {
      .landing__card-container {
        &--inner {
          height: auto;
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    .landing__intro-text {
      margin: 0 auto;
      padding-right: 0;
      min-width: auto;
      flex: 1 0 auto;
      &--inner {
        min-height: auto;
        width: 100%;
        padding-bottom: 60px;
      }
      h1, p {
        text-align: center;
      }
      ul {
        max-width: 300px;
        margin: 15px auto;
        li {
        }
      }
    }
    .landing__card-container {
      overflow: visible;
      flex: 1 0 auto;
      &--inner {
        width: 100%;
        min-height: initial;
        padding: 80px 40px 140px;
      }
    }

    &.spl1 {
      .background-container {
        position:fixed;
      }
      .landing__intro-text {
        &--inner {
          // padding: 120px 60px 0px;
        }
        &--content {
          max-width: 100%;
        }
      }
      .landing__card-container {
        &--inner {
          padding: 20px 60px 60px;
        }
      }
    }
    
    &.spl2 {
      .background-container {
        position:fixed;
      }
      .landing__intro-text {
        &--inner {
          padding-top: 160px;
        }
      }
      .landing__card-container {
        &--inner {
          padding: 40px 40px 140px;
        }
      }
    }
    &.spl4 {
      .background-container {
        position:fixed;
      }
      .landing__intro-text {
        &--inner {
          padding-top: 160px;
        }
      }
      .landing__card-container {
        &--inner {
          padding: 40px 40px 140px;
        }
      }
    }
  }


  @media screen and (max-width: 540px) {
    p {
      text-align:justify !important;
    }
    .container.landing__intro-text {
      &--inner {
        padding: 120px 30px 30px;
      }
    }

    &.spl1 {
      text-align:justify !important;
      .landing__intro-text {
        &--inner {
          // padding: 80px 30px 0px;
        }
      }
      .landing__card-container {
        &--inner {
          padding: 15px 30px 30px;
        }
      }
    }

    
    &.spl2 {
      text-align:justify !important;
      .landing__intro-text {
        &--inner {
          padding: 60px 30px 30px;
        }
      }
      .landing__card-container {
        &--inner {
          padding: 15px 30px 30px;
        }
      }
    }

    
    &.spl3 {
      text-align:justify !important;
      .landing__intro-text {
        &--inner {
          // padding: 120px 30px 30px;
        }
      }
      .landing__card-container {
        &--inner {
          padding: 60px 30px 90px;
        }
      }
    }

    &.spl4 {
      text-align:justify !important;
      .landing__intro-text {
        &--inner {
          // padding: 120px 30px 30px;
        }
      }
      .landing__card-container {
        &--inner {
          padding: 15px 30px 30px;
        }
      }
    }
    
  }

  .screen-mobile & {
  }
`;

const GenerateLayout: FC<Props> = ({ contentType, layoutType }) => {
  const themeContext = useTheme();
  const branding = useSelector((state: RootState) => state.branding)
  const redirectUrls = useSelector((state: RootState) => state.redirectUrls)
  const { vwWidth } = useWindowSize();


  if (!branding) {
    return <Loader />;
  }

  const colorAsBackground = branding.colorAsBackground || true
  const headerBackground = branding.headerBackground || false

  const infoBlockContent = generateContent(contentType, branding, redirectUrls, "infoBlock") as ContentStructureInfoBlock;
  const formBlockContent = generateContent(contentType, branding, redirectUrls, "formBlock") as ContentStructureFormBlock;

  const InfoBlockWrapper: FC<{ addDivider?: number }> = ({ addDivider }) => {

    const signatureText = infoBlockContent["signature-text"]
    const signatureLogo = infoBlockContent["signature-logo"]

    const hasInfoBlockBackground = layoutType === 'DI';
    const hasInfoBlockHeader = layoutType === 'DI';
    const hasSignature = layoutType === 'landing' && branding.brand === companyNames.Rhion;

    return (
      <Container
        className="landing__intro-text"
        boxSizing="content-box"
        flexDirection="column"
        flexGrow={layoutType === 'DI' ? '0' : '1'}
        flexShrink="0"
        flexBasis={layoutType === 'DI' ? '40%' : '0px'}
        alignItems="center"
        justifyContent="center"
        margin="0"
        padding="0"
        position={layoutType === 'DI' ? 'sticky' : 'relative'}
      >
        <Container
          className="landing__intro-text--inner"
          // flexDirection="column"
          flexGrow="1"
          flexShrink="0"
          flexBasis="auto"
          justifyContent="center"
          alignItems="center"
          padding={layoutType === 'DI' ? Scaling.scaleUpTo4K(140, vwWidth) + 'px ' + Scaling.scaleUpTo4K(60, vwWidth) + 'px' : Scaling.scaleUpTo4K(120, vwWidth) + 'px ' + Scaling.scaleUpTo4K(60, vwWidth) + 'px'}
          maxWidth={layoutType !== 'DI' ? Scaling.scaleUpTo4K(640, vwWidth) + 'px ' : '100%'}
        >
          {hasInfoBlockBackground &&
            <Background
              // variants={imgVariants}
              // initial="hidden"
              // animate="visible"
              imageName="BSSP01.jpg"
              overlayColor={Gradients(themeContext).BACKGROUND_DI}
            />}
          {hasInfoBlockHeader && <Header shadow={headerBackground} imgSrc={(!!branding && branding.logoURL) || ''} height={chooseLogoSize(themeContext?.logoSize)} />}
          <InfoBlock content={infoBlockContent} addDivider={addDivider} />
          {hasSignature &&
            <Container
              className="landing__intro-text--signature"
              alignItems="flex-end"
              justifyContent="center"
              flexGrow="0"
              flexBasis={Scaling.scaleUpTo4K(80, vwWidth) + 'px'}
              padding={Scaling.scaleUpTo4K(20, vwWidth) + 'px'}
            >
              {signatureText && <Paragraph>{signatureText}</Paragraph>}
              {signatureLogo && <Logo maxWidth="80px" imgURL={signatureLogo} />}
            </Container>}
        </Container>
      </Container>
    );
  }

  const CardBlockWrapper: FC<{ children?: React.ReactNode }> = ({ children }) => {

    return (
      <Container
        className="landing__card-container"
        // background={Colors.WHITE}
        flexGrow="1"
        flexShrink="0"
        flexBasis={layoutType === 'DI' ? '60%' : '0px'}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Container
          className="landing__card-container--inner"
          flexDirection="column"
          flexGrow="0"
          alignItems="center"
          padding={layoutType === 'DI' ? Scaling.scaleUpTo4K(140, vwWidth) + 'px ' + Scaling.scaleUpTo4K(60, vwWidth) + 'px' : Scaling.scaleUpTo4K(60, vwWidth) + 'px'}
          maxWidth={layoutType === 'DI' ? '100%' : Scaling.scaleUpTo4K(640, vwWidth) + 'px '}
        >
          {children}
        </Container>
      </Container>
    )
  }

  if (layoutType === 'landing') {
    return (
      <>
        <StyledContainer
          className='flex-container spl4'
          justifyContent="space-between"
          $hasTopBar={true}
          $vwWidth={vwWidth}
        >
          <Background
            imageName="slo-mo_bg.jpg"
            overlayColor={colorAsBackground ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.06)'}
            fixed={isIE}
          />
          <Header background={headerBackground ? 'rgba(255,255,255,0.9)' : 'transparent'} shadow={headerBackground} imgSrc={(!!branding && branding.logoURL) || ''} height={chooseLogoSize(themeContext?.logoSize)} />
          <InfoBlockWrapper addDivider={1} />
          <CardBlockWrapper>
            <FormWrapperSG content={formBlockContent} />
          </CardBlockWrapper>
        </StyledContainer>
      </>)
  }
  if (layoutType === 'SG') {
    return <StyledDiv>
      <Header fixed={false} shadow={true} background={Gradients().HEADER} imgSrc={(!!branding && branding.logoURL) || ''} height={chooseLogoSize(themeContext?.logoSize)} />

      <StyledContainer
        className='flex-container spl2'
        justifyContent="space-between"
        $hasTopBar={true}
        $vwWidth={vwWidth}
      >
        <InfoBlockWrapper />
        <CardBlockWrapper>
          <FormWrapperSG content={formBlockContent} />
        </CardBlockWrapper>
      </StyledContainer>
    </StyledDiv>
  }
  if (layoutType === 'DI') {
    return (
      <StyledContainer
        className='flex-container spl3'
        justifyContent="space-between"
        $vwWidth={vwWidth}
      >
        <InfoBlockWrapper />
        <CardBlockWrapper>
          <FormWrapperDI content={formBlockContent} />
        </CardBlockWrapper>
      </StyledContainer>)
  }
  if (layoutType === 'dealerSaas') {
    return <>
      <StyledContainer
        className='flex-container spl4'
        justifyContent="space-between"
        $hasTopBar={true}
        $vwWidth={vwWidth}
      >
        <Background
          imageName="slo-mo_bg.jpg"
          overlayColor={colorAsBackground ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.06)'}
          fixed={isIE}
        />
        <Header background={headerBackground ? 'rgba(255,255,255,0.9)' : 'transparent'} shadow={headerBackground} imgSrc={(!!branding && branding.logoURL) || ''} height={chooseLogoSize(themeContext?.logoSize)} />
        <InfoBlockWrapper />
        <CardBlockWrapper>
          <FormWrapperSG content={formBlockContent} />
        </CardBlockWrapper>
      </StyledContainer>
    </>
  }
  if (layoutType === 'intakePlus') {
    return <>
      <StyledContainer
        className='flex-container spl4'
        justifyContent="space-between"
        $hasTopBar={true}
        $vwWidth={vwWidth}
      >
        <Background
          imageName="slo-mo_bg.jpg"
          overlayColor={colorAsBackground ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.06)'}
          fixed={isIE}
        />
        <Header background={headerBackground ? 'rgba(255,255,255,0.9)' : 'transparent'} shadow={headerBackground} imgSrc={(!!branding && branding.logoURL) || ''} height={chooseLogoSize(themeContext?.logoSize)} />
        <InfoBlockWrapper />
        <CardBlockWrapper>
          <FormWrapperIntakePlus content={formBlockContent} />
        </CardBlockWrapper>
      </StyledContainer>
    </>
  }
  if (layoutType === 'intake') {
    return <>
      <StyledContainer
        className='flex-container spl4'
        justifyContent="space-between"
        $hasTopBar={true}
        $vwWidth={vwWidth}
      >
        <Background
          imageName="slo-mo_bg.jpg"
          overlayColor={colorAsBackground ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.06)'}
          fixed={isIE}
        />
        <Header background={headerBackground ? 'rgba(255,255,255,0.9)' : 'transparent'} shadow={headerBackground} imgSrc={(!!branding && branding.logoURL) || ''} />
        <InfoBlockWrapper />
        <CardBlockWrapper>
          <FormWrapperIntake content={formBlockContent} />
        </CardBlockWrapper>
      </StyledContainer>
    </>
  }
  if (layoutType === 'intakeSpecial') {
    return <>
      <StyledContainer
        className='flex-container spl4'
        justifyContent="space-between"
        $hasTopBar={true}
        $vwWidth={vwWidth}
      >
        <Background
          imageName="slo-mo_bg.jpg"
          overlayColor={colorAsBackground ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.06)'}
          fixed={isIE}
        />
        <Header background={headerBackground ? 'rgba(255,255,255,0.9)' : 'transparent'} shadow={headerBackground} imgSrc={(!!branding && branding.logoURL) || ''} />
        <InfoBlockWrapper />
        <CardBlockWrapper>
          <FormWrapperIntakeSpecial content={formBlockContent} />
        </CardBlockWrapper>
      </StyledContainer>
    </>
  }
  if (layoutType === 'dealerDistribution') {
    return <>
      <StyledContainer
        className='flex-container spl4'
        justifyContent="space-between"
        $hasTopBar={true}
        $vwWidth={vwWidth}
      >
        <Background
          imageName="slo-mo_bg.jpg"
          overlayColor={colorAsBackground ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.06)'}
          fixed={isIE}
        />
        <Header background={headerBackground ? 'rgba(255,255,255,0.9)' : 'transparent'} shadow={headerBackground} imgSrc={(!!branding && branding.logoURL) || ''} />
        <InfoBlockWrapper />
        <CardBlockWrapper>
          <FormWrapperIntake content={formBlockContent} />
        </CardBlockWrapper>
      </StyledContainer>
    </>
  }
  return null
}
export default GenerateLayout;
