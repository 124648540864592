import Color from "color"

const Gradients = (theme?: any) => {

  return {
    CARD_GREY: 'linear-gradient(342deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.18) 37%, rgba(0,0,0,0.08) 91%)', // cards & header

    HEADER: 'linear-gradient(342deg, #ffffff 0%, #d1d1d1 37%, #ebebeb 91%)', // eader SG
    
    CARD_COLOR: '', // cards & header brand color

    BACKGROUND_COLOR: !!theme ?
      `linear-gradient(
        0deg,
        ${Color(theme.palette.primary.main).rgb().alpha(0.82)} 4%,
        ${Color(theme.palette.primary.main).rgb().alpha(0.66)} 26%,
        ${Color(theme.palette.primary.main).rgb().alpha(0.72)} 78%,
        ${Color(theme.palette.secondary.main).rgb().alpha(0.82)} 97%)`
      : '', // main background brand color

    BACKGROUND_DI: !!theme ?
      `linear-gradient(
        0deg,
        ${Color(theme.palette.primary.main).rgb().alpha(0.82)} 4%,
        ${Color(theme.palette.primary.main).rgb().alpha(0.66)} 26%,
        ${Color(theme.palette.primary.main).rgb().alpha(0.72)} 78%,
        ${Color(theme.palette.secondary.main).rgb().alpha(0.82)} 97%)`
      : '', // background for info block DI layout
  }
}

export default Gradients
