import React, { ReactNode } from 'react'
import { isMobile } from 'react-device-detect';
import { styled } from '@mui/material';
import classNames from 'classnames';
interface Props {
  className?: string;
  children: ReactNode | ReactNode[]
}

const StyledMain = styled('main')`
  flex: 1 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  min-height: 100%;
`;
function Main(props: Props) {

  const { children, className } = props
  return (
    <StyledMain className={classNames((isMobile ? 'screen-mobile' : 'screen-desktop'), className)}>
      {React.Children.toArray(children)}
    </StyledMain>

  );
}

export default Main;
