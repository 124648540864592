import React, { FC, MouseEvent } from 'react';
import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Colors, Scaling } from '@domain/theming';
import { TypeOfDamage, InternalExternal } from '@domain/interfaces';
import { isMobile } from 'react-device-detect';
import { useWindowSize } from '@domain/hooks';


const PREFIX = 'VerticalToggleButtons';

const classes = {
    root: `${PREFIX}-root`,
    grouped: `${PREFIX}-grouped`,
    root2: `${PREFIX}-root2`
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup, {
    shouldForwardProp: (propName) => !propName.toString().startsWith('$')
})((props: Partial<TooglleButtonProps>) => ({
    [`&.${classes.root2}`]: {
        width: '100%',
        backgroundColor: Colors.WHITE,
        minHeight: '45%',
        marginTop: '15px',
    },
    [`&.${classes.grouped}`]: {
        color: Colors.GREY_DARK
    }
}));

const StyledToggleButton = styled(ToggleButton, {
    shouldForwardProp: (propName) => !propName.toString().startsWith('$')
})((props: TooglleButtonProps) => ({
    [`&.${classes.root}`]: {
        height: '100%',
        fontSize: props.$dynamicFontSize ? (isMobile
            ? Scaling.textFontSizeMobile(14, props.$vwWidth)
            : Scaling.textFontSize(14, props.$vwWidth)) + 'px'
            : '14px'
    }
}));

interface Props {
    value: TypeOfDamage | InternalExternal
    handleChange(e: MouseEvent<HTMLElement>, value: TypeOfDamage | InternalExternal): void;
    dynamicFontSize?: boolean;
    arrayOfButtons: Array<{ value: TypeOfDamage | InternalExternal, label: string, ariaLabel: string }>
}

interface TooglleButtonProps {
    $dynamicFontSize?: boolean;
    $vwWidth: number;
}

const VerticalToggleButtons: FC<Props> = ({ value, handleChange, dynamicFontSize, arrayOfButtons }) => {



    const { vwWidth } = useWindowSize();


    const buttons = arrayOfButtons.map((button => {
        return (
            <StyledToggleButton $vwWidth={vwWidth}
                $dynamicFontSize={dynamicFontSize} value={button.value} aria-label={button.ariaLabel} className={classes.root} key={button.value}>
                {button.label}
            </StyledToggleButton>
        )
    }))

    return (
        <StyledToggleButtonGroup
            value={value}
            orientation='vertical'
            aria-label="select-damagetype"
            exclusive={true}
            onChange={handleChange}
            classes={{
                root: classes.root2,
                grouped: classes.grouped
            }}>
            {buttons}
        </ StyledToggleButtonGroup>
    );
}

export default VerticalToggleButtons
