import { makeCall } from '@domain/helpers';
import { endpoints } from '@domain/constants';
import { CaseData, ServerResponseVIP } from '@domain/interfaces';

async function postCaseVIP(body: CaseData): Promise<ServerResponseVIP> {
  const data = await makeCall<ServerResponseVIP>(endpoints.postCaseVIP, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  });

  return data as ServerResponseVIP;
}

export default postCaseVIP;
