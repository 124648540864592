export default function detectCompanyID(address: string, path: string) {
  const url = new URL(address);
  const pid = getPid(url, path);
  return { companyId: getCompanyId(path, pid) };
}

function extractCompanyId(locationPath: string) {
  const pathParts = locationPath.split('/');
  const firstPath = pathParts[1];

  if (firstPath === 'embed' || firstPath === 'welkom') {
    return pathParts[2] ? pathParts[2] : '';
  }
  return pathParts[1];
}

function getCompanyId(locationPath: string, pid: string) {
  if (pid !== '') {
    return '';
  }

  const invalidCompanyIds = [
    '',
    'bevestigen',
    'embed',
    'er-is-iets-misgegaan',
    'start',
    'welkom',
    'andere-opties',
    'nieuw',
  ];

  const companyId = extractCompanyId(locationPath);
  if (invalidCompanyIds.includes(companyId)) {
    return '';
  }
  return companyId;
}

function extractPid(url: URL, path: string) {
  if (path.includes('embed')) {
    return '';
  }
  const slicedUrl = url.host.split('.');

  if (slicedUrl.length < 3) {
    return '';
  }
  return slicedUrl[0] === 'www' ? slicedUrl[1] : slicedUrl[0];
}

function getPid(url: URL, path: string) {
  const pid = extractPid(url, path);

  const invalidPids = [
    '',
    '192',
    'undefined',
    'test',
    'accp',
    'www',
    'testing',
    'steering-portal-test',
    'steering-portal-testing',
    'steering-portal-accp',
    'steering-portal',
    'vindmijnhersteller',
    'mijnschade',
    'mijnschades',
  ];

  if (invalidPids.includes(pid)) {
    return '';
  }
  return pid;
}
