import React, { FC, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Colors } from '@domain/theming';
import { QuestionMark } from '..';
import Button from '@mui/material/Button';
import { styled } from '@mui/material';

const PREFIX = 'InteractiveTooltips';

const classes = {
  button: `${PREFIX}-button`,
  arrow: `${PREFIX}-arrow`,
  popper: `${PREFIX}-popper`,
  tooltip: `${PREFIX}-tooltip`,
  tooltipPlacementLeft: `${PREFIX}-tooltipPlacementLeft`,
  tooltipPlacementRight: `${PREFIX}-tooltipPlacementRight`,
  tooltipPlacementTop: `${PREFIX}-tooltipPlacementTop`,
  tooltipPlacementBottom: `${PREFIX}-tooltipPlacementBottom`
};

const StyledTooltip = styled(Tooltip)((
  {
    theme
  }
) => ({
  [`& .${classes.button}`]: {
    // margin: theme.spacing(1),
    padding: 0,
    minWidth: 0,
  },

  [`& .${classes.arrow}`]: {
    position: 'absolute',
    fontSize: 6,
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },

  [`& .${classes.popper}`]: arrowGenerator(Colors.BLACK),

  [`& .${classes.tooltip}`]: {
    position: 'relative',
    backgroundColor: Colors.BLACK,
  },

  [`& .${classes.tooltipPlacementLeft}`]: {
    // margin: '0 8px',
  },

  [`& .${classes.tooltipPlacementRight}`]: {
    // margin: '0 8px',
  },

  [`& .${classes.tooltipPlacementTop}`]: {
    // margin: '8px 0',
  },

  [`& .${classes.tooltipPlacementBottom}`]: {
    // margin: '8px 0',
  }
}));

const TitleP = styled('p')`
  text-align: center;
`;

function arrowGenerator(color: string) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.95em',
      width: '2em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${color} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.95em',
      width: '2em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${color} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.95em',
      height: '2em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${color} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.95em',
      height: '2em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${color}`,
      },
    },
  };
}

interface Props {
  title: string;
}

const InteractiveTooltips: FC<Props> = ({ title }) => {

  const [arrowRef, setArrowRef] = useState<HTMLSpanElement | null>(null);

  return (
    <StyledTooltip
      classes={classes}
      title={
        <>
          <TitleP>{title}</TitleP>{' '}
          <span className={classes.arrow} ref={el => setArrowRef(el)} />
        </>
      }
      placement="top"
    >
      <Button className={classes.button}>
        <QuestionMark />
      </Button>
    </StyledTooltip>
  );
};

export default InteractiveTooltips;
