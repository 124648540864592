
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { companyNames } from '@domain/constants';
import { buildURL, detectCompanyID, detectIds } from '@domain/helpers';
import { useGetParamsFromUrl } from '@domain/hooks';
import { FormRhion, ParamsUrl } from '@domain/interfaces';

import { RootState } from '@domain/root-reducer';
import { useDispatch, useSelector } from 'react-redux';

import {
  attributesFromUrlSet,
  checkProcess,
  companyIDSet,
  fetchBranding,
  fetchIntermediary,
  fidSet,
  pidSet,
  redirectUrlsSet,
} from '@domain/action-creators';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

const useGlobalEffects = () => {
  const fid = useSelector((state: RootState) => state.fid);
  const pid = useSelector((state: RootState) => state.pid);
  const branding = useSelector((state: RootState) => state.branding);
  const companyID = useSelector((state: RootState) => state.companyID);
  const processCheck = useSelector((state: RootState) => state.processCheck);

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();
  const location = useLocation();

  const { pid: pidInitial, fid: fidInitial } = detectIds(window.location.href);

  ////////////////////////////////////////////////////////////////////////////////// logic from DataProvider
  const companyParam = location.pathname;
  const { companyId } = detectCompanyID(window.location.href, companyParam);

  useEffect(() => {
    if (!companyID && companyId !== companyID) {
      dispatch(companyIDSet(companyId));
      localStorage.setItem('companyID', companyId);
    }
  }, [companyId, dispatch, companyID]);

  useEffect(() => {
    if (companyID) {
      dispatch(fetchIntermediary(companyID));
      return;
    }
  }, [companyID, dispatch]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (!pid && pid !== pidInitial) {
      dispatch(pidSet(pidInitial));
    }
    if (!fid && fid !== fidInitial) {
      dispatch(fidSet(fidInitial));
      localStorage.setItem('fid', fidInitial);
    }

    return;
  }, [pidInitial, fidInitial, dispatch, pid, fid]);

  useEffect(() => {
    if (!processCheck && pid) {
      if (fid) {
        dispatch(checkProcess(pid, fid));
        return;
      }
      dispatch(checkProcess(pid));
      return;
    }
  }, [pid, fid, dispatch, processCheck]);

  useEffect(() => {
    if (!branding && pid && processCheck) {
      if (fid) {
        dispatch(fetchBranding(pid, fid));
        return;
      }
      dispatch(fetchBranding(pid));
      return;
    }
  }, [pid, fid, dispatch, branding, processCheck]);

  const { attributesFromUrl } = useGetParamsFromUrl(
    [
      // params in dutch (created for Schadegarant, they get translated before creating the url redirect
      // exceptions: debiteurNummer and relatieNummer that stay the same)
      'debiteurNummer',
      'schadedatum',
      'schadeType',
      'fid',
      'kenteken',
      'straal',
      'postcode',
      'merk',
      'relatieNummer',
      'radius',
      //params added for all process
      'damageDate',
      'damageType',
      'insuranceInformationID',
      'licensePlate',
      'bodyshopNetwork',
      'customerGender',
      'customerFirstName',
      'customerLastName',
      'customerZipCode',
      'customerHouseNumber',
      'customerHouseNumberAddition',
      'customerStreet',
      'customerCity',
      'customerEmail',
      'customerMobileNumber',
      'errorCode',
      'carMake',
      'rid',
      'vehicleIdentificationID',
    ],
    location,
  );

  useEffect(() => {
    if (Object.keys(attributesFromUrl).length > 0) {
      if (branding && branding.objectType === companyNames.Schadegarant) {
        const validDamageType = attributesFromUrl.schadeType && (attributesFromUrl.schadeType === 'Carrosserie' || attributesFromUrl.schadeType === 'CarrosserieGlas' || attributesFromUrl.schadeType === 'Glas')
        const urlAttributes = {
          damageType: validDamageType ? attributesFromUrl.schadeType : '',
          licensePlate: attributesFromUrl.kenteken || '',
          damageDate: attributesFromUrl.schadedatum || '',
          merk: attributesFromUrl.merk || '',
          customerZipCode: attributesFromUrl.postcode || '',
          radius: attributesFromUrl.straal || '',
          relatieNummer: attributesFromUrl.relatieNummer || '',
          debiteurNummer: attributesFromUrl.debiteurNummer || '',
        };
        dispatch(attributesFromUrlSet(urlAttributes as ParamsUrl));
        if (!!attributesFromUrl.fid) {
          dispatch(fidSet(attributesFromUrl.fid));
        }
      } else {
        dispatch(attributesFromUrlSet(attributesFromUrl));
      }
    }
  }, [attributesFromUrl, branding, dispatch]);

  const isRhion = branding && branding.objectType === companyNames.Rhion;
  // RHION FORM
  useEffect(() => {
    if (isRhion) {
      const cascoURL = buildURL(
        `${process.env.REACT_APP_CP_DI_URL}/nieuw?`,
        Object.entries({ ...attributesFromUrl, PID: pid, FID: `${pid}casco` }),
      );

      const ownBodyshopURL = buildURL(
        `${process.env.REACT_APP_CP_DI_URL}/nieuw?`,
        Object.entries({ ...attributesFromUrl, PID: pid, FID: `${pid}em` }),
      );

      const onMyOwnURL = '';

      const redirects: FormRhion = {
        cascoRedirectURL: cascoURL,
        ownBodyshopRedirectURL: ownBodyshopURL,
        onMyOwnRedirectURL: onMyOwnURL,
      };
      dispatch(redirectUrlsSet(redirects));
    }
  }, [pid, isRhion, attributesFromUrl, dispatch]);
};

export default useGlobalEffects;
