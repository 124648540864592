const noop = () => null
const LocalStorage =
  typeof window === 'undefined'
    ? { clear: noop, getItem: noop, removeItem: noop, setItem: noop }
    : localStorage

const generateLocalStorageFn = <T>(key: string) => ({
  clear() {
    LocalStorage.removeItem(key)
  },
  get(): T | null {
    const data = LocalStorage.getItem(key)
    return data ? JSON.parse(data) : null
  },
  save(value: T) {
    LocalStorage.setItem(key, JSON.stringify(value))
  },
})

export const browserStorage = {
  cookieConsentStorage: generateLocalStorageFn<{ga:boolean, hotjar:boolean}>('consentCode'),
} as const
