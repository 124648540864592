import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { styled } from '@mui/material';

import { Colors, Scaling, Shadows } from '@domain/theming';
import Color from 'color';
import { useWindowSize } from '@domain/hooks';
import Loader from '../Loader';

type variant = 'primary' | 'outline';
type styledTheme = variant | 'disabled';

interface Props {
  disabled: boolean;
  variant?: variant;
  type?: 'button' | 'submit' | 'reset';
  value: string;
  dynamicFontSize?: boolean;
  loading?: boolean;
  onClick?: () => void
  children?: React.ReactNode
}

interface StyledButtonProps {
  $variant: styledTheme;
  $dynamicFontSize?: boolean;
  $vwWidth?: number;
}

const StyledButton = styled('button', {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
}) <StyledButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${props => props.$dynamicFontSize ? (Scaling.scaleUpTo4K(64, props.$vwWidth) + 'px') : '64px'};
  padding: 12px 24px;
  font-size: ${props => props.$dynamicFontSize ? (isMobile
    ? Scaling.textFontSizeMobile(12, props.$vwWidth)
    : Scaling.textFontSize(12, props.$vwWidth)) + 'px' : '12'}
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-align: center;
  background: ${props => props.theme.isThemed ? props.theme.palette.primary.main : Colors.BLACK};
  color: ${props => props.theme.palette.primary.contrastText};
  border: 1px solid
    ${props => props.theme.palette.primary.main};
  border-radius: 16px;
  cursor: ${props => (props.$variant === 'disabled' ? 'not-allowed' : 'pointer')};
  transition: 160ms cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: ${Shadows().Shadow6};
  @media (hover: hover) {
    &:hover {
      transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
      box-shadow: ${Shadows().Shadow3};
      background: ${props => props.$variant !== 'outline' ? props.theme.isThemed ? props.theme.palette.primary.light : Color(Colors.BLACK).rgb().alpha(0.7).string() : Color(Colors.BLACK).rgb().alpha(0.08).string()}
    }
  }
  &:focus {
    transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: ${Shadows().Shadow3};
  }
  &:active {
    transform: scale(0.98);
    transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: ${Shadows().Shadow1};
  }
  &[disabled] {
    box-shadow: none;
  }
  &.outline {
    box-shadow: none;
  }
  .card & {
    margin: auto auto 0;
  }
  .card form & {
    margin: 30px auto 0;
  }
`;

const StyledButtonWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
`;

const SubmitButton: FC<Props> = ({
  value,
  variant = 'primary',
  disabled,
  type = 'submit',
  children,
  dynamicFontSize,
  onClick,
  loading
}) => {

  const styledButtonTheme: styledTheme = disabled ? 'disabled' : variant;
  const { vwWidth } = useWindowSize();
  return (
    <StyledButton
      className={variant === 'outline' ? 'button--submit ' + variant : 'button--submit'}
      type={type}
      value={value}
      $variant={styledButtonTheme}
      disabled={disabled}
      $dynamicFontSize={dynamicFontSize}
      $vwWidth={vwWidth}
      {...(onClick ? { onClick: onClick } : {})}
    >
      <StyledButtonWrapper className="button-wrapper">
        {loading ?
          <Loader color="white" size="small" /> :
          React.Children.toArray(children)}
      </StyledButtonWrapper>
    </StyledButton>
  );
};

export default SubmitButton;
