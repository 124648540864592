const routes = Object.freeze({
  confirmation: '/bevestigen',
  embed: '/embed',
  error: '/er-is-iets-misgegaan',
  frontOffice: '/start',
  landing: '/welkom',
  nieuw: '/nieuw',
  noVIP: '/andere-opties',
});

export default routes;
