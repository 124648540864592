import { datadogLogs } from '@datadog/browser-logs';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '@domain/root-reducer';

import { CHECK_PROCESS } from '../shared/action-names';
import { ProcessCheck } from '@domain/interfaces';
import { getProcessCheck } from '@domain/services';

import { httpErrorSet } from '../httpError/actions';

export function checkProcess(
  pid: string,
  fid?: string,
): ThunkAction<void, RootState, unknown, Action> {
  return async function (dispatch) {
    try {
      const response: ProcessCheck = await getProcessCheck(pid, fid);
      dispatch({ type: CHECK_PROCESS, payload: response.valid });
       // @ts-ignore
    } catch ({ error }) {
      const errorLogMessage = "Error on process check"
      const errorInstance = new Error(errorLogMessage)
      datadogLogs.logger.error(errorLogMessage, { error: error }, errorInstance)
      dispatch(httpErrorSet({ ...error }));
    }
  };
}
