import { makeCall } from '@domain/helpers';
import { endpoints } from '@domain/constants';

function cancelCase(uuid: string) {
  makeCall<void>(endpoints.updateCase, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: {
      uuid,
      CustomerSteeringDecision: 'NoVIP',
    },
  });
}

export default cancelCase;
