import React, { ReactNode } from 'react'
import './text.scss';
import { styled } from '@mui/material';

interface Props {
  $level?: 1 | 2 | 3 | 4 | 5 | 6;
  children: ReactNode[] | ReactNode;
}

const StyledText = styled('span') <Props>`
  display: block;
`;

function Text(props: Props) {

  const { children, $level } = props
  const usedClass = $level === 1
    ? 'h1'
    : $level === 2
      ? 'h2'
      : $level === 3
        ? 'h3'
        : $level === 4
          ? 'h4'
          : $level === 6
            ? 'h6'
            : 'h5'

  return (
    <StyledText className={usedClass}>
      {children}
    </StyledText>

  );
}


export default Text;
