import Footer from '../Footer';
import { Colors } from '@domain/theming';
import { styled } from '@mui/material';

const BlackFooter = styled(Footer as any)`
  background: ${Colors.BLACK};
  width: 100%;
  height: 120px;
`;

export default BlackFooter;
