import React, {
  ChangeEvent,
  FC,
  FormEvent,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { errorCodeClear, fetchIntermediaries } from '@domain/action-creators';
import {
  Card,
  ComboBox,
  ConfirmationDialog,
  Container,
  DatePicker,
  ErrorMessage,
  Form,
  Heading,
  Input,
  LicenseInput,
  Recaptcha,
  SubmitButton,
  Tooltip,
} from '@domain/components';
import { captchaKey, ErrorCode } from '@domain/constants';
import { useSubmitDataVMH, useValidation, useWindowSize } from '@domain/hooks';
import { Company, FormData, InputName } from '@domain/interfaces';
import { RootState } from '@domain/root-reducer';
import { page } from '@domain/services';
import moment from 'moment';
import ReCAPTCHA from 'react-google-recaptcha';
import { styled } from '@mui/material';
import validationSchema from '../Landing/validationSchema';

import { Scaling } from '@domain/theming';
import { Action, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';


const initialFormData: FormData = {
  licensePlate: JSON.parse(localStorage.getItem('licensePlate') || '""'),
  customerEmail: JSON.parse(localStorage.getItem('customerEmail') || '""'),
  intermediary: JSON.parse(localStorage.getItem('intermediary') || '""')
    ? JSON.parse(localStorage.getItem('intermediary') as string)
    : {
      brandName: '',
      companyToken: '',
    },
  customerZipCode: JSON.parse(localStorage.getItem('customerZipCode') || '""'),
  damageDate: JSON.parse(localStorage.getItem('damageDate') || '""'),
};

const CaseForm = styled(Form as any)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  max-width: 460px;
  padding: 0;
`;

const InputTooltip = styled('div')`
  position: absolute;
  top: 18px;
  left: 0;
  z-index: 1;
  &.no-dnf {
    top: 16px;
  }
  @media screen and (max-width: 899px) {
    top: 16px
  }
`;

const DialogSpan = styled('span')`
  display: inline-block;
  margin-bottom: 20px;
`;

const StyledHeading = styled(Heading as any)`
  height: 32px;
  `;

const Embed: FC = () => {

  const cookieConsent = useSelector((state: RootState) => state.cookieConsent)
  // tracking
  useEffect(() => {
    page('Embed form', cookieConsent);
  }, [cookieConsent]);

  const intermediaries = useSelector((state: RootState) => state.intermediaries)
  const companyID = useSelector((state: RootState) => state.companyID)
  const errorCode = useSelector((state: RootState) => state.errorCode)
  const redirectUrls = useSelector((state: RootState) => state.redirectUrls)
  const caseDetails = useSelector((state: RootState) => state.caseDetails)

  const submitData = useSubmitDataVMH();
  const [formData, setFormData] = useState<FormData>(() => initialFormData);


  const [invalidLicenseDialogOpen, setInvaldLicenseDialogOpen] = useState<boolean>(false);
  const [licenseInUseDialogOpen, setLicenseInUseDialogOpen] = useState<boolean>(false);
  const captchaRef = useRef<ReCAPTCHA | null>(null);

  const [buttonClicked, setButtonClicked] = useState<boolean>(false);
  const [licenseBlurred, setLicenseBlurred] = useState<boolean>(false);
  const [emailBlurred, setEmailBlurred] = useState<boolean>(false);
  const [postCodeBlurred, setPostCodeBlurred] = useState<boolean>(false);
  const [dateBlurred, setDateBlurred] = useState<boolean>(false);
  const [intermediaryBlurred, setIntermediaryBlurred] = useState<boolean>(
    false,
  );

  const {
    customerEmail,
    customerZipCode,
    licensePlate,
    damageDate,
    intermediary,
  } = formData;

  const [urlCompany, setUrlCompany] = useState<boolean>(true);

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>()
  const { vwWidth } = useWindowSize();

  useEffect(() => {
    if (!companyID && (!intermediaries || !intermediaries.length)) {
      dispatch(fetchIntermediaries())
    }

  }, [dispatch, intermediaries, companyID]);


  useEffect(() => {
    if (!redirectUrls.vmhEmbedRedirect || caseDetails.vipAvailable === undefined) {
      return;
    }
    if (buttonClicked) {
      if (caseDetails.vipAvailable === false) {
        // window.open(process.env.REACT_APP_SP_URL + routes.noVIP);
        return;
      }
      window.open(redirectUrls.vmhEmbedRedirect);
      return;
    }

  }, [caseDetails, redirectUrls.vmhEmbedRedirect, buttonClicked]);

  const errors = useValidation(validationSchema, formData);

  const verifyAndSubmit = (event?: FormEvent<HTMLFormElement>) => {
    event && event.preventDefault();
    const captcha = captchaRef.current;
    if (!captcha) {
      return;
    }
    setButtonClicked(true);
    captcha.execute();
  };

  // was here for dumb component version
  // const shouldShowError = (name: string, isBlurred: boolean) => {
  //   return false;
  // }

  const shouldShowError = (name: InputName, blurred: boolean): boolean => {
    if (!buttonClicked && !blurred) {
      return false;
    }
    if (errors === null) {
      return false;
    }
    return !!errors[name];
  };

  const showError = (name: InputName, blurred: boolean): ReactNode => {
    // Typescript doesn't notice that errors cannot be null if
    // the show errors function returns false
    if (!shouldShowError(name, blurred) || errors === null) {
      return <></>;
    }
    return (
      <Container marginTop={'-' + Scaling.scaleUpTo4K(40, vwWidth) + 'px'}>
        <ErrorMessage>{errors[name]}</ErrorMessage>
      </Container>
    );
  };


  const onLicenseBlur = useCallback(() => setLicenseBlurred(true), []);
  const onEmailBlur = useCallback(() => setEmailBlurred(true), []);
  const onZipCodeBlur = useCallback(() => setPostCodeBlurred(true), []);
  const onDateBlur = useCallback(() => setDateBlurred(true), []);
  const onCompanyBlur = useCallback(() => setIntermediaryBlurred(true), []);


  const updateFormData = useCallback(
    (name: InputName, value: string | Company) =>
      setFormData(d => ({ ...d, [name]: value })),
    [],
  );

  interface eventTarget {
    name: InputName;
    value: string;
  }

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target as eventTarget;

      if (name === 'licensePlate') {
        const nonAlphaNumRegExp = /[^0-9A-Z]/gi;
        const newValue = value
          .toUpperCase()
          .replace(nonAlphaNumRegExp, '')
          .slice(0, 6);
        updateFormData(name, newValue);
        return;
      }

      if (name === 'customerZipCode') {
        const newValue = value.toUpperCase().replace(' ', '');
        updateFormData(name, newValue);
        return;
      }

      updateFormData(name, value);
    },
    [updateFormData],
  );

  const changeDate = useCallback(
    (date: Date | null, _value?: string | null | undefined): void => {
      const dateFormat = 'YYYY-MM-DD';
      const formattedDate = moment(date as Date).format(dateFormat);

      updateFormData('damageDate', formattedDate);
    },
    [updateFormData],
  );

  const comboBoxOnChange = useCallback(
    (_e: object, value: string | Company) => {
      if (value === undefined) {
        // Autopicker seems to hijack the enter key
        // Ugh
        return;
      }
      if (value === null) {
        return;
      }
      updateFormData('intermediary', value);
    },
    [updateFormData],
  );

  const getOptionLabel = useCallback(
    (option: unknown): string => {
      if (option === undefined || option == null) {
        return intermediary.brandName;
      }

      const company = option as Company;
      return company.brandName || "";
    },
    [intermediary],
  );


  const closeDialog = (
    setOpen: (value: SetStateAction<boolean>) => void,
  ) => () => {
    const captcha = captchaRef.current;
    if (captcha === null) {
      return;
    }
    captcha.reset();
    dispatch(errorCodeClear())
    setOpen(false);
  };

  useEffect(() => {
    if (errorCode === ErrorCode.LICENSE_NOT_FOUND) {
      setInvaldLicenseDialogOpen(true);
    }
    if (errorCode === ErrorCode.CASE_WITH_LICENSE_ALREADY_EXISTS) {
      setLicenseInUseDialogOpen(true);
    }
  }, [errorCode]);

  useEffect(() => {
    if (intermediaries && intermediaries.length) {
      if (!companyID) {
        setUrlCompany(false);
        return;
      };
      for (let i = 0; i < intermediaries.length; i++) {
        const intermediary = intermediaries[i];
        const urlCompanyName = intermediary.steeringPortalURLName || '';
        const nonAlphaNumericRegExp = /[^a-zA-Z0-9]/g;
        if (
          companyID.replace(nonAlphaNumericRegExp, '').toLowerCase() ===
          urlCompanyName.replace(nonAlphaNumericRegExp, '').toLowerCase()
        ) {
          setFormData(d => ({
            ...d,
            intermediary,
          }));
          // urlCompanyValid.current = true;
          setUrlCompany(true);
          return;
        }
      }
      // urlCompanyValid.current = false;
      setUrlCompany(false);
    }
  }, [intermediaries, companyID]);


  return (
    <>
      <ConfirmationDialog
        backdrop={false}
        // fullScreen
        open={invalidLicenseDialogOpen}
        onYesClick={closeDialog(setInvaldLicenseDialogOpen)}
        yesLabel="OK"
        content={
          <>
            <DialogSpan>
              Het ingevulde kenteken lijkt niet te bestaan.
            </DialogSpan>
          </>
        }
        showNoButton={false}
        title="Controleer je kenteken"
      />
      {/* <Snackbar open={true} autoHideDuration={6000}>
        <Alert onClose={handleClose} severity="success">
          This is a success message!
        </Alert>
      </Snackbar> */}
      <ConfirmationDialog
        backdrop={false}
        showNoButton={false}
        open={licenseInUseDialogOpen}
        yesLabel="OK"
        onYesClick={closeDialog(setLicenseInUseDialogOpen)}
        content={
          <>
            <DialogSpan>
              Er bestaat al een dossier met dit kenteken. Klik op de knop in de
              email die u heeft ontvangen bij het melden van de schade om de
              status van dit dossier in te zien.
            </DialogSpan>
          </>
        }
        title="Dit kenteken heeft al een dossier"
      />
      <Container
        className="embed-form"
        padding={`${Scaling.scaleUpTo4K(10, vwWidth)}px ${Scaling.scaleUpTo4K(15, vwWidth)}px ${Scaling.scaleUpTo4K(15, vwWidth)}px`}
        flexGrow="0"
        flexBasis="auto"
      >
        <Card
          className="card-form card-embed"
          background="grey"
          flexGrow="0"
          minWidth={Scaling.scaleUpTo4K(300, vwWidth) + 'px'}
          maxWidth={Scaling.scaleUpTo4K(480, vwWidth) + 'px'}
          // height="755px"
          padding={`${Scaling.scaleUpTo4K(30, vwWidth)}px ${Scaling.scaleUpTo4K(30, vwWidth)}px ${Scaling.scaleUpTo4K(40, vwWidth)}px`}
          margin="0"
          shadow={4}
        >
          <CaseForm
            // onSubmit={redirectToApp}
            onSubmit={verifyAndSubmit}
          >
            <StyledHeading className="no-dnf" level={3} textAlign="center">
              Vul je gegevens in
            </StyledHeading>
            <Container
              flexDirection="column"
              margin="0 0 auto 0"
              flexBasis="auto"
              flexGrow="0"
              width="100%"
            >
              <LicenseInput
                onChange={onChange}
                value={licensePlate}
                name="licensePlate"
                type="text"
                label="Kenteken"
                onBlur={onLicenseBlur}
                error={shouldShowError('licensePlate', licenseBlurred)}
                dynamicFontSize={false}
              />
              {showError('licensePlate', licenseBlurred)}
              <Input
                placeholder="voorbeeld@domain.com"
                name="customerEmail"
                onChange={onChange}
                type="email"
                label="E-mailadres"
                onBlur={onEmailBlur}
                error={shouldShowError('customerEmail', emailBlurred)}
                value={customerEmail}
                dynamicFontSize={false}
              />
              {showError('customerEmail', emailBlurred)}
              <Input
                placeholder="1000AB"
                name="customerZipCode"
                onChange={onChange}
                type="text"
                label="Postcode"
                onBlur={onZipCodeBlur}
                error={shouldShowError('customerZipCode', postCodeBlurred)}
                capitals={true}
                value={customerZipCode}
                dynamicFontSize={false}
              />
              {showError('customerZipCode', postCodeBlurred)}
              <DatePicker
                value={damageDate}
                onChange={changeDate}
                label="Schadedatum"
                onBlur={onDateBlur}
                error={shouldShowError('damageDate', dateBlurred)}
                dynamicFontSize={false}
              />
              {showError('damageDate', dateBlurred)}

              {!!urlCompany ? '' :
                <Container display="block" position="relative" width="100%">
                  {!urlCompany &&
                    <InputTooltip className="no-dnf">
                      <Tooltip
                        title="Jouw verzekeringsadviseur is het assurantiebedrijf waar jij jouw
                    verzekering hebt afgesloten."
                      />
                    </InputTooltip>
                  }
                  <ComboBox
                    disabled={urlCompany}
                    dynamicFontSize={false}
                    error={shouldShowError('intermediary', intermediaryBlurred)}
                    getOptionLabel={getOptionLabel}
                    hasTooltip={!urlCompany}
                    label="Jouw verzekeringsadviseur"
                    minChar={2}
                    onBlur={onCompanyBlur}
                    onChange={comboBoxOnChange}
                    options={urlCompany ? [intermediary] : intermediaries ? intermediaries : []}
                    value={intermediary}
                  />
                </Container>
              }
              {!!urlCompany ? '' : showError('intermediary', intermediaryBlurred)}

            </Container>
            <Recaptcha
              onChange={captchaKey =>
                submitData(captchaKey, formData, validationSchema)
              }
              captchaRef={captchaRef}
              siteKey={captchaKey}
            />
            <SubmitButton value="Jouw opties" disabled={errors !== null}>
              Jouw opties
            </SubmitButton>
          </CaseForm>
        </Card>
      </Container>
    </>
  )
}

export default Embed;
