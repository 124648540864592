import { useEffect } from 'react';

function useLocalStorage<T>(name: string, value: T) {
  useEffect(() => {
    if (value != null) {
      localStorage.setItem(name, JSON.stringify(value))
    }
  }, [
    name,
    value,
  ]);
}

export default useLocalStorage;
