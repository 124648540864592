import React, {
  ChangeEvent,
  FC,
  FormEvent,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { errorCodeClear, fetchIntermediaries } from '@domain/action-creators';
import {
  Card,
  ChecklistItem,
  ComboBox,
  ConfirmationDialog,
  Container,
  DatePicker,
  ErrorMessage,
  Form,
  Header,
  Heading,
  Input,
  LicenseInput,
  Main,
  Paragraph,
  Recaptcha,
  Section,
  SubmitButton,
  TextButton,
  Tooltip,
  UList,
} from '@domain/components';
import { captchaKey, ErrorCode, routes } from '@domain/constants';
import { useLocalStorage, useSubmitDataVMH, useValidation, useWindowSize } from '@domain/hooks';
import { Company, FormData, InputName } from '@domain/interfaces';
import { RootState } from '@domain/root-reducer';
import { page } from '@domain/services';
import { Scaling } from '@domain/theming';

import moment from 'moment';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material';
import validationSchema from './validationSchema';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

interface ViewportProps {
  $vwWidth?: number,
}

const CaseForm = styled(Form as any, {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  max-width: ${props => Scaling.scaleUpTo4K(460, props.$vwWidth)}px;
  padding: 0;
  justify-content: space-between;
`;

const DialogSpan = styled('span', {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
}) <ViewportProps>`
  display: inline-block;
  margin-bottom: ${props => Scaling.scaleUpTo4K(20, props.$vwWidth)}px;
`;

const ContainerIntroText = styled(Container as any)`
  @media screen and (max-width: 900px) {
    margin: 0 auto 20px;
    padding-right: 0;
    max-width: 530px;
    min-width: auto;
    > .container {
      margin-top: 30px;
      padding: 0 20px 0 25px;
    }
  }
`;

const FormCard = styled(Card as any)`
  align-self: flex-start;
`;

const InputTooltip = styled('div', {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
}) <ViewportProps>`
  position: absolute;
  left: -${props => Scaling.scaleUpTo4K(18, props.$vwWidth)}px;
  top: ${props => Scaling.scaleUpTo4K(10, props.$vwWidth)}px;
  z-index: 1;
  @media screen and (max-width: 899px) {
    top: 16px
  }
`;

const Landing: FC = () => {
  const cookieConsent = useSelector((state: RootState) => state.cookieConsent)
  // tracking
  useEffect(() => {
    page('Landing', cookieConsent);
  }, [cookieConsent]);

  const companyID = useSelector((state: RootState) => state.companyID)
  const intermediaries = useSelector((state: RootState) => state.intermediaries)
  const errorCode = useSelector((state: RootState) => state.errorCode)
  const caseDetails = useSelector((state: RootState) => state.caseDetails)
  const [searchP, setSearchP] = useSearchParams()

  const initialFormData: FormData = {
    licensePlate: !!searchP.get('LicensePlate') ? searchP.get('LicensePlate') : JSON.parse(localStorage.getItem('licensePlate') || '""'),
    customerEmail: !!searchP.get('CustomerEmail') ? searchP.get('CustomerEmail') : JSON.parse(localStorage.getItem('customerEmail') || '""'),
    intermediary: localStorage.getItem('intermediary')
      ? JSON.parse(localStorage.getItem('intermediary') as string) || '""'
      : {
        brandName: '',
        companyToken: '',
      },
    customerZipCode: !!searchP.get('CustomerZipCode') ? searchP.get('CustomerZipCode') : JSON.parse(localStorage.getItem('customerZipCode') || '""'),
    damageDate: !!searchP.get('DamageDate') ? searchP.get('DamageDate') : JSON.parse(localStorage.getItem('damageDate') || '""'),
  };

  const submitData = useSubmitDataVMH();

  const [formData, setFormData] = useState<FormData>(() => initialFormData);
  const [invalidLicenseDialogOpen, setInvaldLicenseDialogOpen] = useState<boolean>(false);
  const [licenseInUseDialogOpen, setLicenseInUseDialogOpen] = useState<boolean>(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);
  const captchaRef = useRef<ReCAPTCHA | null>(null);
  const navigate = useNavigate();

  const [buttonClicked, setButtonClicked] = useState<boolean>(false);
  const [licenseBlurred, setLicenseBlurred] = useState<boolean>(false);
  const [emailBlurred, setEmailBlurred] = useState<boolean>(false);
  const [postCodeBlurred, setPostCodeBlurred] = useState<boolean>(false);
  const [dateBlurred, setDateBlurred] = useState<boolean>(false);
  const [intermediaryBlurred, setIntermediaryBlurred] = useState<boolean>(
    false,
  );

  const {
    customerEmail,
    customerZipCode,
    licensePlate,
    damageDate,
    intermediary,
  } = formData;


  const urlCompanyValid = useRef(!!companyID);
  const errors = useValidation(validationSchema, formData);

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>()
  const { vwWidth } = useWindowSize();

  useEffect(() => {
    if (!companyID && (!intermediaries || !intermediaries.length)) {
      dispatch(fetchIntermediaries())
    }

  }, [dispatch, intermediaries, companyID]);



  useEffect(() => {
    if (buttonClicked) {
      if (caseDetails.vipAvailable === undefined) {
        return;
      }

      if (caseDetails.vipAvailable === false) {
        navigate(routes.noVIP);
        return;
      }
      navigate(routes.confirmation);
      return;
    }

  }, [caseDetails, history, buttonClicked]);

  const openConfirmationDialog = (event?: FormEvent<HTMLFormElement>) => {
    event && event.preventDefault();
    setConfirmationDialogOpen(true);
  }
  const verifyAndSubmit = useCallback((event?: FormEvent<HTMLFormElement>) => {
    setConfirmationDialogOpen(false);
    event && event.preventDefault();
    const captcha = captchaRef.current;
    if (!captcha) {
      return;
    }
    // closeDialog(setConfirmationDialogOpen);
    setButtonClicked(true);
    captcha.execute();
  }, []);

  const shouldShowError = (name: InputName, blurred: boolean): boolean => {
    if (!buttonClicked && !blurred) {
      return false;
    }
    if (errors === null) {
      return false;
    }
    return !!errors[name];
  };

  const showError = (name: InputName, blurred: boolean): ReactNode => {
    // Typescript doesn't notice that errors cannot be null if
    // the show errors function returns false
    if (!shouldShowError(name, blurred) || errors === null) {
      return <></>;
    }
    return (
      <Container marginTop={'-' + Scaling.scaleUpTo4K(14, vwWidth) + 'px'} >
        <ErrorMessage>{errors[name]}</ErrorMessage>
      </Container>
    );
  };

  const onLicenseBlur = useCallback(() => setLicenseBlurred(true), []);
  const onEmailBlur = useCallback(() => setEmailBlurred(true), []);
  const onZipCodeBlur = useCallback(() => setPostCodeBlurred(true), []);
  const onDateBlur = useCallback(() => setDateBlurred(true), []);
  const onCompanyBlur = useCallback(() => setIntermediaryBlurred(true), []);

  useLocalStorage('licensePlate', licensePlate);
  useLocalStorage('customerZipCode', customerZipCode);
  useLocalStorage('customerEmail', customerEmail);
  useLocalStorage('damageDate', damageDate);
  useLocalStorage('intermediary', intermediary);

  const updateFormData = useCallback(
    (name: InputName, value: string | Company) =>
      setFormData(d => ({ ...d, [name]: value })),
    [],
  );

  interface eventTarget {
    name: InputName;
    value: string;
  }

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target as eventTarget;

      if (name === 'licensePlate') {
        const nonAlphaNumRegExp = /[^0-9A-Z]/gi;
        const newValue = value
          .toUpperCase()
          .replace(nonAlphaNumRegExp, '')
          .slice(0, 6);
        updateFormData(name, newValue);
        return;
      }

      if (name === 'customerZipCode') {
        const newValue = value.toUpperCase().replace(' ', '');
        updateFormData(name, newValue);
        return;
      }

      updateFormData(name, value);
    },
    [updateFormData],
  );

  const changeDate = useCallback(
    (date: Date | null, _value?: string | null | undefined): void => {
      const dateFormat = 'YYYY-MM-DD';
      const formattedDate = moment(date as Date).format(dateFormat);

      updateFormData('damageDate', formattedDate);
    },
    [updateFormData],
  );

  const comboBoxOnChange = useCallback(
    (_e: object, value: null | undefined | string | Company) => {
      if (value === undefined) {
        // Autopicker seems to hijack the enter key
        setConfirmationDialogOpen(true);
        return;
      }
      if (value === null) {
        return;
      }
      updateFormData('intermediary', value);
    },
    [setConfirmationDialogOpen, updateFormData],
  );
  const getOptionLabel = useCallback(
    (option: unknown): string => {
      if (option === undefined || option == null) {
        return intermediary.brandName;
      }

      const company = option as Company;
      return company.brandName || '';
    },
    [intermediary],
  );

  useEffect(() => {
    if (errorCode === ErrorCode.LICENSE_NOT_FOUND) {
      setInvaldLicenseDialogOpen(true);
    }
    if (errorCode === ErrorCode.CASE_WITH_LICENSE_ALREADY_EXISTS) {
      setLicenseInUseDialogOpen(true);
    }
  }, [errorCode]);

  const closeDialog = (
    setOpen: (value: SetStateAction<boolean>) => void,
  ) => () => {
    const captcha = captchaRef.current;
    if (captcha === null) {
      return;
    }
    captcha.reset();
    dispatch(errorCodeClear())
    setOpen(false);
  };

  useEffect(() => {
    if (!companyID) return;
    if (intermediaries) {
      for (let i = 0; i < intermediaries.length; i++) {
        const intermediary = intermediaries[i];
        const urlCompanyName = intermediary.steeringPortalURLName || '';
        const nonAlphaNumericRegExp = /[^a-zA-Z0-9]/g;
        if (
          companyID.replace(nonAlphaNumericRegExp, '').toLowerCase() ===
          urlCompanyName.replace(nonAlphaNumericRegExp, '').toLowerCase()
        ) {
          setFormData(d => ({
            ...d,
            intermediary,
          }));
          urlCompanyValid.current = true;
          return;
        }
      }
    }

    urlCompanyValid.current = false;
  }, [intermediaries, companyID]);

  return (
    <>
      <ConfirmationDialog
        open={invalidLicenseDialogOpen}
        content={
          <>
            <DialogSpan $vwWidth={vwWidth}>
              Het ingevulde kenteken lijkt niet te bestaan.
            </DialogSpan>
            <DialogSpan $vwWidth={vwWidth}>
              Klik{' '}
              <TextButton onClick={closeDialog(setInvaldLicenseDialogOpen)}>
                hier
              </TextButton>{' '}
              om terug te keren naar de vorige pagina en jouw kenteken opnieuw
              in te vullen.
            </DialogSpan>
          </>
        }
        showYesButton={false}
        showNoButton={false}
        title="Controleer je kenteken"
      />
      <ConfirmationDialog
        showNoButton={false}
        open={licenseInUseDialogOpen}
        yesLabel="OK"
        onYesClick={closeDialog(setLicenseInUseDialogOpen)}
        content={
          <>
            <DialogSpan $vwWidth={vwWidth}>
              Er bestaat al een dossier met dit kenteken. Klik op de knop in de
              email die u heeft ontvangen bij het melden van de schade om de
              status van dit dossier in te zien.
            </DialogSpan>
          </>
        }
        title="Dit kenteken heeft al een dossier"
      />
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onNoClick={closeDialog(setConfirmationDialogOpen)}
        onYesClick={verifyAndSubmit}
        noLabel="Annuleren"
        yesLabel="Volgende"
        content={
          <>
            <DialogSpan $vwWidth={vwWidth}>
              Voor het gemak delen we deze schademelding met jouw adviseur.
            </DialogSpan>
          </>
        }
        title=""
      />
      <Header shadow={true} />
      <Main>
        <Section size="lg">
          <Container
            className="flex-container"
            justifyContent="space-between"
          // marginBottom="60px"
          >
            <ContainerIntroText
              className="landing-intro-text"
              boxSizing="content-box"
              flexDirection="column"
              flexGrow="1"
              flexShrink="1"
              flexBasis="auto"
              margin={`0 0 ${Scaling.scaleUpTo4K(80, vwWidth)}px`}
              minWidth={Scaling.scaleUpTo4K(380, vwWidth) + 'px'}
              maxWidth={Scaling.scaleUpTo4K(600, vwWidth) + 'px'}
              paddingRight={Scaling.scaleUpTo4K(50, vwWidth) + 'px'}
            // padding="0 50px"
            >
              <Heading
                className="title"
                level={1}
                marginBottom={Scaling.scaleUpTo4K(40, vwWidth) + 'px'}
                textAlign="left"
              >
                Vind het juiste herstelbedrijf voor je schade
              </Heading>
              <Paragraph level={3}>
                Heb je autoschade en wil je dat deze zo snel mogelijk wordt
                opgelost? Vul eenvoudig jouw gegevens in en wij laten je zien
                welke herstelopties je hebt bij jouw verzekeraar.
              </Paragraph>
              <Container marginTop={Scaling.scaleUpTo4K(60, vwWidth) + 'px'}>
                <UList>
                  <ChecklistItem imgWidth={32} gutterWidth={'25px'} level={3}>
                    Geschikt voor autoverzekeringen van alle verzekeraars
                  </ChecklistItem>
                  <ChecklistItem imgWidth={32} gutterWidth={'25px'} level={3}>
                    Snel en gemakkelijk binnen een paar klikken geregeld
                  </ChecklistItem>
                  <ChecklistItem imgWidth={32} gutterWidth={'25px'} level={3}>
                    Kies eenvoudig de optie die bij jou past
                  </ChecklistItem>
                  <ChecklistItem imgWidth={32} gutterWidth={'25px'} level={3}>
                    Binnen no-time weer op weg
                  </ChecklistItem>
                </UList>
              </Container>
            </ContainerIntroText>
            <FormCard
              className="card-form"
              background="grey"
              flexGrow="0"
              minWidth={Scaling.scaleUpTo4K(300, vwWidth) + 'px'}
              maxWidth={Scaling.scaleUpTo4K(480, vwWidth) + 'px'}
              padding={`${Scaling.scaleUpTo4K(30, vwWidth)}px ${Scaling.scaleUpTo4K(30, vwWidth)}px ${Scaling.scaleUpTo4K(40, vwWidth)}px`}
              margin="0 0 0 auto"
              shadow={4}
            >
              <CaseForm onSubmit={openConfirmationDialog} $vwWidth={vwWidth}>
                <Heading level={3} textAlign="center">
                  Vul je gegevens in
                </Heading>
                <LicenseInput
                  onChange={onChange}
                  value={licensePlate}
                  name="licensePlate"
                  type="text"
                  label="Kenteken"
                  onBlur={onLicenseBlur}
                  error={shouldShowError('licensePlate', licenseBlurred)}
                />
                {showError('licensePlate', licenseBlurred)}
                <Input
                  placeholder="voorbeeld@domain.com"
                  name="customerEmail"
                  onChange={onChange}
                  type="email"
                  label="E-mailadres"
                  onBlur={onEmailBlur}
                  error={shouldShowError('customerEmail', emailBlurred)}
                  value={customerEmail}
                />
                {showError('customerEmail', emailBlurred)}
                <Input
                  placeholder="1000AB"
                  name="customerZipCode"
                  onChange={onChange}
                  type="text"
                  label="Postcode"
                  onBlur={onZipCodeBlur}
                  error={shouldShowError('customerZipCode', postCodeBlurred)}
                  capitals={true}
                  value={customerZipCode}
                />
                {showError('customerZipCode', postCodeBlurred)}
                <DatePicker
                  value={damageDate}
                  onChange={changeDate}
                  label="Schadedatum"
                  onBlur={onDateBlur}
                  error={shouldShowError('damageDate', dateBlurred)}
                />
                {showError('damageDate', dateBlurred)}
                <Container display="block" position="relative" width="100%">
                  {!urlCompanyValid.current ?
                    <InputTooltip $vwWidth={vwWidth}>
                      <Tooltip
                        title="Jouw verzekeringsadviseur is het assurantiebedrijf waar jij jouw
                      verzekering hebt afgesloten."
                      />
                    </InputTooltip> :
                    ''
                  }
                  <ComboBox
                    disabled={urlCompanyValid.current}
                    error={shouldShowError('intermediary', intermediaryBlurred)}
                    label="Jouw verzekeringsadviseur"
                    getOptionLabel={getOptionLabel}
                    hasTooltip={!urlCompanyValid.current}
                    minChar={2}
                    onBlur={onCompanyBlur}
                    onChange={comboBoxOnChange}
                    options={urlCompanyValid.current ? [intermediary] : intermediaries ? intermediaries : [intermediary]}
                    value={intermediary}
                  />
                </Container>
                {showError('intermediary', intermediaryBlurred)}
                <Recaptcha
                  onChange={captchaKey =>
                    submitData(captchaKey, formData, validationSchema)
                  }
                  captchaRef={captchaRef}
                  siteKey={captchaKey}
                />
                <SubmitButton value="Jouw opties" disabled={errors !== null}>
                  Jouw opties
                </SubmitButton>
              </CaseForm>
            </FormCard>
          </Container>
        </Section>
      </Main>
    </>
  );
};

export default Landing;
