import { customContent, defaultContent } from '@domain/content';
import {
  BlockOptions,
  Branding,
  ContentOptions,
  ContentStructure,
  RedirectUrls,
} from '@domain/interfaces';

const generateContent: (
  contentType: ContentOptions,
  branding: Branding,
  redirectUrls: RedirectUrls,
  block: BlockOptions,
) => ContentStructure = (contentType, branding, redirectUrls, block) => {
  const defaultTexts = defaultContent(branding);
  const defaultBlockText = defaultTexts[block];

  const customTexts = customContent(contentType, branding, redirectUrls);
  const customBlockText = customTexts[block] || {};
  //// prepared to receive content under key 'content'
  const companyCustomTexts =
    branding && branding.content ? branding.content[block] || {} : {};

  const finalContent = {
    ...defaultBlockText,
    ...customBlockText,
    ...companyCustomTexts,
  };

  return finalContent;
};

export default generateContent;
