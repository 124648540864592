import { PID_SET } from '../shared/action-names';
import { pidActionTypes, PIDState } from './types';

const initialState: PIDState = null;

export default function (
  state = initialState,
  action: pidActionTypes,
): PIDState {
  switch (action.type) {
    case PID_SET:
      return action.payload;
    default:
      return state;
  }
}
