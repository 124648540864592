import { endpoints } from '@domain/constants';
import { Branding } from '@domain/interfaces';
import { makeCall } from '@domain/helpers';

async function getBranding(pid: string, fid?: string): Promise<Branding> {

  const FID = fid ? { FID: fid } : localStorage.getItem('fid') ? { FID: localStorage.getItem('fid') } : {}
  const headers = { PID: pid, ...FID, 'Pragma': 'no-cache' }

  const data = await makeCall<Branding>(endpoints.getBranding,
    {
      method: 'GET',
      headers: headers
    });
  return data as Branding;
}

export default getBranding;