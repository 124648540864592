import React, { FC, FormEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { generateRedirectURL } from '@domain/helpers';
import { ContentStructureFormBlock, TypeOfDamage } from '@domain/interfaces';
import { RootState } from '@domain/root-reducer';

import FormIntake from './FormIntake';


interface Props {
    content: ContentStructureFormBlock;
}
const FormWrapperIntake: FC<Props> = ({ content }) => {

    const caseDetails = useSelector((state: RootState) => state.caseDetails)
    const pid = useSelector((state: RootState) => state.pid)
    const fid = useSelector((state: RootState) => state.fid)

    const [damageType, setDamageType] = useState<TypeOfDamage>('Carrosserie');

    useEffect(() => {
        if (caseDetails.damageType) {
            setDamageType(caseDetails.damageType as TypeOfDamage)
        }
    }, [caseDetails.damageType])

    const handleSubmitDamageType = (event?: FormEvent<HTMLElement>) => {
        event && event.preventDefault();
        const redirectLink = generateRedirectURL(caseDetails, pid || '', fid || '', { damageType });
        window.location.href = redirectLink
    };

    const handleDamageType = (event: React.MouseEvent<HTMLElement>, selectedDamageType: TypeOfDamage) => {
        if (selectedDamageType !== null) {
            setDamageType(selectedDamageType);
        }
    };

    return (
        <>
            <FormIntake
                handleSubmitDamageType={handleSubmitDamageType}
                handleDamageType={handleDamageType}
                damageType={damageType}
                caseDetails={caseDetails}
                content={content}
            />
        </>
    )
}

export default FormWrapperIntake
