import { datadogLogs } from '@datadog/browser-logs';
import { Company } from '@domain/interfaces';
import { RootState } from '@domain/root-reducer';
import { getIntermediaries } from '@domain/services';

import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { httpErrorSet } from '../httpError/actions';
import { GET_INTERMEDIARIES, GET_INTERMEDIARY } from '../shared/action-names';

export function fetchIntermediaries(): ThunkAction<
  void,
  RootState,
  unknown,
  Action
> {
  return async function (dispatch) {
    try {
      const response: Company[] = await getIntermediaries();
      dispatch({
        type: GET_INTERMEDIARIES,
        payload: [...response, { brandName: '', companyToken: '' }],
      });
       // @ts-ignore
    } catch ({ error }) {
      const errorLogMessage = "Error getting intermediaries"
      const errorInstance = new Error(errorLogMessage)
      datadogLogs.logger.error(errorLogMessage, { error: error }, errorInstance)
      dispatch(httpErrorSet({ ...error }));
    }
  };
}

export function fetchIntermediary(
  companyParam: string,
): ThunkAction<void, RootState, unknown, Action> {
  return async function (dispatch) {
    try {
      const response: Company[] = await getIntermediaries(companyParam);
      dispatch({ type: GET_INTERMEDIARY, payload: response });
       // @ts-ignore
    } catch ({ error }) {
      const errorLogMessage = "Error getting choosen intermediary"
      const errorInstance = new Error(errorLogMessage)
      datadogLogs.logger.error(errorLogMessage, { error: error }, errorInstance)
      dispatch(httpErrorSet({ ...error }));
    }
  };
}
