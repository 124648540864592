import { endpoints } from '@domain/constants';
import { makeCall } from '@domain/helpers';
import { CaseDataInvite, ServerResponseDIInvite } from '@domain/interfaces';


//need new server response interface
async function postCaseDIInvite(body: CaseDataInvite): Promise<ServerResponseDIInvite> {
  const data = await makeCall<ServerResponseDIInvite>(endpoints.postCaseDIInvite, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  });

  return data as ServerResponseDIInvite;
}

export default postCaseDIInvite;
