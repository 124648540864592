import React, { ChangeEvent, FC, useState, memo, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';
import { Colors, Scaling } from '@domain/theming';
import { InputName } from '@domain/interfaces';
import { useWindowSize, useInputBlur } from '@domain/hooks';
import TextField from '@mui/material/TextField';
import Container from '../Container';

const PREFIX = 'index';

const classes = {
  textField: `${PREFIX}-textField`,
  inputRoot: `${PREFIX}-inputRoot`,
  inputInput: `${PREFIX}-inputInput`,
  inputError: `${PREFIX}-inputError`,
  inputLabelRoot: `${PREFIX}-inputLabelRoot`,
  inputLabelShrink: `${PREFIX}-inputLabelShrink`,
  inputUnderline: `${PREFIX}-inputUnderline`,
  inputLabelClasses: `${PREFIX}-inputLabelClasses`,
  inputDisabled: `${PREFIX}-inputDisabled`,
  inputLabelDisabled: `${PREFIX}-inputLabelDisabled`,
  focused: `${PREFIX}-focused`,
  inputLabelError: `${PREFIX}-inputLabelError`,
  dense: `${PREFIX}-dense`,
  menu: `${PREFIX}-menu`
};

const StyledTextField = styled(TextField, {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
})((props: Partial<StyledProps>) => ({
  ['& .MuiInputLabel-root.Mui-error']: {
    color: Colors.BLACK
  },
  ['& .MuiInputLabel-root.Mui-focused']: {
    color: Colors.BLACK,
  },
  [`&.${classes.textField}`]: {
    //main container
    width: '100%',
    background: Colors.YELLOW,
    height: 'auto',
    margin:
      props.$dynamicFontSize ? `${Scaling.scaleUpTo4K(52, props.$vwWidth)}px 0 ${Scaling.scaleUpTo4K(30, props.$vwWidth)}px` : '42px 0 20px',
    '&:after': {
      content: '"NL"',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      left: 0,
      bottom: 0,
      width: '42px',
      height: '100%',
      color: Colors.WHITE,
      fontSize:
        props.$dynamicFontSize ?
          (isMobile
            ? Scaling.textFontSizeMobile(14, props.$vwWidth)
            : Scaling.textFontSize(14, props.$vwWidth)) + 'px' :
          '14px',
      lineHeight: '20px',
      letterSpacing: '0.0625em',
      background: Colors.BLUE,
      backgroundImage: 'url(/img/eu-stars.svg)',
      backgroundPosition: 'top',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '60% 55%',
      padding: '0 0 0 2px',
      position: 'absolute',
      textAlign: "center"
    },
  },

  [`& .${classes.inputRoot}`]: {
    //input container
    marginTop: 0,
    fontFamily: 'Gabriel Sans',
    borderColor: Colors.BLACK,
    color: Colors.BLACK,
    padding: '7px 46px 0',
  },

  [`& .${classes.inputInput}`]: {
    textAlign: 'center',
    fontSize:
      props.$dynamicFontSize ?
        (isMobile
          ? Scaling.textFontSizeMobile(32, props.$vwWidth)
          : Scaling.textFontSize(32, props.$vwWidth)) + 'px' :
        '32px',
    fontWeight: 'bold',
    lineHeight:
      props.$dynamicFontSize ?
        (isMobile
          ? Scaling.textFontSizeMobile(36, props.$vwWidth)
          : Scaling.textFontSize(36, props.$vwWidth)) + 'px' :
        '36px',
    height:
      props.$dynamicFontSize ?
        (isMobile
          ? Scaling.textFontSizeMobile(48, props.$vwWidth)
          : Scaling.textFontSize(48, props.$vwWidth)) + 'px' :
        '48px',
    boxSizing: 'border-box',
  },

  [`& .${classes.inputError}`]: {
    color: Colors.RED,
  },

  [`& .${classes.inputLabelRoot}`]: {
    height:
      props.$dynamicFontSize ?
        (isMobile
          ? Scaling.textFontSizeMobile(48, props.$vwWidth)
          : Scaling.textFontSize(48, props.$vwWidth)) + 'px' :
        '48px',
    maxWidth: '100%',
    top: 'calc(50% + 10px)',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // padding: '6px 0 7px',
    color: Colors.BLACK,
    fontFamily: 'inherit',
    fontSize:
      props.$dynamicFontSize ?
        (isMobile
          ? Scaling.textFontSizeMobile(32, props.$vwWidth)
          : Scaling.textFontSize(32, props.$vwWidth)) + 'px' :
        '32px',
    fontWeight: 'bold',
    lineHeight: '36px',
    letterSpacing: '0.03125em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    transition: '200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
    '&$focused': {
      color: Colors.BLACK,
      fontWeight: 'bold',
    },
    '&$inputLabelError': {
      color: Colors.BLACK,
    },
    '&+$inputRoot': {
      marginTop: 0,
    },
  },

  [`& .${classes.inputLabelShrink}`]: {
    top: '-22px',
    left: 0,
    transform: 'translate(0, 0) scale(1)',
    color: Colors.BLACK,
    lineHeight: '14px',
    letterSpacing: '0.0625em',
    fontSize:
      props.$dynamicFontSize ?
        (isMobile
          ? Scaling.textFontSizeMobile(16, props.$vwWidth)
          : Scaling.textFontSize(16, props.$vwWidth)) + 'px' :
        '16px',
    transition: '200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  },

  [`& .${classes.inputUnderline}`]: {
    '&$inputError': {
      '&:before': {
        borderColor: Colors.RED,
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: `1px solid ${Colors.RED}`,
      },
      '&:after': {
        borderBottom: `2px solid ${Colors.RED}`,
      },
    },
    '&:before': {
      borderColor: Colors.BLACK,
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: `1px solid ${Colors.BLACK}`,
    },
    '&:after': {
      borderBottom: `2px solid ${Colors.BLACK}`,
    },
  },

  [`& .${classes.inputLabelClasses}`]: {},
  [`& .${classes.inputDisabled}`]: {},
  [`& .${classes.inputLabelDisabled}`]: {},
  [`& .${classes.focused}`]: {
    color: Colors.BLACK,
  },
  [`& .${classes.inputLabelError}`]: {
    color: Colors.BLACK,
    ['& .Mui-error']: {
      color: Colors.BLACK
    }
  },

  [`& .${classes.dense}`]: {
    marginTop: 19,
  },

  [`& .${classes.menu}`]: {
    width: 200,
  }
}));

interface StyledProps {
  $dynamicFontSize?: boolean
  $vwWidth?: any;
}

interface Props {
  dynamicFontSize?: boolean;
  label: string;
  labelAlign?: 'left' | 'center';
  name: InputName;
  onChange(e: ChangeEvent<HTMLInputElement>): void;
  textAlign?: 'left' | 'center';
  type: 'email' | 'text' | 'date';
  value: string;
  vwWidth?: any;
  onBlur: () => void;
  onFocus?: () => void;
  error: boolean;
}

const LicenseInput: FC<Props> = ({
  dynamicFontSize,
  label,
  name,
  onChange,
  type,
  value,
  onBlur,
  error,
}) => {
  const { vwWidth } = useWindowSize();

  const [inputEl, setInputEl] = useState<HTMLInputElement | null>(null);

  useInputBlur(inputEl, onBlur);

  const setInputRef = useCallback((el: HTMLInputElement) => setInputEl(el), []);

  return (
    <Container flexGrow="0" width="100%">
      <StyledTextField
        inputRef={setInputRef}
        $vwWidth={vwWidth}
        $dynamicFontSize={dynamicFontSize}
        required
        variant='standard'
        id={name}
        label={label}
        value={value}
        className={classes.textField}
        name={name}
        onChange={onChange}
        error={error}
        type={type}
        InputProps={{
          classes: {
            root: classes.inputRoot,
            underline: classes.inputUnderline,
            disabled: classes.inputDisabled,
            error: classes.inputError,
            input: classes.inputInput,
          },
          spellCheck: false,
        }}
        InputLabelProps={{
          htmlFor: name,
          classes: {
            root: classes.inputLabelRoot,
            shrink: classes.inputLabelShrink,
            focused: classes.focused,
            disabled: classes.inputLabelDisabled,
            error: classes.inputLabelError,
          },
          shrink: true,
        }}
      />
    </Container>
  );
};

export default memo(LicenseInput);
