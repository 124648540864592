enum Error {
  NO_ERROR = 0,
  INVALID_LICENSE_PLATE = 1,
  CASE_WITH_LICENSE_ALREADY_EXISTS = 2,
  INVALID_POSTCODE = 3,
  COMPANY_DOES_NOT_EXIST = 14,
  RISK_CARRIER_NOT_FOUND = 15,
  RISK_CARRIER_CHECK_OFF = 16,
  RDW_CHECK_FAILED = 17,
  CAPTCHA_FAILED = 18,
  LICENSE_NOT_FOUND = 19,
  LICENSE_PLATE_NOT_FOUND_CCS = 26,
  CCS_SERVICE_DOWN = 27,
  NO_EXPERIENCE = 28
}

export default Error;
