import { publicIpv4 } from 'public-ip';

async function getIP(): Promise<string> {
  try {
    const ip = await publicIpv4();
    return ip;
  } catch (_e) {
    console.log('IP detection failed');
    return '';
  }
}

export default getIP;
