import React, { FC } from 'react';

import { styled } from '@mui/material';
import { motion } from 'framer-motion'

import { useWindowSize } from '@domain/hooks';
import { CardType, Paragraphs } from '@domain/interfaces';
import { Colors, Scaling } from '@domain/theming';

import { AnchorButton, Container, Heading, Paragraph, TickSVG, } from '..';
import UseIcon from '../UseIcon';
import classNames from 'classnames';

interface StyleProps {
  $vwWidth: number;
}

const CardContainer = styled(motion(Container) as any, {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
})`
  position:relative;
  max-width: ${props => Scaling.scaleUpTo4K(580, props.$vwWidth)}px;
  margin: 0 auto;
  padding-left: ${props => Scaling.scaleUpTo4K(30, props.$vwWidth)}px;
  padding-right: ${props => Scaling.scaleUpTo4K(30, props.$vwWidth)}px;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
  opacity: .85;
  color: ${Colors.WHITE};
  height: 0px;
  will-change: transform, opacity;
  > p,
  > ul,
  > h3 {
    max-width: 100%;
  }
  ul {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
    padding-left: 18px;
    font-size: 13px;
  }
  h3, h4, h5 {
    color: ${Colors.WHITE}
  }

  @media screen and (max-width: 900px) {
    + .card {
      margin-top: 40px;
      margin-bottom:30px;
    }
  }
  @media screen and (max-width: 420px) {
    &.card.card-form {
      padding: 0;
    }
  }
`;



const StyledUseIcon = styled(UseIcon)`
  transition: .25s ease-out;
  transform: rotate(0deg);
  .open & {
    transform: rotate(-180deg) translateY(3px);
  }
  .screen-ie & {
    position: absolute;
    top: 0px;
    right: 24px;
    height: 100%;
  }
`;

const StyledList = styled('ul') <StyleProps>`
  list-style: none;
  li {
    display:flex;
    padding: ${props => Scaling.scaleUpTo4K(5, props.$vwWidth)}px;
    justify-content: flex-start;
    svg {
      flex: 0 0 auto
      margin-right: ${props => Scaling.scaleUpTo4K(10, props.$vwWidth)}px;
    }
    p {
      margin: 0;
    }
  }
`;

interface CompleteCard extends CardType {
  link?: string | null;
  className?: string
}

interface Props {
  card: CompleteCard
  isOpen: boolean
  toggleIsOpen: () => void
}

const ToogleCard: FC<Props> = ({ card, isOpen, toggleIsOpen }) => {

  const classNameList = classNames(
    'main__button--redirect',
    'black-and-white',
    card.className || 'toggle-card__button',
    { 'open': isOpen },
  );
  const { vwWidth } = useWindowSize();
  const { paragraphs, title, list, 'closing-paragraphs': closingParagraphs, button } = card
  const generateList = list ? list.map((item: Paragraphs, i: number) => {
    return (
      <li key={i}>
        {item.icon ? <TickSVG width={`${Scaling.scaleUpTo4K(16, vwWidth)}px`} height={`${Scaling.scaleUpTo4K(16, vwWidth)}px`} color={Colors.WHITE} /> : <UseIcon name={'minus'} height={20} width={20} className={'list-icon'} />}
        <Paragraph> {item.text}</Paragraph>
      </li>
    )
  }) : null

  const generateParagraphs = paragraphs ? paragraphs.map((item: Paragraphs, i: number) => {
    return (
      <Paragraph key={i}>
        {item.text}
      </Paragraph>
    )
  }) : null

  const generateFinalParagraphs = closingParagraphs ? closingParagraphs.map((item: Paragraphs, i: number) => {
    return (
      <Paragraph key={i}>
        {item.text}
      </Paragraph>
    )
  }) : null

  const variants = {
    open: { height: 'auto', },
    closed: { height: 0, overflow: 'hidden' },
  }

  // const iconVariants = {
  //   open: { rotate: 180 },
  //   closed: { rotate: 0 },
  // }

  return (
    <>
      <AnchorButton
        className={classNameList}
        dynamicFontSize={true}
        onClick={toggleIsOpen} >{button}
        <StyledUseIcon
          // animate={isOpen ? 'open' : 'closed'}
          // variants={iconVariants}
          name="arrow-down"
          className="button__icon"
          width={26}
          height={26}
        />
      </AnchorButton>
      <CardContainer
        $vwWidth={vwWidth}
        animate={isOpen ? "open" : "closed"}
        variants={variants}
        initial={false}
        transition={{ duration: 0.5 }}
        paddingBottom={isOpen ? Scaling.scaleUpTo4K(60) + 'px' : '0'}
      >
        {title && <Heading
          level={3}
          textAlign="center"
          marginBottom="30px"
        >
          {title}
        </Heading>}
        {generateParagraphs && <>{generateParagraphs}</>}
        {generateList && <StyledList $vwWidth={vwWidth}>
          {generateList}
        </StyledList>}
        {generateFinalParagraphs && <>{generateFinalParagraphs}</>}
      </CardContainer>
    </>
  )
}

export default ToogleCard
