import { processTypes } from '@domain/constants';
import { Branding, ContentOptions } from '@domain/interfaces';

const detectContentType: (branding: Branding | null) => ContentOptions = (
  branding,
) => {
  if (branding && branding.objectType === processTypes.DI) {
    return 'DI';
  }
  if (branding && branding.objectType === processTypes.Landing) {
    return 'landing';
  }
  if (branding && branding.objectType === processTypes.Schadegarant) {
    return 'SG';
  }
  if (branding && branding.objectType === processTypes.DealerSaas) {
    return 'dealerSaas';
  }
  if (branding && branding.objectType === processTypes.Intake) {
    return 'intake';
  }
  if (branding && branding.objectType === processTypes.IntakeSpecial) {
    return 'intakeSpecial';
  }
   if (branding && branding.objectType === processTypes.IntakePlus) {
    return 'intakePlus';
  }
     if (branding && branding.objectType === processTypes.DealerDistribution) {
    return 'dealerDistribution';
  }
  return 'notCustom';
};

export default detectContentType;
