import React, { forwardRef, Children, ReactElement, ReactNode } from 'react';
import './paragraph.scss';
import { styled } from '@mui/material';

interface Props {
  className?: string;
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  textAlign?: 'left' | 'center' | 'right';
  marginTop?: string;
  removeMargin?: boolean;
  paddingTop?: string
  paddingBottom?: string
  children: ReactElement | ReactNode
}

interface StyledProps {
  className?: string;
  $level?: 1 | 2 | 3 | 4 | 5 | 6;
  $textAlign?: 'left' | 'center' | 'right';
  $marginTop?: string;
  $removeMargin?: boolean;
  $paddingTop?: string
  $paddingBottom?: string
}


const StyledParagraph = styled('p', {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
}) <StyledProps>`
  text-align: ${props => props.$textAlign};
  margin: ${props => props.$removeMargin ? '0' : '0 auto'};
  padding-top: ${props => props.$paddingTop};
  padding-bottom: ${props => props.$paddingBottom}
`;
const Paragraph = forwardRef<HTMLParagraphElement, Props>(({
  level,
  textAlign,
  marginTop,
  removeMargin,
  paddingTop,
  paddingBottom,
  children
}, ref) => {

  const usedClass =
    level === 1
      ? 'h1'
      : level === 2
        ? 'h2'
        : level === 3
          ? 'h3'
          : level === 4
            ? 'h4'
            : level === 6
              ? 'h6'
              : 'h5'
  return (
    <StyledParagraph
      ref={ref}
      className={usedClass}
      $level={level}
      $textAlign={textAlign}
      $marginTop={marginTop}
      $removeMargin={removeMargin}
      $paddingTop={paddingTop}
      $paddingBottom={paddingBottom}
    >
      {Children.toArray(children)}
    </StyledParagraph>
  );
});

Paragraph.displayName = 'Paragraph'
export default Paragraph;
