import React, { Children, forwardRef, ReactElement, ReactNode } from 'react';
import { styled } from '@mui/material';
import { Colors, Shadows } from '@domain/theming';
import classNames from 'classnames';

interface Props {
  background?: 'white' | 'grey' | 'dark-grey' | 'black';
  borderRadius?: string;
  width?: string;
  maxWidth?: string;
  minWidth?: string;
  flexGrow?: string;
  flexShrink?: string;
  flexBasis?: string;
  height?: string;
  margin?: string;
  marginTop?: string;
  marginRight?: string;
  marginBottom?: string;
  marginLeft?: string;
  padding?: string;
  paddingTop?: string;
  paddingRight?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  shadow?: 1 | 2 | 3 | 4 | 6 | 12;
  className?: string
  children: ReactElement | ReactNode
}
interface StyledProps {
  $background?: 'white' | 'grey' | 'dark-grey' | 'black';
  $borderRadius?: string;
  $width?: string;
  $maxWidth?: string;
  $minWidth?: string;
  $flexGrow?: string;
  $flexShrink?: string;
  $flexBasis?: string;
  $height?: string;
  $margin?: string;
  $marginTop?: string;
  $marginRight?: string;
  $marginBottom?: string;
  $marginLeft?: string;
  $padding?: string;
  $paddingTop?: string;
  $paddingRight?: string;
  $paddingBottom?: string;
  $paddingLeft?: string;
  $shadow?: 1 | 2 | 3 | 4 | 6 | 12;
  className?: string
}

const StyledCard = styled('div', {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
}) <StyledProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  flex: ${props =>
    `${props.$flexGrow || 1} ${props.$flexShrink || 1} ${props.$flexBasis ||
    'auto'}`};
  
  margin: ${props => props.$margin};
  margin-top: ${props => props.$marginTop};
  margin-right: ${props => props.$marginRight};
  margin-bottom: ${props => props.$marginBottom};
  margin-left: ${props => props.$marginLeft};
  padding: ${props => props.$padding || '50px'};
  padding-top: ${props => props.$paddingTop};
  padding-right: ${props => props.$paddingRight};
  padding-bottom: ${props => props.$paddingBottom};
  padding-left: ${props => props.$paddingLeft};
  background: ${props =>
    props.$background === 'black'
      ? Colors.BLACK
      : props.$background === 'white'
        ? Colors.WHITE
        : props.$background === 'dark-grey'
          ? Colors.GREY_DARK
          : Colors.GREY_LIGHT};
  border-radius: ${props => props.$borderRadius || '16px'};
  min-width: ${props => props.$minWidth || 0};
  max-width: ${props => props.$maxWidth};
  width: ${props => props.$width};
  height: ${props => props.$height};
  box-shadow: ${props =>
    props.$shadow === 1 ?
      Shadows().Shadow1 :
      props.$shadow === 2 ?
        Shadows().Shadow2 :
        props.$shadow === 3 ?
          Shadows().Shadow3 :
          props.$shadow === 4 ?
            Shadows().Shadow4 :
            props.$shadow === 6 ?
              Shadows().Shadow6 :
              props.$shadow === 12 ?
                Shadows().Shadow12 :
                ''
  };
`;

const Card = forwardRef<HTMLDivElement, Props>(({
  background,
  className,
  borderRadius,
  flexGrow,
  flexShrink,
  flexBasis,
  width,
  height,
  maxWidth,
  minWidth,
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
  shadow,
  children
}, ref) => {

  const usedClass = classNames('card', className)
  return (
    <StyledCard
      ref={ref}
      className={usedClass}
      $background={background}
      $borderRadius={borderRadius}
      $shadow={shadow}
      $flexGrow={flexGrow}
      $flexShrink={flexShrink}
      $flexBasis={flexBasis}
      $width={width}
      $height={height}
      $maxWidth={maxWidth}
      $minWidth={minWidth}
      $margin={margin}
      $marginTop={marginTop}
      $marginRight={marginRight}
      $marginBottom={marginBottom}
      $marginLeft={marginLeft}
      $padding={padding}
      $paddingTop={paddingTop}
      $paddingRight={paddingRight}
      $paddingBottom={paddingBottom}
      $paddingLeft={paddingLeft}
    >
      {Children.toArray(children)}
    </StyledCard>
  );
});


export default Card;
