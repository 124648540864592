import React, {
  FC,
  FormEvent,
} from 'react';

import Color from 'color'
import { motion } from 'framer-motion';
import { styled } from '@mui/material';

import {
  Card,
  Container,
  Heading,
  SubmitButton,
  ToggleButtons,
} from '@domain/components';
import { useWindowSize } from '@domain/hooks';
import { CaseInfo, ContentStructureFormBlock, TypeOfDamage } from '@domain/interfaces';
import { Colors, Scaling } from '@domain/theming';

const cardGradient = 'linear-gradient(342deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.18) 37%, rgba(0,0,0,0.08) 91%)';

const FormCard = styled(motion(Card) as any, {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
})`
   background-image: ${cardGradient};
        border: 3px solid ${Colors.WHITE};
        border-radius: 0px;
        flex-grow: 0;
        width: 100%;
        position:relative;
        max-width: ${props => Scaling.scaleUpTo4K(520, props.$vwWidth)}px;
        padding: 0;
        margin: auto;
        align-self: flex-start;
        align-items: flex-end;
        opacity: .85;
        will-change: transform, opacity;
        &.card-form-grey {
          background-image: linear-gradient(
            358deg,
            ${Colors.GREY_LIGHT} -10%,
            ${Color(Colors.GREY_LIGHT).lighten(0.06).hex()} 33%,
            ${Colors.GREY_LIGHT} 55%
            );
            .button {
              border-color: ${Colors.BLACK};
              color: ${Colors.BLACK};
              &:hover {
                background: ${Color(Colors.BLACK).rgb().alpha(0.08).string()}
              }
            }
        }
    
        &.single-card {
          min-height:  ${props => Scaling.scaleUpTo4K(468, props.$vwWidth)}px;
          height: 0; //IE
        }
        + .card {
          margin-top: ${props => Scaling.scaleUpTo4K(60, props.$vwWidth)}px;
          margin-bottom: ${props => Scaling.scaleUpTo4K(468, props.$vwWidth)}px;
        }
        p, h3, input, label {
        }
        @media screen and (min-width:1920px) {
          &.large-width-out {
            max-width: ${props => Scaling.scaleUpTo4K(650, props.$vwWidth)}px;
          }
        }
        @media screen and (max-width: 900px) {
          + .card {
            margin-top: 40px;
            margin-bottom:30px;
          }
        }
        @media screen and (max-width: 420px) {
          &.card.card-form {
            padding: 0;
          }
        }     
      `;

const FormCardContainer = styled(
  motion(Container) as any, {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
}
)`
        padding: ${props => Scaling.scaleUpTo4K(30, props.$vwWidth)}px ${props => Scaling.scaleUpTo4K(30, props.$vwWidth)}px ${props => Scaling.scaleUpTo4K(40, props.$vwWidth)}px;
        will-change: transform, opacity;
        + .card-form__container--response {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        .card-form__button--redirect {
          max-width: 100%;
          text-align: center;
          + .card-form__button--redirect {
            margin-top: ${props => Scaling.scaleUpTo4K(30, props.$vwWidth)}px;
          }
        }
        h4, h5 {
          // color: ${props => props.theme.palette.primary.main};
          color: ${props => props.theme.isCustomThemed ? Colors.GREY_DARKER : props.theme.palette.primary.contrastText};
        }
      `;

const CaseForm = styled(motion.form as any, {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
})`
      display: flex;
      margin: auto 0;
      flex-direction: column;
      flex: 1 0 auto;
      max-width: ${props => Scaling.scaleUpTo4K(500, props.$vwWidth)}px;
      padding: 0;
      // justify-content: space-between;
      .card-form & .button--submit {
        margin-top: auto;
      }
      > p,
      > ul,
      > h3 {
        max-width: 100%;
      }
      ul {
        padding-inline-start: 0px;
        padding-inline-end: 0px;
        padding-left: ${props => Scaling.scaleUpTo4K(18, props.$vwWidth)}px;
        padding-bottom: ${props => Scaling.scaleUpTo4K(15, props.$vwWidth)}px;
        font-size: ${props => Scaling.textFontSize(13, props.$vwWidth)}px;
      }
      @media screen and (min-width:1920px) {
        &.large-width {
          max-width: ${props => Scaling.scaleUpTo4K(610, props.$vwWidth)}px;
        }
      }
    `;

const formVariants = (vwWidth: number) => ({
  hidden: {
    opacity: 0,
    y: Scaling.scaleUpTo4K(50, vwWidth),
    transition: {
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
      opacity: {
        duration: 0.68,
        delay: 0.3,
      },
      y: {
        delay: 0.3,
        type: 'spring',
        stiffness: 40,
      }
    },
  }
})

const formInnerVariants = {
  hidden: {
    opacity: 0,
    y: '20%',
    transition: {
      duration: 0.12,
      y: {
        delay: 0.12,
      },
    },
    transitionEnd: {
      zIndex: 0,
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    zIndex: 1,
    transition: {
      type: 'spring',
      stiffness: 30,
      mass: 0.2,
      opacity: {
        delay: 0.22,
        duration: 0.18,
      },
    },
  },
};

interface Props {
  handleSubmitDamageType: (event?: FormEvent<HTMLElement>) => void;
  handleDamageType: (event: React.MouseEvent<HTMLElement>, selectedDamageType: TypeOfDamage) => void;
  caseDetails: CaseInfo;
  damageType: TypeOfDamage;
  content: ContentStructureFormBlock;
}

const FormIntake: FC<Props> = ({ handleSubmitDamageType, handleDamageType, caseDetails, damageType, content }) => {

  const contentCardOne = content['card-one']
  const typeOfDamageOptions: Array<{ value: TypeOfDamage, label: string, ariaLabel: string }> = [{ value: "Carrosserie", label: "Autoschade", ariaLabel: "car" }, { value: "CarrosserieGlas", label: "Auto en ruitschade", ariaLabel: "both" }, { value: "Glas", label: "Ruitschade", ariaLabel: "glass" }]

  const { vwWidth } = useWindowSize();
  const { button: buttonCardOne, title: titleCardOne } = contentCardOne

  return (
    <FormCard
      className="card-form single-card"
      shadow={4}
      variants={formVariants(vwWidth)}
      initial="hidden"
      animate="visible"
      $vwWidth={vwWidth}
    >
      <FormCardContainer
        className="card-form__container--fields"
        flexDirection="column"
        flexGrow="1"
        flexShrink="1"
        width="100%"
        variants={formInnerVariants}
        initial={false}
        animate={!caseDetails.damageType ? 'visible' : 'hidden'}
        $vwWidth={vwWidth}
      >
        <CaseForm
          onSubmit={handleSubmitDamageType}
          $vwWidth={vwWidth}
        >
          {titleCardOne && <Heading
            level={4}
            textAlign="center"
            marginBottom={Scaling.scaleUpTo4K(30, vwWidth) + 'px'}
            marginTop={Scaling.scaleUpTo4K(10, vwWidth) + 'px'}
            initial={false}
          >
            {titleCardOne}
          </Heading>}
          <ToggleButtons handleChange={handleDamageType} value={damageType} dynamicFontSize={true} arrayOfButtons={typeOfDamageOptions} />
          {<SubmitButton
            value="Jouw opties"
            disabled={false}
            dynamicFontSize={true}
          >
            <span>{buttonCardOne || 'Volgende'}</span>
          </SubmitButton>}
        </CaseForm>
      </FormCardContainer>
    </FormCard>
  )
};
export default FormIntake
