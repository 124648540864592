const processType = {
  Schadegarant: 'schadegarant',
  Casco: 'casco',
  DI: 'dicase',
  EM: 'expertisemanager',
  Landing: 'landing',
  DealerSaas: 'dealer_saas',
  Intake: 'intake',
  IntakeSpecial: 'intake_special',
  IntakePlus: 'intake_plus',
  DealerDistribution: 'dealer_distribution'
};

export default processType;
