import { FID_SET } from '../shared/action-names';
import { fidActionTypes, FIDState } from './types';

const initialState: FIDState = null;

export default function (
  state = initialState,
  action: fidActionTypes,
): FIDState {
  switch (action.type) {
    case FID_SET:
      return action.payload;
    default:
      return state;
  }
}
