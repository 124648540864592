import { Branding } from '@domain/interfaces';
import { RootState } from '@domain/root-reducer';
import { getBranding } from '@domain/services';

import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { httpErrorSet } from '../httpError/actions';
import { GET_BRANDING } from '../shared/action-names';
import { datadogLogs } from '@datadog/browser-logs';
export function fetchBranding(
  pid: string,
  fid?: string,
): ThunkAction<void, RootState, unknown, Action> {
  return async function (dispatch) {
    try {
      const response: Branding = await getBranding(pid, fid);
      dispatch({ type: GET_BRANDING, payload: response });
       // @ts-ignore
    } catch ({ error }) {
      const errorLogMessage = "Error dispatching get branding"
      const errorInstance = new Error(errorLogMessage)
      datadogLogs.logger.error(errorLogMessage, { error: error }, errorInstance)
      dispatch(httpErrorSet({ ...error }));
    }
  };
}
