import React, { FC, FormEvent, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ContentStructureFormBlock, InternalExternal, TypeOfDamage } from '@domain/interfaces';
import { RootState } from '@domain/root-reducer';
import FormIntakeSpecial from './FormIntakeSpecial';
import { generateRedirectURL } from '@domain/helpers';
import { internalExternalSet } from '../../../store/caseDetails/actions';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

interface Props {
    content: ContentStructureFormBlock;
}
const FormWrapperIntakeSpecial: FC<Props> = ({ content }) => {

    const caseDetails = useSelector((state: RootState) => state.caseDetails)
    const pid = useSelector((state: RootState) => state.pid)

    const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>()

    const [damageType, setDamageType] = useState<TypeOfDamage>('Carrosserie');
    const [internalExternal, setInternalExternal] = useState<InternalExternal>('external');
    const [temporaryFID, setTemporaryFID] = useState<string | null>(null);

    useEffect(() => {
        if (caseDetails.damageType) {
            setDamageType(caseDetails.damageType as TypeOfDamage)
        }
    }, [caseDetails.damageType])

    const handleSubmitInternalExternal = (event: FormEvent<HTMLElement>) => {
        event.preventDefault();
        dispatch(internalExternalSet(internalExternal))
        setTemporaryFID(`${pid}${internalExternal}`)
    };


    const handleDamageType = (event: React.MouseEvent<HTMLElement>, value: TypeOfDamage) => {
        if (value !== null) {
            setDamageType(value);
        }
    };

    const handleInternalExternal = (event: React.MouseEvent<HTMLElement>, value: InternalExternal) => {
        if (value !== null) {
            setInternalExternal(value);
        }
    };
    const handleResetCreation = () => {
        dispatch(internalExternalSet(""))
        setTemporaryFID(null)
    };

    const verifyAndSubmit = async (event?: FormEvent<HTMLFormElement>) => {
        event && event.preventDefault();
        const redirectLink = generateRedirectURL(caseDetails, pid || '', temporaryFID || '', { damageType });
        window.location.href = redirectLink
    }

    return (
        <>
            <FormIntakeSpecial
                internalExternal={internalExternal}
                handleInternalExternal={handleInternalExternal}
                handleSubmitInternalExternal={handleSubmitInternalExternal}
                handleDamageType={handleDamageType}
                damageType={damageType}
                verifyAndSubmit={verifyAndSubmit}
                handleResetCreation={handleResetCreation}
                temporaryFID={temporaryFID}
                content={content}
                caseDetails={caseDetails}
            />
        </>
    )
}

export default FormWrapperIntakeSpecial
