// export const RESPONSE_UPDATE = 'RESPONSE_UPDATE';
export const FORM_DI_UPDATE = 'FORM_DI_UPDATE';
export const FORM_SG_UPDATE = 'FORM_SG_UPDATE';
export const DAMAGE_TYPE_UPDATE = 'DAMAGE_TYPE_UPDATE';
export const ATTRIBUTE_FROM_URL_SET = 'ATTRIBUTE_FROM_URL_SET';
export const VEHICLE_IDENTIFICATION_ID_SET = 'VEHICLE_IDENTIFICATION_ID_SET';
export const VEHICLE_INFO_SET = 'VEHICLE_INFO_SET'
export const LICENSEPLATE_UUID_SET = 'LICENSEPLATE_UUID_SET';
export const CAPTCHA_SET = 'CAPTCHA_SET';
export const CCS_INFORMATION_UUID_SET = 'CCS_INFORMATION_UUID_SET';

export const ERROR_CODE_SET = 'ERROR_CODE_SET';
export const ERROR_CODE_CLEAR = 'ERROR_CODE_CLEAR';

export const REDIRECT_URL_SET = 'REDIRECT_URL_SET';
export const URL_CREATION_SET = ' URL_CREATION_SET';
export const URL_CREATION_DELETE = 'URL_CREATION_DELETE';
export const URL_SG_SET = 'URL_SG_SET';

export const GET_BRANDING = 'GET_BRANDING';

export const PID_SET = 'PID_SET';

export const FID_SET = 'FID_SET';

export const HTTP_ERROR_SET = 'HTTP_ERROR_SET';
export const HTTP_ERROR_CLEAR = 'HTTP_ERROR_CLEAR';

export const GET_INTERMEDIARIES = 'GET_INTERMEDIARIES';
export const GET_INTERMEDIARY = 'GET_INTERMEDIARY';
export const COMPANY_ID_SET = 'COMPANY_ID_SET';
export const URL_VMH_EMBED_SET = 'URL_VMH_EMBED_SET';
export const FORM_VIP_UPDATE = 'FORM_VIP_UPDATE';
export const GET_EMBED_VIP_CASE = 'GET_EMBED_VIP_CASE';

export const CHECK_PROCESS = 'CHECK_PROCESS';
export const INTERNAL_EXTERNAL_SET = 'INTERNAL_EXTERNAL_SET'
export const INSURANCE_INFORMATION_ID_SET = 'INSURANCE_INFORMATION_ID_SET'

export const CONSENT_SET = 'CONSENT_SET'