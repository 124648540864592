import { date, object, string } from 'yup';
import { format } from 'date-fns'
const zipCodeRegEx = /^([0-9]{4})(\s??)([A-Z]{2})$/;
const licenseRegex = /^[0-9a-zA-Z]{6}$/;

const validationSchema = object({
  customerEmail: string()
    .required()
    .email()
    .label('E-mailadres'),
  customerZipCode: string()
    .required()
    .min(6)
    .max(7)
    .matches(zipCodeRegEx, 'Controleer jouw postcode')
    .uppercase()
    .label('Postcode'),
  damageDate: date()
    .required()
    .max(new Date(), `Schadedatum moet eerder zijn dan ${format(new Date(), 'dd/MM/yyyy')}`)
    .label('Schadedatum'),
  intermediary: object()
    .required()
    .test(
      'User must select company',
      'Kies (of typ) je verzekeringsadviseur',
      //@ts-ignore
      val => !!val.brandName,
    )
    .label('Verzekeringsadviseur'),
  licensePlate: string()
    .required()
    .length(6)
    .matches(licenseRegex, 'Vul alstublieft een geldig kenteken in')
    .trim()
    .uppercase()
    .label('Kenteken'),
});

export default validationSchema;
