import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// Only polyfill related imports before this line

import React from 'react';
import { BrowserView, isIE, MobileOnlyView, TabletView } from 'react-device-detect';
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import '@domain/yup';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store'

import { HelmetProvider } from 'react-helmet-async';

// Initialize Datadog RUM datacapture (user logs)
datadogRum.init({
  applicationId: process.env.REACT_APP_DD_APPLICATION_ID || '',
  clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN || '',
  env: process.env.REACT_APP_DD_ENV,
  sessionSampleRate: 100,
  useSecureSessionCookie: true,
  sessionReplaySampleRate: 0,
  trackLongTasks: true,
  version: process.env.REACT_APP_PUBLIC_DD_VERSION,
  trackResources: true,
  service: 'Steering Portal RUM',
  site: 'datadoghq.com',
  // trackInteractions: true
});
datadogLogs.init({
  beforeSend: (log) => {
    // this changes log level for requests to analytics (stric mode issue)
    if (
      log.status === 'error' &&
      log.http?.status_code === 0 &&
      log.http?.url.includes('https://region1.google-analytics.com')
    ) {
      log.status = 'info'
    }
    return true
  },
  clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN_LOGS || '',
  env: process.env.REACT_APP_DD_ENV,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  useSecureSessionCookie: true,
  version: process.env.REACT_APP_PUBLIC_DD_VERSION,
  service: 'Steering Portal browser logs',
  site: 'datadoghq.com',
});

function MainApp() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </BrowserRouter>
    </Provider>
  )
}

function renderMainframe() {
  return (
    <>
      <MobileOnlyView className={"screen-mobile" + (isIE ? ' screen-ie' : '')}>
        <MainApp />
      </MobileOnlyView>
      <TabletView className={"screen-mobile"}>
        <MainApp />
      </TabletView>
      <BrowserView className={"screen-desktop" + (isIE ? ' screen-ie' : '')}>
        <MainApp />
      </BrowserView>

    </>
  );

}

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  renderMainframe(),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
