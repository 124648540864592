const textFontSize = (baseSize, vwWidth) => {
  const min_vw = 640;
  const max_vw = 1080;
  const xl_vw = 1920;
  const xxl_vw = 3840;
  const min_line_height = baseSize / 1.3;
  const mid_line_height = baseSize;
  const max_line_height = baseSize * 2.2;
  const fluid_range_sm =
    min_line_height +
    (mid_line_height - min_line_height) *
    ((vwWidth - min_vw) / (max_vw - min_vw));
  const fluid_range_xl =
    mid_line_height +
    (max_line_height - mid_line_height) *
    ((vwWidth - xl_vw) / (xxl_vw - xl_vw));

  if (vwWidth < min_vw) {
    return min_line_height.toFixed(2);
  } else if (vwWidth >= min_vw && vwWidth < max_vw) {
    return fluid_range_sm.toFixed(2);
  } else if (vwWidth >= max_vw && vwWidth < xl_vw) {
    return mid_line_height.toFixed(2);
  } else if (vwWidth >= xl_vw && vwWidth < xxl_vw) {
    return fluid_range_xl.toFixed(2);
  } else if (vwWidth >= xxl_vw) {
    return max_line_height.toFixed(2);
  }
}

const textFontSizeMobile = (baseSize, vwWidth) => {
  const min_vw = 320;
  const max_vw = 400;
  const min_line_height = baseSize / 1.3;
  const mid_line_height = baseSize;
  const fluid_range_sm =
    min_line_height +
    (mid_line_height - min_line_height) *
    ((vwWidth - min_vw) / (max_vw - min_vw));

  if (vwWidth < min_vw) {
    return min_line_height.toFixed(2);
  } else if (vwWidth >= min_vw && vwWidth < max_vw) {
    return fluid_range_sm.toFixed(2);
  } else if (vwWidth >= max_vw) {
    return mid_line_height.toFixed(2);
  }
}

const inputFontSize = (val, baseSize, vwWidth, inputRefCurrent) => {
  if (!val) return baseSize;

  const min_vw = 640;
  const max_vw = 1080;
  const xl_vw = 1920;
  const xxl_vw = 3840;
  const min_font_size = baseSize / 1.5;
  const mid_font_size = baseSize;
  const max_font_size = baseSize * 2.2;
  const fluid_range_sm =
    min_font_size +
    (mid_font_size - min_font_size) *
    ((vwWidth - min_vw) / (max_vw - min_vw));
  const fluid_range_xl =
    mid_font_size +
    (max_font_size - mid_font_size) * ((vwWidth - xl_vw) / (xxl_vw - xl_vw));

  const len = val.toString().length; // Housenumbers are stored as actual numbers serverside
  const width = inputRefCurrent ? inputRefCurrent.offsetWidth : 0;
  const ratio = 1.5; // Experiment with ratio. Bigger value = bigger text
  const fontSize = (width * ratio) / len;

  if (vwWidth < min_vw) {
    return Math.max(
      min_font_size / 2,
      Math.min(min_font_size, fontSize)
    ).toFixed(2);
  } else if (vwWidth >= min_vw && vwWidth < max_vw) {
    return Math.max(
      fluid_range_sm / 2,
      Math.min(fluid_range_sm, fontSize)
    ).toFixed(2);
  } else if (vwWidth >= max_vw && vwWidth < xl_vw) {
    return Math.max(
      mid_font_size / 2,
      Math.min(mid_font_size, fontSize)
    ).toFixed(2);
  } else if (vwWidth >= xl_vw && vwWidth < xxl_vw) {
    return Math.max(
      fluid_range_xl / 2,
      Math.min(fluid_range_xl, fontSize)
    ).toFixed(2);
  } else if (vwWidth >= xxl_vw) {
    return Math.max(
      max_font_size / 2,
      Math.min(max_font_size, fontSize)
    ).toFixed(2);
  }
}

const inputFontSizeMobile = (val, baseSize, vwWidth, inputRefCurrent) => {
  if (!val) return baseSize;

  const min_vw = 320;
  const max_vw = 400;
  const min_font_size = baseSize / 1.5;
  const mid_font_size = baseSize;
  const fluid_range_sm =
    min_font_size +
    (mid_font_size - min_font_size) *
    ((vwWidth - min_vw) / (max_vw - min_vw));

  const len = val.toString().length === 0 ? 1 : val.toString().length; // Housenumbers are stored as actual numbers serverside
  const width = inputRefCurrent ? inputRefCurrent.offsetWidth : 0;
  const ratio = 1.5; // Experiment with ratio. Bigger value = bigger text
  const fontSize = (width * ratio) / len;
  // return Math.max(16, Math.min(32, fontSize)); // Value cannot exceed 32 or be lower than 16

  if (vwWidth < min_vw) {
    return Math.max(
      mid_font_size / 2,
      Math.min(min_font_size, fontSize)
    ).toFixed(2);
  } else if (vwWidth >= min_vw && vwWidth < max_vw) {
    return Math.max(
      fluid_range_sm / 2,
      Math.min(fluid_range_sm, fontSize)
    ).toFixed(2);
  } else if (vwWidth >= max_vw) {
    return Math.max(
      mid_font_size / 2,
      Math.min(mid_font_size, fontSize)
    ).toFixed(2);
  }
}

const lineHeightSize = (baseSize, vwWidth) => {
  const min_vw = 640;
  const max_vw = 1080;
  const xl_vw = 1920;
  const xxl_vw = 3840;
  const min_line_height = baseSize / 1.3;
  const mid_line_height = baseSize;
  const max_line_height = baseSize * 2.2;
  const fluid_range_sm =
    min_line_height +
    (mid_line_height - min_line_height) *
    ((vwWidth - min_vw) / (max_vw - min_vw));
  const fluid_range_xl =
    mid_line_height +
    (max_line_height - mid_line_height) *
    ((vwWidth - xl_vw) / (xxl_vw - xl_vw));

  if (vwWidth < min_vw) {
    return min_line_height.toFixed(2);
  } else if (vwWidth >= min_vw && vwWidth < max_vw) {
    return fluid_range_sm.toFixed(2);
  } else if (vwWidth >= max_vw && vwWidth < xl_vw) {
    return mid_line_height.toFixed(2);
  } else if (vwWidth >= xl_vw && vwWidth < xxl_vw) {
    return fluid_range_xl.toFixed(2);
  } else if (vwWidth >= xxl_vw) {
    return max_line_height.toFixed(2);
  }
}

const lineHeightSizeMobile = (baseSize, vwWidth) => {
  const min_vw = 320;
  const max_vw = 400;
  const min_line_height = baseSize / 1.3;
  const mid_line_height = baseSize;
  const fluid_range_sm =
    min_line_height +
    (mid_line_height - min_line_height) *
    ((vwWidth - min_vw) / (max_vw - min_vw));

  if (vwWidth < min_vw) {
    return min_line_height.toFixed(2);
  } else if (vwWidth >= min_vw && vwWidth < max_vw) {
    return fluid_range_sm.toFixed(2);
  } else if (vwWidth >= max_vw) {
    return mid_line_height.toFixed(2);
  }
}

const scaleUpTo4K = (baseSize, vwWidth) => {
  const xl_vw = 1920;
  const xxl_vw = 3840;

  // what percentage of the viewport width is the baseSize of the element
  const percentage = (100 / xl_vw) * baseSize;
  // scale up to desired size, with breakpoints

  if (baseSize <= 1) {
    if (vwWidth >= (xxl_vw / 4) * 3) {
      return baseSize * 2;
    } else {
      return baseSize;
    }
  }
  // scale up to desired size, with breakpoints
  if (vwWidth <= xl_vw) {
    return baseSize;
  } else if (vwWidth >= xxl_vw) {
    return (xxl_vw / 100) * percentage.toFixed(2);
  } else {
    return (((vwWidth) / 100) * percentage).toFixed(2);
  }
}

export default { inputFontSize, inputFontSizeMobile, lineHeightSize, lineHeightSizeMobile, scaleUpTo4K, textFontSize, textFontSizeMobile };
