import { HTTP_ERROR_CLEAR, HTTP_ERROR_SET } from '../shared/action-names';
import { httpErrorActionTypes, httpErrorState } from './types';

const initialState: httpErrorState = null;

export default function (
  state = initialState,
  action: httpErrorActionTypes,
): httpErrorState {
  switch (action.type) {
    case HTTP_ERROR_SET:
      return action.payload;
    case HTTP_ERROR_CLEAR:
      return null;
    default:
      return state;
  }
}
