import { ERROR_CODE_CLEAR, ERROR_CODE_SET } from '../shared/action-names';
import { errorCodeActionTypes, ErrorCodeState } from './types';

const initialState: ErrorCodeState = null;

export default function (
  state = initialState,
  action: errorCodeActionTypes,
): ErrorCodeState {
  switch (action.type) {
    case ERROR_CODE_SET:
      return action.payload;
    case ERROR_CODE_CLEAR:
      return null;
    default:
      return state;
  }
}
