import { COMPANY_ID_SET } from '../shared/action-names';
import { companyIDActionTypes, CompanyIDState } from './types';

const initialState: CompanyIDState = null;

export default function (
  state = initialState,
  action: companyIDActionTypes,
): CompanyIDState {
  switch (action.type) {
    case COMPANY_ID_SET:
      return action.payload;
    default:
      return state;
  }
}
