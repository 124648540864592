import { useRef } from 'react';

import { ErrorCode } from '@domain/constants';
import { LPCheckRequestBody } from '@domain/interfaces';
import { licensePlateCheck } from '@domain/services';
import {
  ccsInformationUUIDSet,
  errorCodeSet,
  fidSet,
  formUpdateSG,
  httpErrorSet,
  licensePlateUUIDSet,
  pidSet,
  vehicleInfoSet,
  vehicleIdentificationIDSet,
} from '@domain/action-creators';
import { useDispatch, useSelector } from 'react-redux';
import { insuranceInformationIDSet } from '../../../store/caseDetails/actions';
import { RootState } from '@domain/root-reducer';
import {processTypes} from '@domain/constants';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

type HeaderWithFID = { PID: String; FID: String };
type HeaderDefault = { PID: String };
type HeaderIdentificator = HeaderWithFID | HeaderDefault;

const useLicensePlateCheck = () => {

  const branding = useSelector((state: RootState) => state.branding)
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();
  const checkingLicensePlate = useRef(false);

  const doLicensePlateCheck = async (
    headers: HeaderIdentificator,
    body: LPCheckRequestBody,
  ) => {
    if (checkingLicensePlate.current) {
      return;
    }
    checkingLicensePlate.current = true;
    try {
      const licensePlateValidated = await licensePlateCheck(headers, body);
      if (licensePlateValidated.errorCode === ErrorCode.NO_ERROR) {
        if (licensePlateValidated.fid) {
          dispatch(fidSet(licensePlateValidated.fid));
        }
        if (licensePlateValidated.pid) {
          dispatch(pidSet(licensePlateValidated.pid));
        }
        if (licensePlateValidated.licensePlateUUID) {
          dispatch(licensePlateUUIDSet(licensePlateValidated.licensePlateUUID));
        }
        if (licensePlateValidated.cCSInformationUUID) {
          dispatch(
            ccsInformationUUIDSet(licensePlateValidated.cCSInformationUUID),
          );
        }
        if(licensePlateValidated.insuranceInformationID) {
          dispatch(insuranceInformationIDSet(licensePlateValidated.insuranceInformationID))
        }
        if (licensePlateValidated.vehicleModelName && licensePlateValidated.vehicleManufacturerName) {
          dispatch(
            vehicleInfoSet(
              {vehicleModelName: licensePlateValidated.vehicleModelName, vehicleManufacturerName: licensePlateValidated.vehicleManufacturerName},
            ),
          );
        }
        if (licensePlateValidated.vehicleIdentificationID) {
          dispatch(
            vehicleIdentificationIDSet(
              licensePlateValidated.vehicleIdentificationID,
            ),
          );
        }
        if (
          licensePlateValidated.licensePlate &&
          licensePlateValidated.damageDate
        ) {
          dispatch(
            formUpdateSG({
              licensePlate: licensePlateValidated.licensePlate,
              damageDate: licensePlateValidated.damageDate,
            }),
          );
        }
        if(!licensePlateValidated.insuranceInformationID && !licensePlateValidated.cCSInformationUUID && (branding && branding.objectType !== processTypes.IntakePlus)) {
          dispatch(errorCodeSet(17))
          return
        }
        dispatch(errorCodeSet(0));
      } else {
        dispatch(errorCodeSet(licensePlateValidated.errorCode));
      }
      // @ts-ignore
    } catch ({ error }) {
      dispatch(httpErrorSet({ ...error }));
    }
    checkingLicensePlate.current = false;
  };
  return {
    doLicensePlateCheck,
  };
};

export default useLicensePlateCheck;
