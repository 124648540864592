import { styled } from '@mui/material';

const UList = styled('ul')`
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  width: 100%;
`;

export default UList;
