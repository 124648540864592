import { endpoints } from '@domain/constants';
import { ProcessCheck } from '@domain/interfaces';
import { makeCall } from '@domain/helpers';

async function getProcessCheck(
  pid: string,
  fid?: string,
): Promise<ProcessCheck> {
  const FID = fid ? { FID: fid } : {};
  const headers = { PID: pid, ...FID, 'Pragma': 'no-cache' };

  const search = fid
    ? `${endpoints.processcheck}?PID=${encodeURIComponent(
        pid,
      )}&FID=${encodeURIComponent(fid)}`
    : `${endpoints.processcheck}?PID=${encodeURIComponent(pid)}`;

  const data = await makeCall<ProcessCheck>(search, {
    method: 'GET',
    headers: headers,
  });
  return data as ProcessCheck;
}

export default getProcessCheck;
