import React, { FC } from 'react';
import { styled } from '@mui/material';

import { Container, Heading, Main } from '@domain/components';
import { useWindowSize } from '@domain/hooks';
import { Colors, Scaling } from '@domain/theming';

const StyledMain = styled(Main as any, {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
})`
height: 100vh;
position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  width: 100%;
  background: ${Colors.GREY_LIGHTER};
  padding: ${props => Scaling.scaleUpTo4K(60, props.$vwWidth)}px ${props => Scaling.scaleUpTo4K(80, props.$vwWidth)}px;
  display: flex;
  @media screen and (max-width: 900px) {
    padding: 40px;
  }
  @media screen and (max-width: 420px) {
    padding: 40px 20px;
  }
`
const ErrorPage: FC = () => {

  const { vwWidth } = useWindowSize();

  return (
    <StyledMain $vwWidth={vwWidth}>
      <Container maxWidth={Scaling.scaleUpTo4K(900, vwWidth) + 'px'} justifyContent="center" alignItems="center" padding={Scaling.scaleUpTo4K(10, vwWidth) + 'px'}>
        <Heading level={1}>
          Sorry, er is iets misgegaan. Probeer het over tien minuten nogmaals.
        </Heading>
      </Container>
    </StyledMain>
  );
};

export default ErrorPage;
