import React, { FC, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { styled } from '@mui/material';

import { errorCodeClear } from '@domain/action-creators';
import { ConfirmationDialog, TextButton } from '@domain/components';
import { ErrorCode, processTypes } from '@domain/constants';
import { RootState } from '@domain/root-reducer';
import { page } from '@domain/services';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

const DialogSpan = styled('span')`
  display: inline-block;
  margin-bottom: 20px;
`;
const ErrorDialogs: FC = () => {
    const cookieConsent = useSelector((state: RootState) => state.cookieConsent)
    // tracking
    useEffect(() => {
        page('Start pagina', cookieConsent);
    }, [cookieConsent]);

    const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>()
    const errorCode = useSelector((state: RootState) => state.errorCode)
    const branding = useSelector((state: RootState) => state.branding)

    const isIntakePlus = branding && branding.objectType === processTypes.IntakePlus

    const [invalidLicenseDialogOpen, setInvaldLicenseDialogOpen] = useState<boolean>(false);
    const [licenseInUseDialogOpen, setLicenseInUseDialogOpen] = useState<boolean>(false);
    const [generalErrorDialogOpen, setGeneralErrorDialogOpen] = useState<boolean>(false)
    const [licenseNotFoundDialogOpen, setLicenseNotFoundDialogOpen] = useState<boolean>(false)
    const [ccsServiceDownDialogOpen, setCCSServiceDownDialogOpen] = useState<boolean>(false)

    useEffect(() => {
        if (errorCode && errorCode !== 0) {
            if (errorCode === ErrorCode.INVALID_LICENSE_PLATE || errorCode === ErrorCode.LICENSE_NOT_FOUND) {
                setInvaldLicenseDialogOpen(true)
                return
            }
            if (errorCode === ErrorCode.LICENSE_PLATE_NOT_FOUND_CCS) {
                setLicenseNotFoundDialogOpen(true)
                return
            }
            if (errorCode === ErrorCode.CASE_WITH_LICENSE_ALREADY_EXISTS) {
                setLicenseInUseDialogOpen(true)
                return
            }
            if (errorCode === ErrorCode.CCS_SERVICE_DOWN) {
                setCCSServiceDownDialogOpen(true)
                return
            }
            if (errorCode === ErrorCode.NO_EXPERIENCE) {
                return
            }
            setGeneralErrorDialogOpen(true)
            return
        }

    }, [dispatch, errorCode]);

    const closeDialog = (
        setOpen: (value: SetStateAction<boolean>) => void) => () => {
            dispatch(errorCodeClear())
            setOpen(false);
        };

    return (
        <>
            <ConfirmationDialog
                open={invalidLicenseDialogOpen}
                showYesButton={false}
                showNoButton={false}
                title="Controleer je kenteken"
                content={
                    <>
                        <DialogSpan>
                            Het ingevulde kenteken lijkt niet te bestaan.
                        </DialogSpan>
                        <DialogSpan>
                            Klik{' '}
                            <TextButton onClick={closeDialog(setInvaldLicenseDialogOpen)}>
                                hier
                            </TextButton>
                            {' '}om terug te keren naar de vorige pagina en jouw kenteken opnieuw
                            in te vullen.
                        </DialogSpan>
                    </>
                }
            />
            <ConfirmationDialog
                open={licenseNotFoundDialogOpen}
                yesLabel="OK"
                onYesClick={closeDialog(setLicenseNotFoundDialogOpen)}
                showNoButton={false}
                title="Dit kenteken komt niet voor in onze gegevens"
                content={
                    <>
                        <DialogSpan>
                            Neem contact op met uw verzekeraar.
                        </DialogSpan>
                    </>
                }
            />
            <ConfirmationDialog
                showNoButton={false}
                open={licenseInUseDialogOpen}
                yesLabel="OK"
                onYesClick={closeDialog(setLicenseInUseDialogOpen)}
                title="Dit kenteken heeft al een dossier"
                content={isIntakePlus
                    ?
                    <>
                        <DialogSpan>
                            Uw verzekeraar heeft al een dossier aangemaakt voor deze autoschade.
                            Volg de instructies die u in de eerdere mail heeft gekregen of neem contact
                            op met uw dealer en geef aan dat er al een eXperience dossier bestaat.
                        </DialogSpan>
                    </>
                    : <>
                        <DialogSpan>
                            Er bestaat al een dossier met dit kenteken. Klik op de knop in de
                            email die u heeft ontvangen bij het melden van de schade om de
                            status van dit dossier in te zien.
                        </DialogSpan>
                    </>
                }
            />
            <ConfirmationDialog
                open={ccsServiceDownDialogOpen}
                yesLabel="OK"
                onYesClick={closeDialog(setCCSServiceDownDialogOpen)}
                showNoButton={false}
                title="Het lijkt erop dat er iets niet helemaal goed gaat."
                content={
                    <>
                        <DialogSpan>
                            Probeert u het over een paar minuten nog een keer.
                        </DialogSpan>
                    </>
                }
            />
            <ConfirmationDialog
                showNoButton={false}
                open={generalErrorDialogOpen}
                yesLabel="OK"
                title="Er is iets fout"
                onYesClick={closeDialog(setGeneralErrorDialogOpen)}
                content={
                    <>
                        <DialogSpan>
                            Probeer het zo nogmaals.
                        </DialogSpan>
                    </>
                }
            />
        </>
    );
};

export default ErrorDialogs;
