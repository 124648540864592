import { makeCall } from '@domain/helpers';
import { endpoints } from '@domain/constants';
import { Company } from '@domain/interfaces';

interface Body {
  companies: Company[];
}

async function getIntermediaries(companyParam?: string): Promise<Company[]> {
  const endpoint = companyParam ? endpoints.getIntermediaries + '?Company=' + companyParam : endpoints.getIntermediaries;
  const body = await makeCall<Body>(endpoint, {
    method: 'GET'
  });
  const { companies } = body;
  companies.sort((companyA, companyB) =>
    companyA.brandName < companyB.brandName ? -1 : 1
  );
  return companies;
}

export default getIntermediaries;
