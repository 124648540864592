import React, { FC, Children, ReactNode, memo } from 'react';
import { Box, styled } from '@mui/material';
import { Colors } from '@domain/theming';
import './heading.scss';
import { motion, Variants } from 'framer-motion';

type level = 1 | 2 | 3 | 4 | 5 | 6;
type textAlign =
  | 'left'
  | 'right'
  | 'center'
  | 'justify'
  | 'initial'
  | 'inherit';


interface PropsStyled {
  animate?: any;
  $initial?: any;
  $children: ReactNode | string;
  className?: string;
  $color?: string;
  $level: level;
  $textAlign?: textAlign;
  $margin?: string;
  $marginTop?: string;
  $marginRight?: string;
  $marginBottom?: string;
  $marginLeft?: string;
  variants?: Variants;
}

interface Props {
  animate?: any;
  initial?: any;
  children: ReactNode | string;
  className?: string;
  color?: string;
  level: level;
  textAlign?: textAlign;
  margin?: string;
  marginTop?: string;
  marginRight?: string;
  marginBottom?: string;
  marginLeft?: string;
  variants?: Variants;
}


const H1 = styled(Box, {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
}) <Partial<PropsStyled>>`
  color: ${props => (props.color === 'white' ? Colors.WHITE : Colors.GREY_DARK)};
  text-align: ${props => props.$textAlign};
  margin: ${props => props.$margin};
  margin-top: ${props => props.$marginTop};
  margin-right: ${props => props.$marginRight};
  margin-bottom: ${props => props.$marginBottom};
  margin-left: ${props => props.$marginLeft};
`;

const Heading: FC<Props> = ({
  animate,
  className,
  children,
  color,
  initial,
  level,
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  textAlign = 'center',
  variants,
}) => {
  const headingLevel =
    level === 1
      ? motion.h1
      : level === 2
        ? motion.h2
        : level === 3
          ? motion.h3
          : level === 4
            ? motion.h4
            : level === 5
              ? motion.h5
              : motion.h6

  return (
    <H1
      as={headingLevel}
      className={className}
      $color={color}
      $margin={margin}
      $marginTop={marginTop}
      $marginRight={marginRight}
      $marginBottom={marginBottom}
      $marginLeft={marginLeft}
      $textAlign={textAlign}
      $initial={initial}
      animate={animate}
      variants={variants}
    >
      {Children.toArray(children)}
    </H1>
  );
};

export default memo<Props>(Heading);
