import { ErrorI } from '@domain/interfaces';
import { HTTP_ERROR_CLEAR, HTTP_ERROR_SET } from '../shared/action-names';
import { httpErrorActionTypes } from './types';

export function httpErrorSet(error: ErrorI): httpErrorActionTypes {
  return { type: HTTP_ERROR_SET, payload: error };
}

export function httpErrorClear(): httpErrorActionTypes {
  return { type: HTTP_ERROR_CLEAR, payload: null };
}
