const camelToPascal = (str: string): string => {
  if (str === 'uuid') {
    return 'UUID';
  }
  if (str === 'ip') {
    return 'IP';
  }

  const firstChar = str[0].toUpperCase();
  return firstChar + str.slice(1);
};

export default camelToPascal;
