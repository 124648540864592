import { browserStorage } from '@domain/helpers';
import { CONSENT_SET } from '../shared/action-names';
import { consentActionTypes, ConsentState } from './types';

const initialState: ConsentState = browserStorage.cookieConsentStorage.get()

export default function (
  state = initialState,
  action: consentActionTypes,
): ConsentState {
  switch (action.type) {
    case CONSENT_SET:
      return action.payload;
    default:
      return state;
  }
}
