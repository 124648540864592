function buildURL(
  baseURL: string,
  params: Array<any>,
): string {
  return params.reduce(
    (acc, [name, value]) => {
      if (!!value) {
        if (['uuid', 'fid', 'pid', 'rid', 'url'].includes(name.toLowerCase())) {
          return `${acc}&${name.toUpperCase()}=${encodeURIComponent(value)}`
        }
        return `${acc}&${name.charAt(0).toUpperCase() + name.slice(1)
          }=${encodeURIComponent(value)}`
      }
      return acc
    },
    baseURL,
  )
}

export default buildURL;
