import React, { FC } from 'react';

import { Colors, Scaling, Shadows } from '@domain/theming';
import { motion } from 'framer-motion';
import { styled } from '@mui/material';
import { useWindowSize } from '@domain/hooks';
// import { string } from 'yup';

interface Props {
  animated?: boolean;
  animation?: object;
  background?: string;
  border?: string;
  borderColor?: string;
  className?: string;
  fixed?: boolean;
  height?: number;
  imgSrc?: string;
  logoAlign?: 'center' | 'left';
  shadow?: boolean;
  vwWidth?: number;
}

interface StyledProps {
  $animated?: boolean;
  $animation?: object;
  $background?: string;
  $border?: string;
  $borderColor?: string;
  className?: string;
  $fixed?: boolean;
  $height?: number;
  $imgSrc?: string;
  $logoAlign?: 'center' | 'left';
  $shadow?: boolean;
  $vwWidth?: number;
}


const HeaderContainer = styled(motion.div as any, {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
}) <StyledProps>`
  width: 100%;
  height: ${props => Scaling.scaleUpTo4K(props.$height, props.$vwWidth)}px;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 8px 0px;
  position: ${props => props.$fixed ? 'absolute' : 'relative'};
  top: 0;
  background: ${props => props.$background};
  border-bottom: ${props => !!props.$border ? Scaling.scaleUpTo4K(props.$border, props.$vwWidth) : '0px'} solid ${props => !!props.$border ? props.$borderColor : Colors.GREY_PRIMARY};
  box-shadow: ${props => props.$shadow ? Shadows().Shadow3 : 'none'};
  padding: ${props => Scaling.scaleUpTo4K(10, props.$vwWidth)}px;
  z-index: 1;
  + main {
    padding-top: ${props => props.$fixed ? Scaling.scaleUpTo4K(83, props.$vwWidth) + 'px' : '0px'}
  }
`;

const LogoContainer = styled('div', {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
}) <any>`
  display: flex;
  align-items: flex-end;
  width:100%;
  height:  ${props => Scaling.scaleUpTo4K(props.$height, props.$vwWidth)}px;
  padding: ${props => Scaling.scaleUpTo4K(4, props.$vwWidth)}px 0;
`;

const LogoImg = styled('img')`
  width: auto;
  margin: auto;
  height: 80%;
  max-width: 100%;
`;

const variants = (height: number) => {
  return {
    hidden: {
      y: -(Scaling.scaleUpTo4K(height + 6)),
      transition: {

      }
    },
    visible: {
      y: 0,
      transition: {
        ease: 'easeIn',
        duration: 0.3,
      }
    },
  }
}

const Header: FC<Props> = ({
  animated = false,
  background,
  border,
  borderColor,
  fixed = true,
  height = 83,
  imgSrc = '/img/vindmijnhersteller.png',
  logoAlign = 'center',
  shadow = false
}) => {

  const { vwWidth } = useWindowSize();

  const headerAnimation = {
    initial: "hidden",
    animate: "visible",
    variants: animated && variants(height),
  }

  return (

    <HeaderContainer
      $animation={headerAnimation}
      $background={background}
      $border={border}
      $borderColor={borderColor}
      className={`header`}
      $fixed={fixed}
      $height={height}
      $logoAlign={logoAlign}
      $shadow={shadow}
      $vwWidth={vwWidth}
    >
      <LogoContainer $vwWidth={vwWidth} $height={height}>
        <LogoImg
          src={imgSrc}
        // height={height - 1}
        />
      </LogoContainer>
    </HeaderContainer>
  );
};


export default Header;
