import React, {
    ChangeEvent,
    FC,
    FormEvent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment'
import ReCAPTCHA from 'react-google-recaptcha';

import { validationSchema } from './validationSchema';

import { damageTypeUpdate } from '@domain/action-creators';
import { Container, ErrorMessage, Form, Recaptcha } from '@domain/components';

import { useLicensePlateCheck, useValidation, useWindowSize } from '@domain/hooks';
import { ContentStructureFormBlock, FormDataCreation, InputName, TypeOfDamage } from '@domain/interfaces';
import { RootState } from '@domain/root-reducer';
import FormSG from './FormSG';
import { captchaKey } from '@domain/constants';
import { generateSGRedirectURL } from '@domain/helpers';
import { Scaling } from '@domain/theming';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
interface Props {
    content: ContentStructureFormBlock;
}
const FormWrapperSG: FC<Props> = ({ content }) => {

    interface EventTarget {
        name: InputName;
        value: string;
    }

    const {
        doLicensePlateCheck,
    } = useLicensePlateCheck()

    const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>()
    const { vwWidth } = useWindowSize();
    const caseDetails = useSelector((state: RootState) => state.caseDetails)
    const pid = useSelector((state: RootState) => state.pid)
    const fid = useSelector((state: RootState) => state.fid)

    const [formDataCreation, setFormDataCreation] = useState<FormDataCreation>({ licensePlateCreation: caseDetails.licensePlate, damageDateCreation: caseDetails.damageDate });
    const errorCode = useSelector((state: RootState) => state.errorCode)
    const [licenseCreationBlurred, setLicenseCreationBlurred] = useState<boolean>(false);
    const [dateCreationBlurred, setDateCreationBlurred] = useState<boolean>(false);
    const [damageType, setDamageType] = useState<TypeOfDamage>('Carrosserie');

    useEffect(() => {
        if (caseDetails.damageType) {
            setDamageType(caseDetails.damageType as TypeOfDamage)
        }
    }, [caseDetails.damageType])

    const updateFormDataCreation = useCallback(
        (name: InputName, value: string) =>
            setFormDataCreation((d: any) => ({ ...d, [name]: value })),
        [],
    );

    const errors = useValidation(validationSchema, formDataCreation)


    const formInitiated = useRef(false);

    useEffect(() => {
        if (caseDetails.damageDate && caseDetails.licensePlateUUID && caseDetails.damageType && (caseDetails.insuranceInformationID || caseDetails.cCSInformationUUID) && errorCode == null && !formInitiated.current) {
            const redirectLinkSG = generateSGRedirectURL(caseDetails, pid || '', fid || '', 'direct');
            window.location.href = redirectLinkSG
        }
        if (caseDetails.damageDate && caseDetails.licensePlateUUID && caseDetails.damageType && (caseDetails.insuranceInformationID || caseDetails.cCSInformationUUID) && errorCode === 0) {
            const redirectLinkSG = generateSGRedirectURL(caseDetails, pid || '', fid || '', 'start-page');
            window.location.href = redirectLinkSG
        }
    }, [caseDetails, pid, errorCode, formInitiated, fid])

    const captchaRef = useRef<ReCAPTCHA | null>(null);

    const [isLoading, setIsLoading] = useState(false);

    const onChangeCaptcha = async (captchaCode: string | null) => {
        if (captchaCode === null) {
            return;
        }
        const headers = fid
            ? { PID: pid as string, FID: fid }
            : { PID: pid as string, }

        const body = {
            damageDate: formDataCreation.damageDateCreation || "",
            licensePlate: formDataCreation.licensePlateCreation,
            damageType: caseDetails.damageType,
            captchaCode: captchaCode,
            siteKey: captchaKey
        }
        setIsLoading(true);
        await doLicensePlateCheck(headers, body)
        setIsLoading(false);
        const captcha = captchaRef.current;
        if (!!captcha) {
            captcha.reset();
        }
    };

    const handleSubmitDamageType = (event?: FormEvent<HTMLElement>) => {
        event && event.preventDefault();
        formInitiated.current = true
        dispatch(damageTypeUpdate(damageType))
    };


    const handleDamageType = (event: React.MouseEvent<HTMLElement>, selectedDamageType: TypeOfDamage) => {
        if (selectedDamageType !== null) {
            setDamageType(selectedDamageType);
        }
    };

    const onChangeCreation = useCallback(
        (event: ChangeEvent<HTMLInputElement> | Date | null) => {
            if (event == null) {
                return
            }
            if (event instanceof Date) {
                const dateFormat = 'YYYY-MM-DD';
                const formattedDate = moment(event).format(dateFormat);

                updateFormDataCreation('damageDateCreation', formattedDate)
                return
            }
            const { name, value } = event.target as EventTarget;

            if (name === 'licensePlateCreation') {
                const nonAlphaNumRegExp = /[^0-9A-Z]/gi;
                const newValue = value
                    .toUpperCase()
                    .replace(nonAlphaNumRegExp, '')
                    .slice(0, 6);
                updateFormDataCreation(name, newValue);
                return;
            }
            updateFormDataCreation(name, value);
        },
        [updateFormDataCreation],
    );

    const onBlurLicensePlate = () => setLicenseCreationBlurred(true)
    const onBlurDamageDate = () => setDateCreationBlurred(true)

    const [captchaExecuted, setCaptchaExecuted] = useState<boolean>(false);

    const handleResetCreation = () => {
        dispatch(damageTypeUpdate(""))
        setFormDataCreation({ 'licensePlateCreation': "", 'damageDateCreation': "" });
        setLicenseCreationBlurred(false)
        setDateCreationBlurred(false)
        if (!captchaExecuted) {
            return;
        }
        const captcha = captchaRef.current
        if (!!captcha) {
            captcha.reset();
            setCaptchaExecuted(false);
        }
    };

    const shouldShowError = (name: InputName, blurred: boolean): boolean => {
        if (!blurred) {
            return false;
        }
        if (errors === null) {
            return false
        }
        return !!errors[name]
    };

    const showError = (name: InputName, blurred: boolean): ReactNode => {
        if (!shouldShowError(name, blurred)) {
            return <></>;
        }
        if (errors === null) {
            return <></>;
        }
        return (
            <Container marginTop={'-' + Scaling.scaleUpTo4K(14, vwWidth) + 'px'} maxHeight={Scaling.scaleUpTo4K(14, vwWidth) + 'px'}>
                <ErrorMessage dynamicFontSize={true}>{errors[name]}</ErrorMessage>
            </Container>
        );
    };

    const verifyAndSubmit = async (event?: FormEvent<HTMLFormElement>) => {
        event && event.preventDefault();
        const captcha = captchaRef.current;
        if (!captcha) {
            return;
        }
        if (errors === null) {
            setCaptchaExecuted(true);
            captcha.execute();
        }
    }

    return (
        <Form onSubmit={verifyAndSubmit}>
            <FormSG
                onChangeCreation={onChangeCreation}
                handleSubmitDamageType={handleSubmitDamageType}
                handleDamageType={handleDamageType}
                damageType={damageType}
                shouldShowError={shouldShowError}
                showError={showError}
                formDataCreation={formDataCreation}
                onBlurLicensePlate={onBlurLicensePlate}
                onBlurDamageDate={onBlurDamageDate}
                caseDetails={caseDetails}
                handleResetCreation={handleResetCreation}
                licenseCreationBlurred={licenseCreationBlurred}
                dateCreationBlurred={dateCreationBlurred}
                errors={errors}
                content={content}
                isLoading={isLoading}
            />
            <Recaptcha
                onChange={(captchaCode) => onChangeCaptcha(captchaCode)}
                captchaRef={captchaRef}
                siteKey={captchaKey}
            />
        </Form>
    )
}

export default FormWrapperSG
