import { datadogLogs } from '@datadog/browser-logs';
import { routes } from '@domain/constants';
import { browserStorage, buildURL } from '@domain/helpers';
import {
  CaseData,
  CaseDataCreation,
  FormSGUpdate,
  HeaderIdentificator,
  InternalExternal,
  ParamsUrl,
  ServerResponseDICreation,
  ServerResponseVIP,
} from '@domain/interfaces';
import { RootState } from '@domain/root-reducer';
import { getCase, postCaseDICreation, postCaseVIP } from '@domain/services';
import { TypeOfDamage } from '@domain/interfaces';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { errorCodeSet } from '../errorCode/actions';
import { httpErrorSet } from '../httpError/actions';
import { urlCreationSet, vmhEmbedRedirectSet } from '../redirectUrls/actions';
import {
  ATTRIBUTE_FROM_URL_SET,
  CCS_INFORMATION_UUID_SET,
  DAMAGE_TYPE_UPDATE,
  FORM_DI_UPDATE,
  FORM_SG_UPDATE,
  FORM_VIP_UPDATE,
  GET_EMBED_VIP_CASE,
  INSURANCE_INFORMATION_ID_SET,
  INTERNAL_EXTERNAL_SET,
  LICENSEPLATE_UUID_SET,
  VEHICLE_IDENTIFICATION_ID_SET,
  VEHICLE_INFO_SET
} from '../shared/action-names';
import { caseDetailActionTypes } from './types';

export function damageTypeUpdate(
  damageType: TypeOfDamage,
): caseDetailActionTypes {
  return { type: DAMAGE_TYPE_UPDATE, payload: damageType };
}

export function formUpdateSG(
  dataToUpdate: FormSGUpdate,
): caseDetailActionTypes {
  return { type: FORM_SG_UPDATE, payload: dataToUpdate };
}

export function attributesFromUrlSet(params: ParamsUrl): caseDetailActionTypes {
  return { type: ATTRIBUTE_FROM_URL_SET, payload: params };
}

export function vehicleIdentificationIDSet(
  vehicleIdentificationID: string,
): caseDetailActionTypes {
  return {
    type: VEHICLE_IDENTIFICATION_ID_SET,
    payload: vehicleIdentificationID,
  };
}

export function vehicleInfoSet(vehicleInfo: {vehicleModelName:string, vehicleManufacturerName: string}): caseDetailActionTypes {
 return {
    type: VEHICLE_INFO_SET,
    payload: vehicleInfo,
  };
}

export function ccsInformationUUIDSet(
  cCSInformationUUID: string,
): caseDetailActionTypes {
  return {
    type: CCS_INFORMATION_UUID_SET,
    payload: cCSInformationUUID,
  };
}

export function insuranceInformationIDSet(
  insuranceInformationID: string,
): caseDetailActionTypes {
  return {
    type: INSURANCE_INFORMATION_ID_SET,
    payload: insuranceInformationID,
  };
}


export function internalExternalSet(
  internalExternal: InternalExternal,
): caseDetailActionTypes {
  return {
    type: INTERNAL_EXTERNAL_SET,
    payload: internalExternal,
  };
}

export function licensePlateUUIDSet(
  licensePlateUUID: string,
): caseDetailActionTypes {
  return {
    type: LICENSEPLATE_UUID_SET,
    payload: licensePlateUUID,
  };
}

export function postCaseDI(
  caseDataCreation: CaseDataCreation,
  header: HeaderIdentificator,
): ThunkAction<void, RootState, unknown, Action> {
  return async function (dispatch, getState) {
    try {
      const response: ServerResponseDICreation = await postCaseDICreation(
        caseDataCreation,
        header,
      );
      dispatch({ type: FORM_DI_UPDATE, payload: response });

      if (response.errorCode !== 0) {
        dispatch(errorCodeSet(response.errorCode));
        return;
      }

      const cpURL = process.env.REACT_APP_CP_DI_URL;
      const caseDetails = getState().caseDetails;
      const { token, objectType, pid, fid, ...caseDetailsForUrl } = caseDetails;
         const consentCode = JSON.stringify(browserStorage.cookieConsentStorage.get())

      const redirectURL = buildURL(
        `${cpURL}/onboarding?`,
        Object.entries({
          ...caseDetailsForUrl,
          PID: getState().pid,
          FID: getState().fid,
          SPCase: `${true}`,
          Token: token || '',
          ConsentCode:consentCode
        }),
      );
      // Saving generated URL to the store
      dispatch(urlCreationSet({ urlCreation: redirectURL }));
       // @ts-ignore
    } catch ({ error }) {
      const errorLogMessage = "Error on post di case"
      const errorInstance = new Error(errorLogMessage)
      datadogLogs.logger.error(errorLogMessage, { error: error }, errorInstance)
      dispatch(httpErrorSet({ ...error }));
    }
  };
}

export function postCaseVMH(
  caseData: CaseData,
): ThunkAction<void, RootState, unknown, Action> {
  return async function (dispatch) {
    try {
      const response: ServerResponseVIP = await postCaseVIP(caseData);
      const { captchaCode, ...responseMinusCaptcha } = response;
      dispatch({ type: FORM_VIP_UPDATE, payload: responseMinusCaptcha });

      if (response.errorCode !== 0) {
        dispatch(errorCodeSet(response.errorCode));
        return;
      }

      const spURL = process.env.REACT_APP_SP_URL;
      const redirectURL = `${spURL}${routes.confirmation}?uuid=${response.uuid}`;

      // Saving generated URL to the store
      dispatch(vmhEmbedRedirectSet({ vmhEmbedRedirect: redirectURL }));
       // @ts-ignore
    } catch ({ error }) {
      const errorLogMessage = "Error on post case vmh"
      const errorInstance = new Error(errorLogMessage)
      datadogLogs.logger.error(errorLogMessage, { error: error }, errorInstance)
      dispatch(httpErrorSet({ ...error }));
    }
  };
}

export function getEmbedCase(
  uuid: string,
): ThunkAction<void, RootState, unknown, Action> {
  return async function (dispatch) {
    try {
      const response: ServerResponseVIP = await getCase(uuid);
      dispatch({ type: GET_EMBED_VIP_CASE, payload: response });

      if (response.errorCode !== 0) {
        dispatch(errorCodeSet(response.errorCode));
        return;
      }
       // @ts-ignore
    } catch ({ error }) {
      const errorLogMessage = "Error getting vmh case"
      const errorInstance = new Error(errorLogMessage)
      datadogLogs.logger.error(errorLogMessage, { error: error }, errorInstance)
      dispatch(httpErrorSet({ ...error }));
    }
  };
}
