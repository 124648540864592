import { consentSet } from '@domain/action-creators'
import { browserStorage } from '@domain/helpers'
import { RootState } from '@domain/root-reducer'
import {useState, } from 'react'
import { useDispatch } from 'react-redux'
import { Action } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

export const useCookieConsent = () => {

 const [preferences, setPreferences] = useState({ ga: true, hotjar: true })

 const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>()

  return {
    preferences,
    updateConsent: (consented:{ ga: boolean, hotjar: boolean }) => {
        dispatch(consentSet(consented))
        browserStorage.cookieConsentStorage.save(consented)
    },
    updatePreferences: (destinationId: 'ga' | 'hotjar') => setPreferences((currentState) => ({
                            ...currentState,
                            [destinationId]: !preferences[destinationId],
                          })),
  }
}
