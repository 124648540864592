import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material';
import {
  Container,
  NextButton,
  ToggleCardGroup,
  UseIcon,
} from '@domain/components';
import { RootState } from '@domain/root-reducer';
import { ContentStructureFormBlock } from '@domain/interfaces';
import { useWindowSize } from '@domain/hooks';
import { Scaling } from '@domain/theming';
import { browserStorage } from '@domain/helpers';

interface ViewportProps {
  $vwWidth?: number;
}

const StyledWrapper = styled('div')`
  height:100%;
  display: flex;
  width: 100%;
  flex-direction: column;
`
const Wrapper = styled('div', {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
}) <ViewportProps>` 
    margin: auto 0;
    padding-top: ${props => Scaling.scaleUpTo4K(30, props.$vwWidth)}px;

    
  @media screen and (max-width: 900px) {
    padding-top: 0;
  }
`

const StyledContainer = styled(Container as any)`
    align-content: space-around;
    flex-wrap: wrap;
`;

const ButtonContainer = styled(Container as any, {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
})`
    justify-content: center;
    max-width: 100%;
    margin: auto;
    flex: 0 0 auto;
    .volgende-button {
      margin-left: auto;
    }

    @media screen and (max-width: 580px) {
      margin: 0;
      margin-left: auto;
    }
`;

const ButtonWrapper = styled(Container as any, {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
})`
    align-items: flex-end;
    width: ${props => Scaling.scaleUpTo4K(580, props.$vwWidth)}px;
    max-width: 100%;

    .screen-ie & {
      max-width: ${props => Scaling.scaleUpTo4K(580, props.$vwWidth)}px;
    }

    @media screen and (max-width: 580px) {
      width: 100%
      max-width: 100%
    }
`;

interface Props {
  content: ContentStructureFormBlock;
}

type Link = null | string

const FormWrapperRhion: FC<Props> = ({ content }) => {

  const redirectUrls = useSelector((state: RootState) => state.redirectUrls)
  const { vwWidth } = useWindowSize();

  const [currentLink, setCurrentLink] = useState<Link>(redirectUrls.cascoRedirectURL)

  const contentCardOne = content['card-one']
  const contentCardTwo = content['card-two']
  const nextButtonText = currentLink === redirectUrls.cascoRedirectURL ? 'Maak een herstelafspraak' : currentLink === redirectUrls.ownBodyshopRedirectURL ? 'Dien offerte in' : 'Maak een keuze'

  const generateLink = () => {
    const consentCode = JSON.stringify(browserStorage.cookieConsentStorage.get())
    window.location.href = `${currentLink}&ConsentCode=${consentCode}`
  }
  return (
    <StyledWrapper>
      <Wrapper $vwWidth={vwWidth}>
        <StyledContainer
          boxSizing="content-box"
          flexDirection="column"
          alignItems="center"
        >
          <ToggleCardGroup cards={[{ ...contentCardOne, link: redirectUrls.cascoRedirectURL }, { ...contentCardTwo, link: redirectUrls.ownBodyshopRedirectURL, className: 'secondary_button' }]} setCurrentLink={setCurrentLink} initialOpenCard={0} />
        </StyledContainer>
      </Wrapper>
      <ButtonContainer $vwWidth={vwWidth}>
        <ButtonWrapper $vwWidth={vwWidth}>
          <NextButton
            variant="outline"
            className="volgende-button"
            onClick={generateLink}
            dynamicFontSize={true}
            disabled={!currentLink}
            iconButton={true}
          ><span>{nextButtonText}</span>
            <UseIcon name="arrow-right" className="button__icon" />
          </NextButton>
        </ButtonWrapper>
      </ButtonContainer>
    </StyledWrapper>
  )
}

export default FormWrapperRhion
