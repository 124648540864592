import React, { FC, Children } from 'react';
import Card from '../Card';
import Container from '../Container';
import Heading from '../Heading';
import Paragraph from '../Paragraph';
import { styled } from '@mui/material';
import { Colors, Scaling, Shadows } from '@domain/theming';
import { useWindowSize } from '@domain/hooks';
interface Props {
  reviewName: string;
  children?: React.ReactNode
  reviewValue: string;
}

interface ViewportProps {
  $vwWidth?: number;
}

const ReviewContainer = styled('div', {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
}) <ViewportProps>`
  display: flex;
  align-items: stretch;
  width: 27%;
  flex: 1 1 auto;
  min-width: ${props => Scaling.scaleUpTo4K(210, props.$vwWidth)}px;
  max-width: ${props => Scaling.scaleUpTo4K(250, props.$vwWidth)}px;
  padding-bottom: ${props => Scaling.scaleUpTo4K(50, props.$vwWidth)}px;
  margin: 0 ${props => Scaling.scaleUpTo4K(50, props.$vwWidth)}px ${props => Scaling.scaleUpTo4K(80, props.$vwWidth)}px;
`;
const ReviewContent = styled(Card as any, {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
})`
  color: ${Colors.WHITE};
  width: 100%;
  background: ${Colors.BLACK};
  padding: ${props => Scaling.scaleUpTo4K(40)}px ${props => Scaling.scaleUpTo4K(25, props.$vwWidth)}px ${props => Scaling.scaleUpTo4K(70, props.$vwWidth)}px;
  h3 {
    color: ${Colors.WHITE};
  }
`;
const ReviewBadge = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  position:absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  border: 4px solid ${Colors.GREY_PRIMARY};
  border-radius: 50%;
  color: ${Colors.WHITE};
  background: ${Colors.WHITE};
  box-shadow: ${Shadows().Shadow4};
`;

const ReviewCard: FC<Props> = ({ reviewName, reviewValue, children }) => {
  const { vwWidth } = useWindowSize();
  return (
    <ReviewContainer
      className='review__container'
      $vwWidth={vwWidth}
    >
      <ReviewContent
        $shadow={6}
        $vwWidth={vwWidth}
      >
        <Container paddingBottom={Scaling.scaleUpTo4K(40, vwWidth) + 'px'}>
          <Paragraph textAlign="center">
            {Children.toArray(children)}
          </Paragraph>
        </Container>
        <Heading level={3} marginTop="auto">{reviewName}</Heading>
        <ReviewBadge><Heading level={1} marginLeft="3px">{reviewValue}</Heading></ReviewBadge>
      </ReviewContent>
    </ReviewContainer>
  )
}

export default ReviewCard;
