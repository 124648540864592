import { format } from 'date-fns';
import { date, object, string } from 'yup';

const licenseRegex = /^[0-9a-zA-Z]{6}$/;

const validationSchema = object({
  damageDateCreation: date().required().max(new Date(), `Schadedatum moet eerder zijn dan ${format(new Date(), 'dd/MM/yyyy')}`).label('Schadedatum'),
  licensePlateCreation: string()
    .required()
    .length(6)
    .matches(licenseRegex, 'Vul alstublieft een geldig kenteken in')
    .trim()
    .uppercase()
    .label('Kenteken'),
});

export { validationSchema };
