import { endpoints } from '@domain/constants';
import { makeCall } from '@domain/helpers';
import { ServerResponseVIP } from '@domain/interfaces';

async function confirmCase(uuid: string): Promise<ServerResponseVIP> {
    const caseUpdated = await makeCall<ServerResponseVIP>(endpoints.updateCase, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: {
            uuid,
            CustomerSteeringDecision: 'VIP',
        },
    });

    return caseUpdated as ServerResponseVIP
}

export default confirmCase;