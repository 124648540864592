import { CaseInfo, } from '@domain/interfaces';
import { browserStorage } from '../browserStorage';
import buildURL from '../buildURL';

const generateIntakeRedirectURL = (caseDetails: CaseInfo, pid: string, fid: string, direct: string) => {

  const consentCode = JSON.stringify(browserStorage.cookieConsentStorage.get())
  const currenturl = window.location.href.replace(window.location.search, '')
  const isSpCaseAndPid = { ...caseDetails, SPCase: true, PID: pid, Redirect: direct, FID: fid, ConsentCode: consentCode, URL: currenturl }
  const SGredirectUrl = buildURL(
    `${process.env.REACT_APP_CP_DI_URL}/nieuw?`,
    Object.entries(isSpCaseAndPid),
  );

  const url = SGredirectUrl;
  return url;
};

export default generateIntakeRedirectURL;
