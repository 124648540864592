const pascalToCamel = (str: string): string => {
  if (str === 'UUID') {
    return 'uuid';
  } else if (str === 'VIPAvailable') {
    return 'vipAvailable';
  }
  if (str === 'IP') {
    return 'ip';
  }
  if (str === 'FID') {
    return 'fid'
  }
  if (str === 'PID') {
    return 'pid'
  }
  const firstChar = str[0].toLowerCase();
  return firstChar + str.slice(1);
};

export default pascalToCamel;
