import React, { FC } from 'react';

const QuestionMark: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    color="white"
  >
    <g transform="translate(0.337 0.337)">
      <g transform="translate(-0.323 -0.323)">
        <rect
          width="20"
          height="20"
          rx="10"
          transform="translate(-0.013 -0.013)"
        />
        <path
          d="M25.043,23.577a.609.609,0,0,1-.609-.609V21.349a.609.609,0,0,1,.609-.609,1.826,1.826,0,1,0-1.826-1.826.609.609,0,1,1-1.217,0A3.043,3.043,0,1,1,25.652,21.9v1.071A.609.609,0,0,1,25.043,23.577Z"
          transform="translate(-15.057 -10.793)"
          fill="currentColor"
        />
        <circle
          cx="0.609"
          cy="0.609"
          r="0.609"
          transform="translate(9.378 13.772)"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default QuestionMark;
