import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import { companyNames } from '@domain/constants'
import { useSubdomain } from '@domain/hooks';
import { RootState } from '@domain/root-reducer';

interface Props {
    faviconCustom?: string
}

const HtmlHead: FC<Props> = ({ faviconCustom }) => {

    const branding = useSelector((state: RootState) => state.branding)
    const subdomain = useSubdomain();

    if (faviconCustom === 'no-branding-call') {
        return (
            <Helmet>
                <title>Vind mijn hersteller</title>
                <link rel="shortcut icon" href={'/favicon.ico'} />
            </Helmet>

        )
    }
    if (subdomain === null) {
        return (
            <Helmet />
        )
    }

    const chooseTitle = () => {
        if (!!branding && branding.objectType === companyNames.Schadegarant) {
            return { title: 'eXperience', favicon: '/favicon_sg.ico' }
        }
        if (!!branding && branding.objectType === 'dicase') {
            return { title: 'Digitale intake', favicon: '/favicon_akzo.ico' }
        }
        if (!!branding && branding.objectType === companyNames.Rhion) {
            return { title: 'Uw schadebeschrijving', favicon: '/favicon_rhion.ico' }
        }
        else {
            return { title: '', favicon: '/favicon.ico' }
        }
    }

    return (
        <Helmet>
            <title>{chooseTitle().title}</title>
            <link rel="shortcut icon" href={!!faviconCustom ? 'data:image/x-icon;base64,' + faviconCustom : chooseTitle().favicon} />
        </Helmet>
    );
};


export default HtmlHead;
