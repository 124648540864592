// page function
// to keep track of page hit
const writeKeyGA = process.env.REACT_APP_GA_API_KEY
const page = (pageName: string, consent: { ga: boolean, hotjar: boolean } | null) => {
 if(consent && consent.ga && window.gtag) {
  window.gtag('event', 'page_view', {
       page_title: pageName,
       send_to: writeKeyGA
     })
 }
 if(consent && consent.hotjar && window.hj) {
      window.hj('stateChange', `/${pageName || 'no-step-found'}`)
 }
}

 export default page