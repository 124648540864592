import React, { FC, useState } from 'react';

import { Colors, Scaling, Shadows } from '@domain/theming';
import { styled } from '@mui/material';

import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Button from '../Button';
import ConfirmationDialog from '../ConfirmationDialog';
import Container from '../Container';
import Text from '../Text';
import { companyNames, processTypes } from '@domain/constants';
import { useSelector } from 'react-redux';
import { RootState } from '@domain/root-reducer';
import { useWindowSize } from '@domain/hooks';
import { motion } from "framer-motion"
interface Props {
  vwWidth?: number;
  preferences: { ga: boolean, hotjar: boolean },
  updateConsent: (consented: { ga: boolean, hotjar: boolean }) => void,
  updatePreferences: (destinationId: 'ga' | 'hotjar') => void,
}

const ConsentBanner = styled(motion.div)`
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100%;
  > div {
    padding: 30px 40px 50px 40px;
    width: 88%;
    max-width: 1200px;
    margin: auto;
    position: relative;
    top: 30px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background-color: ${Colors.GREY_LIGHT};
    box-shadow: ${Shadows().Shadow4};
  }
  .embed-form + & {
    display: none;
  }
`;

const BannerButton = styled(Button as any)`
  width: 120px;
  text-transform: initial;
  flex: 0 1 auto;
  padding: 10px;
  height: auto;
  border-radius: 15px;
  margin-right: 20px;
`;
const Link = styled('a')`
  color: ${Colors.BLACK};
  text-decoration: underline;
  cursor: pointer;
  padding: 10px 0 12px;
`;
const TableStyled = styled(Table)`
  margin-bottom: 40px;
`;

const variants = {
  open: {
    opacity: 1,
    transition: {
      duration: .3,
      delay: .3,
    }
  },
  closes: {
    opacity: 0,
    transition: {
      duration: 1,
    }
  }
}
interface Destination {
  category: string;
  description: string;
  id: 'ga' | 'hotjar';
  name: string;
}
const categories: Record<string, Destination[]> = {
  "Analytics": [{
    category: 'Analytics',
    description: `Google Analytics 4 (GA4) is the next generation of Analytics which collects event-based data from both websites and apps`,
    id: 'ga',
    name: 'Google Analytics 4',
  }],
  "Heatmaps & Recordings": [{
    category: 'Heatmaps & Recordings',
    description: `Hotjar is a heatmap and recording tool for tracking what your users are doing on your website, and where they're doing it`,
    id: 'hotjar',
    name: 'Hotjar',
  }]
}
const ConsentDialogContent: FC<Props> = ({
  vwWidth,
  preferences,
  updatePreferences
}) => (
  <>
    <Container margin={`${Scaling.scaleUpTo4K(10, vwWidth)}px 0 ${Scaling.scaleUpTo4K(20)}px`}>
      <Text>
        We gebruiken de data verzameld door de cookies en de JavaScript
        Libraries om uw ervaring met de website te verbeteren, website-verkeer
        te analyzeren, en de algemene prestatie van de website te verhogen.
      </Text>
    </Container>
    <TableStyled size="small">
      <TableHead>
        <TableRow>
          <TableCell>Allow</TableCell>
          <TableCell>Category</TableCell>
          <TableCell>Purpose</TableCell>
          <TableCell>Tool Name</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(categories).map((category) => {
          return (categories[category].map((destination: Destination) => (
            <TableRow key={destination.id}>
              <TableCell component="th" scope="row">
                <Checkbox
                  checked={Boolean(preferences[destination.id])}
                  onChange={() => updatePreferences(destination.id)}
                />
              </TableCell>
              <TableCell>{destination.category}</TableCell>
              <TableCell>{destination.description}</TableCell>
              <TableCell>{destination.name}</TableCell>
            </TableRow>
          )))
        })}
      </TableBody>
    </TableStyled>
  </>
);



const Cookie: FC<Props> = ({
  preferences,
  updateConsent,
  updatePreferences
}) => {

  const consent = useSelector((state: RootState) => state.cookieConsent)
  const branding = useSelector((state: RootState) => state.branding)
  const { vwWidth } = useWindowSize();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const writeKeyHotjar = process.env.REACT_APP_HOTJAR_API_KEY
  const writeKeyGA = process.env.REACT_APP_GA_API_KEY

  const isSG = branding && branding.objectType === companyNames.Schadegarant
  const isDealerDistribution = branding && branding.objectType === processTypes.DealerDistribution
  const isDealerSaas = branding && branding.objectType === processTypes.DealerSaas
  if (consent !== null) {
    return <></>
  }
  if (!writeKeyHotjar && !writeKeyGA) {
    return <></>
  }
  return (
    <>
      <ConsentBanner
        animate={'open'}
        variants={variants}
      >
        <Container flexDirection="column">
          <Text>
            We gebruiken cookies (en andere dergelijke technologieën) om
            data te verzamelen zodat wij uw ervaring op onze site kunnen
            verbeteren. Door gebruik te maken van onze website gaat u
            akkoord met het verzamelen van data zoals beschreven in onze{' '}
            <Link
              href="https://www.openclaims.com/wp-content/uploads/2018/05/Privacy-Statement-Openclaims-1.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              {' '}
              Website Data Collection Policy
            </Link>
            .
          </Text>
          {isSG && <Text> Wilt u weten waar Schadegarant uw data voor nodig heeft, klik dan{' '}
            <Link
              href="https://www.schadegarant.nl/privacystatement/"
              rel="noopener noreferrer"
              target="_blank"
            >
              {' '}
              hier
            </Link>.</Text>}
          {isDealerDistribution && <Text> Wilt u weten waar Bovemij uw data voor nodig heeft, klik dan{' '}
            <Link
              href="https://www.bovemij.nl/privacyverklaring"
              rel="noopener noreferrer"
              target="_blank"
            >
              {' '}
              hier
            </Link>.</Text>}
          {isDealerSaas && <Text><br /><b>Wat doen wij met uw persoonsgegevens?</b>
            Als wij een schademelding krijgen leggen wij gegevens vast.
            Wij registreren en verwerken de persoonsgegevens van partijen die betrokken zijn bij de schade. Wij zijn er verantwoordelijk voor dat dat zorgvuldig gebeurt.
            De persoonsgegevens leggen wij ook vast bij de Stichting CIS (Centraal Informatie Systeem).
            Hoe wij omgaan met persoonsgegevens kunt u lezen in onze privacyverklaring op onze{' '}
            <Link
              href="https://www.dealerloketbv.nl"
              rel="noopener noreferrer"
              target="_blank"
            >
              {' '}
              <b>website</b>
            </Link>.</Text>}
          <Container
            justifyContent="space-between"
            // alignItems="center"
            paddingTop={Scaling.scaleUpTo4K(20, vwWidth) + 'px'}
          >
            <BannerButton type="button" onClick={() => updateConsent({ ga: true, hotjar: true })}>
              Ok
            </BannerButton>
            <Link onClick={() => setDialogOpen(true)}>
              Click hier om uw voorkeuren te wijzigen
            </Link>
          </Container>
        </Container>
      </ConsentBanner>
      <ConfirmationDialog
        open={dialogOpen}
        onYesClick={() => {
          updateConsent(preferences)
          // setDialogOpen(false)
        }}
        onNoClick={() => setDialogOpen(false)}
        title="Voorkeuren voor dataverzameling van deze site"
        yesLabel="Opslaan"
        noLabel="Annuleren"
        content={
          <ConsentDialogContent
            vwWidth={vwWidth}
            preferences={preferences}
            updateConsent={updateConsent}
            updatePreferences={updatePreferences}
          />
        }
      />
    </>
  );
};

export default Cookie;
