import {
  Branding,
  CompanyContent,
  ContentOptions,
  RedirectUrls,
} from '@domain/interfaces';
import parse from 'html-react-parser';

const customContent: (
  contentType: ContentOptions,
  branding: Branding,
  redirectUrls: RedirectUrls,
) => CompanyContent = (contentType, branding, redirectUrls) => {
  const landing = {
    infoBlock: {
      title: 'Vind een aangesloten hersteller bij u in de buurt',
      'signature-text': 'Powered by',
      'signature-logo': '/img/rhion-logo.svg',
      'intro-text-paragraphs': [
        {
          text:
            'Heeft u schade aan uw auto en wilt u deze zo snel mogelijk herstellen? We helpen u graag verder. Op deze pagina selecteert u eenvoudig een hersteloptie.',
        },
        {
          text: `U heeft een verzekeringspolis van ${branding.brandName}. U kunt daarom gebruik maken van het rhion herstelnetwerk voor het herstel van uw schade. U profiteert van extra voordelen waaronder gratis vervangend vervoer en een gratis haal- en brengservice.`,
        },
        {
          text: parse(
            `Maakt u geen gebruik van een aangesloten hersteller? Dan ontvangen wij graag de offerte van uw eigen hersteller. U profiteert niet van de extra voordelen. Klik op <a href=${
              (redirectUrls && redirectUrls.ownBodyshopRedirectURL) || ''
            }><u>offerte aanleveren</u></a> als u kiest voor een eigen hersteller.`,
          ),
        },
        {
          text: `U levert een duidelijke offerte of calculatie van de schade. U moet het schadebedrag zelf voorschieten. Het kan zijn dat u een verhoogd eigen risico van € 500,- heeft, kijk goed naar uw aanvullende dekking en voorwaarden hiervoor.`
        }
      ],
    },
    formBlock: {
      'card-one': {
        button: 'Volgende',
        title: 'Selecteer welke soort schade uw auto heeft en klik op “Volgende”',
        subtitle: null,
        paragraphs: [
          {
            text:
              'Wij werken samen met de beste herstelbedrijven van Nederland. U profiteert altijd van de volgende voordelen:',
          },
        ],
        list: [
          {
            text: 'Gratis haal- en brengservice of vervangend vervoer.',
            icon: true,
          },
          {
            text:
              'U hoeft niets voor te schieten, wij regelen alles met het herstelbedrijf.',
            icon: true,
          },
          {
            text: 'Zeven jaar garantie op het uitgevoerde herstel.',
            icon: true,
          },
          {
            text:
              'U betaalt geen, of een verlaagd, eigen risico (dit hangt af van uw polisvoorwaarden).',
            icon: true,
          },
        ],
        'closing-paragraphs': [
          {
            text:
              'Klik op de onderstaande knop om de beschrijving en foto’s van uw schade toe te voegen. Het meest geschikte herstelbedrijf wordt aan u voorgesteld en neemt vervolgens binnen één werkdag contact met u op om een afspraak in te plannen.',
          },
        ],
      },
      'card-two': {
        button: 'Dossier aanmaken',
        title: 'Vul hier uw gegevens in',
        subtitle: null,
        paragraphs: [
          {
            text:
              'Maakt u geen gebruik van een aangesloten hersteller? Dan ontvangen wij graag foto’s van uw schade en de offerte van uw eigen hersteller zodat wij deze kunnen beoordelen. U kunt de informatie via onderstaande knop aanleveren.',
          },
          {
            text:
              'Wij nemen uw schade  zo snel mogelijk in behandeling. Houd rekening met het volgende:',
          },
        ],
        list: [
          {
            text:
              'U levert een duidelijke offerte of calculatie van de schade.',
          },
          { text: 'U moet het schadebedrag zelf voorschieten.' },
          {
            text:
              'Het kan zijn dat u een verhoogd eigen risico van € 500,- heeft, kijk goed naar uw aanvullende dekking en voorwaarden hiervoor.',
          },
        ],
        'closing-paragraphs': null,
      },
    },
  };

  const SG = {
    infoBlock: {
      title: `Welkom in eXperience!`,
      'intro-text-paragraphs': [
        {
          text:
            'In een paar stappen meldt u hier uw schade en krijgt u een persoonlijk eXperience dossier. Via de track-and-trace functie volgt u de status van uw schade.',
        },
        {
          text:
            'Heeft u al een afspraak bij een bedrijf uit ons schadeherstelnetwerk gemaakt? Dan is melden via eXperience niet meer nodig. Het herstelbedrijf verzorgt uw eXperience dossier.',
        },
        {
          text: parse(
            'Foto’s helpen het herstelbedrijf bij de beoordeling van uw schade. Met een tablet of smartphone maakt en selecteert u ze hier direct (maximaal 10 stuks). <b>Logt u in via uw</b> personal computer (pc) in <b>eXperience</b>? Dan dient u de foto’s eerst naar uw pc te uploaden <b>voordat</b> u zie hier kunt selecteren.'
            ),
        },
        {
          text:
            'Om uw eXperience dossier te bekijken, logt u in met uw e-mailadres en het kenteken van uw auto.',
        },
        {
          text: parse(
            `Meer informatie over eXperience vindt u op de <a href='https://www.schadegarant.nl/experience' target='_blank'><u>eXperience pagina</u></a>. In ons <a href='https://www.schadegarant.nl/privacystatement/' target='_blank'><u>privacystatement</u></a>
            kunt u meer lezen over hoe wij veilig omgaan met vertrouwelijke gegevens.`,
          ),
        },
      ],
    },
    formBlock: {
      'card-one': {
        button: 'Volgende',
        title:
          'Selecteer welke soort schade uw auto heeft en klik op “Volgende”',
        subtitle: null,
        paragraphs: null,
        list: null,
        'closing-paragraphs': null,
      },
      'card-two': {
        button: 'eXperience dossier aanmaken',
        title: 'Vul hier uw gegevens in',
        subtitle: null,
        paragraphs: null,
        list: null,
        'closing-paragraphs': null,
      },
    },
  };

  const DI = {
    infoBlock: {
      'intro-text-paragraphs': [
        {
          text: `Vul hiernaast uw kenteken en e-mailadres in. Hiermee maakt u uw digitale
      schadedossier aan. Als bevestiging ontvangt u een e-mail met instructies
      om uw schademelding af te maken. In slechts 3 simpele stappen is uw
      schade dossier compleet en kunnen wij aan de slag met uw schade.`,
        },
        {
          text: 'Wat hebben we van u nodig voor het digitale schade dossier?',
        },
      ],
      'show-intro-text-list': true,
      'intro-text-list': [
        { text: `Adres & contactgegevens` },
        { text: `Informatie over de schade` },
        { text: `Foto’s van de schade, groene kaart en kentekenbewijs.` },
      ],
    },
    formBlock: {
      'card-one': {
        button: 'Schadedossier aanmaken',
        title: null,
        subtitle: null,
        paragraphs: null,
        list: null,
        'closing-paragraphs': null,
      },
      'card-two': {
        button: 'Schadedossier aanvullen',
        title: 'Dank voor uw informatie!',
        subtitle: 'We hebben u een bevestigingsmail gestuurd.',
        paragraphs: [
          {
            text:
              'Rond direct uw schadedossier af met onderstaande knop of doe dit op een later moment via de link in de bevestigingsmail.',
          },
        ],
        list: null,
        'closing-paragraphs': null,
      },
    },
  };

  const dealerSaas = {
    infoBlock: {
      title: `Welkom`,
      'intro-text-paragraphs': [
        {
          text:
            'In een paar stappen meldt u hier uw schade en krijgt u een persoonlijk dossier. Via de track-and-trace functie volgt u de status van uw schade.',
        },
        {
          text:
            'Foto’s helpen het herstelbedrijf bij de beoordeling van uw schade. Met een tablet of smartphone maakt en selecteert u ze hier direct. Via een desktop dient u ze eerst zelf naar uw pc te uploaden.',
        },
      ],
    },
    formBlock: {
      'card-one': {
        button: 'Volgende',
        title:
          'Selecteer welke soort schade uw auto heeft en klik op “Volgende”',
        subtitle: null,
        paragraphs: null,
        list: null,
        'closing-paragraphs': null,
      },
      'card-two': {
        button: 'dossier aanmaken',
        title: 'Vul hier uw gegevens in',
        subtitle: null,
        paragraphs: null,
        list: null,
        'closing-paragraphs': null,
      },
    },
  };

  const intakePlus = {
    infoBlock: {
      title: `Welkom`,
      'intro-text-paragraphs': [
        {
          text:
            'In een paar stappen meldt u hier uw schade en krijgt u een persoonlijk dossier.',
        },
        {
          text:
            'Foto’s helpen het herstelbedrijf bij de beoordeling van uw schade. Met een tablet of smartphone maakt en selecteert u ze hier direct. Via een desktop dient u ze eerst zelf naar uw pc te uploaden.',
        },
      ],
    },
    formBlock: {
      'card-one': {
        button: 'dossier aanmaken',
        title: 'Vul hier uw gegevens in',
        subtitle: null,
        paragraphs: null,
        list: null,
        'closing-paragraphs': null,
      },
       'card-two': {
        button: 'Ja',
        buttonNo: 'Nee',
        title: 'Kloppen deze voertuiggegevens?',
        subtitle: null,
        paragraphs: null,
        list: null,
        'closing-paragraphs': null,
      },
    },
  };

    const intake = {
    infoBlock: {
      title: `Welkom`,
      'intro-text-paragraphs': [
        {
          text:
            'In een paar stappen meld je hier jouw schade en krijg je een persoonlijk dossier. Via de track-and-trace functie volg je de status van jouw schade.',
        },
        {
          text:
            'Foto’s helpen het herstelbedrijf bij de beoordeling van jouw schade. Met een tablet of smartphone maak en selecteer je ze hier direct. Via een desktop dien je ze eerst zelf naar jouw pc te uploaden.',
        },
      ],
    },
    formBlock: {
      'card-one': {
        button: 'Volgende',
        title:
          'Selecteer welke soort schade jouw auto heeft en klik op “Volgende”',
        subtitle: null,
        paragraphs: null,
        list: null,
        'closing-paragraphs': null,
      },
      'card-two': {
        button: 'dossier aanmaken',
        title: 'Vul hier jouw gegevens in',
        subtitle: null,
        paragraphs: null,
        list: null,
        'closing-paragraphs': null,
      },
    },
  };

  const intakeSpecial = {
    infoBlock: {
      title: `Welkom`,
      'intro-text-paragraphs': [
        {
          text:
            'In een paar stappen meld je hier jouw schade en krijg je een persoonlijk dossier. Via de track-and-trace functie volg je de status van jouw schade.',
        },
        {
          text:
            'Foto’s helpen het herstelbedrijf bij de beoordeling van jouw schade. Met een tablet of smartphone maak en selecteer je ze hier direct. Via een desktop dien je ze eerst zelf naar jouw pc te uploaden.',
        },
      ],
    },
    formBlock: {
      'card-one': {
        button: 'Volgende',
        title:
          'Is de schade intern of extern?',
        subtitle: null,
        paragraphs: null,
        list: null,
        'closing-paragraphs': null,
      },
      'card-two': {
        button: 'dossier aanmaken',
        title: 'Selecteer welke soort schade jouw auto heeft en klik op “Volgende”',
        subtitle: null,
        paragraphs: null,
        list: null,
        'closing-paragraphs': null,
      },
    },
  };

      const dealerDistribution = {
    infoBlock: {
      title: `Meld je schade online`,
      'intro-text-paragraphs': [
        {
          text:
            'In een paar stappen meld je hier jouw schade en krijg je toegang tot je persoonlijk dossier.',
        },
        {
          text:
            'Zorg hierbij voor goede foto’s van de schade. Dit helpt het herstelbedrijf om jouw schade te beoordelen. Maak de foto’s met een tablet of een smartphone, upload ze via je desktop en selecteer ze via de portal.',
        },
      ],
    },
    formBlock: {
      'card-one': {
        button: 'Volgende',
        title:
          'Selecteer welke soort schade jouw auto heeft en klik op “Volgende”',
        subtitle: null,
        paragraphs: null,
        list: null,
        'closing-paragraphs': null,
      },
      'card-two': {
        button: 'dossier aanmaken',
        title: 'Vul hier jouw gegevens in',
        subtitle: null,
        paragraphs: null,
        list: null,
        'closing-paragraphs': null,
      },
    },
  };
  const notCustom = { infoBlock: {}, formBlock: {} };

  const contentOptions = { dealerDistribution, landing, SG, DI, dealerSaas, notCustom, intake, intakeSpecial, intakePlus };
  return contentOptions[contentType];
};

export default customContent;
