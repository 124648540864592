import { CHECK_PROCESS } from '../shared/action-names';
import { ProcessCheckActionTypes, ProcessCheckState } from './types';

const initialState: ProcessCheckState = null;

export default function (
  state = initialState,
  action: ProcessCheckActionTypes,
): ProcessCheckState {
  switch (action.type) {
    case CHECK_PROCESS:
      return action.payload;
    default:
      return state;
  }
}
