import {
  REDIRECT_URL_SET,
  URL_CREATION_DELETE,
  URL_CREATION_SET,
  URL_SG_SET,
  URL_VMH_EMBED_SET,
} from '../shared/action-names';
import { redirectUrlsActionTypes, redirectUrlsState } from './types';

const initialState: redirectUrlsState = {
  cascoRedirectURL: null,
  ownBodyshopRedirectURL: null,
  onMyOwnRedirectURL: null,
  redirectLinkSG: null,
  urlCreation: null,
  vmhEmbedRedirect: null,
};

export default function (
  state = initialState,
  action: redirectUrlsActionTypes,
): redirectUrlsState {
  switch (action.type) {
    case REDIRECT_URL_SET:
      return { ...state, ...action.payload };
    case URL_SG_SET:
      return { ...state, ...action.payload };
    case URL_CREATION_SET:
      return { ...state, ...action.payload };
    case URL_CREATION_DELETE:
      return { ...state, urlCreation: null };
    case URL_VMH_EMBED_SET:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
