import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { styled, useTheme } from '@mui/material';

import { Header, Main, Paragraph, Section } from '@domain/components';
import { useWindowSize } from '@domain/hooks';
import { Colors, Gradients, Scaling } from '@domain/theming';
import { chooseLogoSize } from '../../shared/helpers';

import { RootState } from '@domain/root-reducer';

const StyledMain = styled(Main as any, {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
})`
  height: 100vh;
  position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
    width: 100%;
    background: ${Colors.GREY_LIGHTER};
    padding: ${props => Scaling.scaleUpTo4K(60, props.$vwWidth)}px ${props => Scaling.scaleUpTo4K(80, props.$vwWidth)}px;
    display: flex;
    a {
      color: inherit;
    }
    @media screen and (max-width: 900px) {
      padding: 40px;
    }
    @media screen and (max-width: 420px) {
      padding: 40px 20px;
    }
  `

const Link = styled('a')`
  color: ${Colors.BLACK};
  text-decoration: underline;
  cursor: pointer;
`;
const NoSGPage: FC = () => {

  const { vwWidth } = useWindowSize();

  const themeContext = useTheme();
  const branding = useSelector((state: RootState) => state.branding)
  const redirectUrls = useSelector((state: RootState) => state.redirectUrls)


  if (branding && branding.objectType === 'landing') {
    return (
      <StyledMain $vwWidth={vwWidth}>
        <Header shadow={true} background={Gradients().HEADER} imgSrc={(!!branding && branding.logoURL) || ''} height={chooseLogoSize(themeContext?.logoSize)} />
        <Section transparent={true}>
          <Paragraph removeMargin={true} paddingBottom={`${Scaling.scaleUpTo4K(20, vwWidth)}px`}>
            Het is niet mogelijk om uw autoschade via eXperience te melden omdat uw verzekeraar deze service niet biedt. Neem contact op met uw verzekeraar en vraag op welke manier u uw auto kunt laten herstellen.
          </Paragraph>
          <Paragraph removeMargin={true} paddingBottom={`${Scaling.scaleUpTo4K(20, vwWidth)}px`}>
            Mocht u al in het bezit zijn van een offerte van uw schadehersteller, dan kunt u <Link href={(redirectUrls && redirectUrls.ownBodyshopRedirectURL) || ''} rel="noopener noreferrer" target='_blank'>hier</Link> uw schademelden.
          </Paragraph>
        </Section>
      </StyledMain >
    )
  }
  return (
    <StyledMain $vwWidth={vwWidth}>
      <Header shadow={true} background={Gradients().HEADER} imgSrc={(!!branding && branding.logoURL) || ''} height={chooseLogoSize(themeContext?.logoSize)} />
      <Section transparent={true}>
        <Paragraph removeMargin={true} paddingBottom={`${Scaling.scaleUpTo4K(20, vwWidth)}px`}>
          Het is niet mogelijk om uw autoschade via eXperience te melden omdat uw verzekeraar deze service niet biedt. Neem contact op met uw verzekeraar en vraag op welke manier u uw auto kunt laten herstellen.
        </Paragraph>
        <Paragraph removeMargin={true} paddingBottom={`${Scaling.scaleUpTo4K(20, vwWidth)}px`}>
          eXperience is een service van verzekeraars die deelnemen aan Schadegarant en Glasgarant. Alle informatie over eXperience vindt u op de eXperience pagina: <Link href='https://www.schadegarant.nl/experience' rel="noopener noreferrer" target='_blank'>www.schadegarant.nl/experience</Link>.
        </Paragraph>
        <Paragraph removeMargin={true} paddingBottom={`${Scaling.scaleUpTo4K(20, vwWidth)}px`}>
          De (persoons)gegevens die u heeft ingevoerd worden automatisch verwijderd zodra u deze pagina verlaat.
        </Paragraph>
      </Section>
    </StyledMain >
  )
}

export default NoSGPage;