import buildURL from '../buildURL';
import { FormSG } from '@domain/interfaces';
import { browserStorage } from '../browserStorage';

const generateSGRedirectURL = (formSG: FormSG, pid: string, fid: string, direct: string) => {
  const { merk, licensePlate, ...formSGMinusMerk } = formSG;
  const consentCode = JSON.stringify(browserStorage.cookieConsentStorage.get())
  const currenturl = window.location.href.replace(window.location.search, '')
  const isSpCaseAndPid = { ...formSGMinusMerk, CarMake: merk, SPCase: true, PID: pid, Redirect: direct, FID: fid, ConsentCode: consentCode, URL: currenturl }
  const SGredirectUrl = buildURL(
    `${process.env.REACT_APP_CP_DI_URL}/nieuw?`,
    Object.entries(isSpCaseAndPid),
  );

  const url = SGredirectUrl;
  return url;
};

export default generateSGRedirectURL;
