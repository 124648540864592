import {
  ATTRIBUTE_FROM_URL_SET,
  CCS_INFORMATION_UUID_SET,
  DAMAGE_TYPE_UPDATE,
  FORM_DI_UPDATE,
  FORM_SG_UPDATE,
  FORM_VIP_UPDATE,
  GET_EMBED_VIP_CASE,
  INSURANCE_INFORMATION_ID_SET,
  LICENSEPLATE_UUID_SET,
  INTERNAL_EXTERNAL_SET,
  VEHICLE_IDENTIFICATION_ID_SET,
  VEHICLE_INFO_SET
} from '../shared/action-names';
import { caseDetailActionTypes, caseDetailsState } from './types';

const initialState: caseDetailsState = {
  debiteurNummer: '',
  postcode: '',
  merk: '',
  relatieNummer: '',
  radius: '',
  damageDate: '',
  damageType: '',
  internalExternal: '',
  insuranceInformationID: '',
  licensePlate: '',
  bodyshopNetwork: '',
  customerGender: '',
  customerFirstName: '',
  customerLastName: '',
  customerZipCode: '',
  customerHouseNumber: '',
  customerHouseNumberAddition: '',
  customerStreet: '',
  customerCity: '',
  customerEmail: '',
  customerMobileNumber: '',
  errorCode: null,
  carMake: '',
  rid: '',
  vehicleIdentificationID: '',
  uuid: '',
};

export default function (
  state = initialState,
  action: caseDetailActionTypes,
): caseDetailsState {
  switch (action.type) {
    case DAMAGE_TYPE_UPDATE:
      return { ...state, damageType: action.payload };
    case FORM_SG_UPDATE:
      return { ...state, ...action.payload };
    case FORM_DI_UPDATE:
      return { ...state, ...action.payload };
    case ATTRIBUTE_FROM_URL_SET:
      return { ...state, ...action.payload };
    case VEHICLE_IDENTIFICATION_ID_SET:
      return { ...state, vehicleIdentificationID: action.payload };
    case VEHICLE_INFO_SET:
      return {...state, ...action.payload}
      case INTERNAL_EXTERNAL_SET:
      return { ...state, internalExternal: action.payload };
    case CCS_INFORMATION_UUID_SET:
      return { ...state, cCSInformationUUID: action.payload };
    case LICENSEPLATE_UUID_SET:
      return { ...state, licensePlateUUID: action.payload };
    case INSURANCE_INFORMATION_ID_SET:
      return { ...state, insuranceInformationID: action.payload}  
    case FORM_VIP_UPDATE:
      return { ...state, ...action.payload };
    case GET_EMBED_VIP_CASE:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
}
