import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';

import { Theme, styled } from '@mui/material';
import { Colors, Scaling, Shadows } from '@domain/theming';
import { useWindowSize } from '@domain/hooks';
import classNames from 'classnames';

interface StyledAnchorButtonProps extends React.ComponentPropsWithoutRef<'a'> {
  className?: string,
  $dynamicFontSize?: boolean,
  $iconButton?: boolean,
  target?: '_blank' | '',
  $variant?: 'primary' | 'outline',
  $vwWidth: number
}

interface Props extends React.ComponentPropsWithoutRef<'a'> {
  className?: string,
  disabled?: boolean,
  dynamicFontSize?: boolean,
  href?: string,
  iconButton?: boolean,
  onClick?: any,
  target?: '_blank' | '',
  variant?: 'primary' | 'outline',
}

const StyledAnchorButton = styled('a', {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
}) <StyledAnchorButtonProps>`
  display: flex;
  max-width: ${props => Scaling.scaleUpTo4K(580, props.$vwWidth) + 'px'};
  width: ${props => Scaling.scaleUpTo4K(240, props.$vwWidth)}px;
  justify-content: space-between;
  border: 0;
  color: ${Colors.WHITE};
  box-shadow: ${props => props.$iconButton && Shadows().Shadow6};
  background: ${Colors.BLACK};

  flex-direction: row;
  align-items: center;
  height: ${props => Scaling.scaleUpTo4K(64, props.$vwWidth) + 'px'};
  padding: 12px 24px;
  font-size: ${props => props.$dynamicFontSize ? (isMobile
    ? Scaling.textFontSizeMobile(14, props.$vwWidth)
    : Scaling.textFontSize(14, props.$vwWidth)) + 'px' : '12px'}
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  border-radius: ${props => Scaling.scaleUpTo4K(16, props.$vwWidth) + 'px'};
  cursor: pointer;
  transition: 160ms cubic-bezier(0.4, 0, 0.2, 1);
  text-decoration: none;
  text-align: right;
  .button__icon {
    background: ${Colors.WHITE};
    color: ${Colors.BLACK};
    border-radius: ${props => Scaling.scaleUpTo4K(16, props.$vwWidth)}px;
    margin-left: auto;
  }

  &.icon-button {
    padding: 0 0 0 15px;
    justify-content: flex-end;
    background: transparent;
    text-shadow: 4px 3px 3px rgba(0, 0, 0, 0.28);
    width: auto;
    .button__icon {
      height: ${props => Scaling.scaleUpTo4K(64, props.$vwWidth)}px;;
      width: ${props => Scaling.scaleUpTo4K(64, props.$vwWidth)}px;
      border-radius: 50%;
      margin-left: 30px;
      box-shadow: ${Shadows().Shadow6};
    }
  }

  &.disabled {
    color: ${Colors.GREY_LIGHT};
    pointer-events: none;
    cursor: default;
    box-shadow: none;
    background: ${Colors.WHITE};
    .button__icon {
      background: ${Colors.GREY_LIGHT};
      color: ${Colors.WHITE};
      box-shadow: none;
    }

    &.icon-button {
      background: transparent;
      opacity: 0.66;
      .button__icon {
        background: ${Colors.WHITE};
        color: ${Colors.GREY_DARK};
      }
    }
  }

  @media (hover: hover) {
    &:hover {
      transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
      box-shadow: ${props => props.$iconButton && Shadows().Shadow3};
      &.icon-button {
        color: ${Colors.GREY_LIGHT};
        .button__icon {
          background: ${Colors.GREY_LIGHT}
        }
      }
    }
  }
  &:focus {
    transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: ${props => props.$iconButton && Shadows().Shadow3};
    &.icon-button {
      color: ${Colors.GREY_LIGHT};
      .button__icon {
        background: ${Colors.GREY_LIGHT}
      }
    }
  }
  &:active {
    transform: scale(0.98);
    transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: ${props => props.$iconButton && Shadows().Shadow1};
    &.icon-button {
      color: ${Colors.GREY_LIGHT};
      .button__icon {
        background: ${Colors.GREY_LIGHT}
      }
    }
  }
`;
const NextButton: FC<Props> = ({
  className,
  children,
  disabled,
  dynamicFontSize,
  href,
  iconButton,
  onClick,
  target,
  variant,
}) => {

  const classNamesCombined = classNames(className, iconButton && 'icon-button', disabled && 'disabled')

  const { vwWidth } = useWindowSize();
  return (
    <StyledAnchorButton
      $variant={variant}
      target={target}
      className={`button ${classNamesCombined}`}
      $dynamicFontSize={dynamicFontSize}
      $vwWidth={vwWidth}
      href={href}
      onClick={onClick}
    >
      {React.Children.toArray(children)}
    </StyledAnchorButton>
  );
};

export default NextButton;
