import React, { ReactNode, Children, forwardRef, ReactElement } from 'react'
import { styled } from '@mui/material';
import classNames from 'classnames';
import { Scaling } from '@domain/theming';

interface StyledProps {
  $background?: string;
  $boxSizing?: string;
  $display?: string;
  $position?: string;
  $flexGrow?: string;
  $flexShrink?: string;
  $flexBasis?: string;
  $flexDirection?: 'column' | 'row';
  $flexWrap?:
  | 'nowrap'
  | 'wrap'
  | 'wrap-reverse'
  | 'initial'
  | 'inherit'
  $justifyContent?:
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'initial'
  | 'inherit';
  $alignItems?:
  | 'stretch'
  | 'center'
  | 'flex-start'
  | 'flex-end'
  | 'baseline'
  | 'initial'
  | 'inherit';
  $width?: number | string;
  $height?: number | string;
  $maxWidth?: number | string;
  $maxHeight?: number | string;
  $minWidth?: number | string;
  $margin?: string;
  $marginTop?: string;
  $marginRight?: string;
  $marginBottom?: string;
  $marginLeft?: string;
  $padding?: string;
  $paddingTop?: string;
  $paddingRight?: string;
  $paddingBottom?: string;
  $paddingLeft?: string;
}

interface Props {
  background?: string;
  boxSizing?: string;
  display?: string;
  position?: string;
  flexGrow?: string;
  flexShrink?: string;
  flexBasis?: string;
  flexDirection?: 'column' | 'row';
  flexWrap?:
  | 'nowrap'
  | 'wrap'
  | 'wrap-reverse'
  | 'initial'
  | 'inherit'
  justifyContent?:
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'initial'
  | 'inherit';
  alignItems?:
  | 'stretch'
  | 'center'
  | 'flex-start'
  | 'flex-end'
  | 'baseline'
  | 'initial'
  | 'inherit';
  width?: number | string;
  height?: number | string;
  maxWidth?: number | string;
  maxHeight?: number | string;
  minWidth?: number | string;
  margin?: string;
  marginTop?: string;
  marginRight?: string;
  marginBottom?: string;
  marginLeft?: string;
  padding?: string;
  paddingTop?: string;
  paddingRight?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  className?: string;
  children: ReactElement | ReactNode
}


const StyledContainer = styled('div', {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
}) <StyledProps>`
  background: ${props => props.$background};
  box-sizing: ${props => props.$boxSizing};
  display: ${props => props.$display || 'flex'};
  position: ${props => props.$position};
  flex-direction: ${props => props.$flexDirection || 'row'};
  flex: ${props =>
    `${props.$flexGrow || 1} ${props.$flexShrink || 0} ${props.$flexBasis ||
    'auto'}`};
  flex-wrap: ${props => props.$flexWrap};
  justify-content: ${props => props.$justifyContent};
  align-items: ${props => props.$alignItems};
  min-width: ${props => !!props.$minWidth && typeof props.$minWidth === 'number' ? Scaling.scaleUpTo4K(props.$minWidth) : props.$minWidth || 0};
  min-height: 0;
  width: ${props => !!props.$width && typeof props.$width === 'number' ? Scaling.scaleUpTo4K(props.$width) : props.$width};
  height: ${props => !!props.$height && typeof props.$width === 'number' ? Scaling.scaleUpTo4K(props.$height) : props.$height};
  max-width: ${props => !!props.$maxWidth && typeof props.$width === 'number' ? Scaling.scaleUpTo4K(props.$maxWidth) : props.$maxWidth};
  max-height: ${props => !!props.$maxHeight && typeof props.$width === 'number' ? Scaling.scaleUpTo4K(props.$maxHeight) : props.$maxHeight};
  margin: ${props => props.$margin};
  margin-top: ${props => props.$marginTop};
  margin-right: ${props => props.$marginRight};
  margin-bottom: ${props => props.$marginBottom};
  margin-left: ${props => props.$marginLeft};
  padding: ${props => props.$padding};
  padding-top: ${props => props.$paddingTop};
  padding-right: ${props => props.$paddingRight};
  padding-bottom: ${props => props.$paddingBottom};
  padding-left: ${props => props.$paddingLeft};
`;


const Container = forwardRef<HTMLDivElement, Props>(({
  background,
  boxSizing,
  className,
  display,
  position,
  flexGrow,
  flexShrink,
  flexBasis,
  flexDirection,
  flexWrap,
  justifyContent,
  alignItems,
  width,
  height,
  maxWidth,
  maxHeight,
  minWidth,
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
  children
}, ref) => {

  const usedClass = classNames('container', className)
  return (
    <StyledContainer
      ref={ref}
      className={usedClass}
      $background={background}
      $boxSizing={boxSizing}
      $display={display}
      $position={position}
      $flexGrow={flexGrow}
      $flexShrink={flexShrink}
      $flexBasis={flexBasis}
      $flexDirection={flexDirection}
      $flexWrap={flexWrap}
      $justifyContent={justifyContent}
      $alignItems={alignItems}
      $width={width}
      $height={height}
      $maxWidth={maxWidth}
      $maxHeight={maxHeight}
      $minWidth={minWidth}
      $margin={margin}
      $marginTop={marginTop}
      $marginRight={marginRight}
      $marginBottom={marginBottom}
      $marginLeft={marginLeft}
      $padding={padding}
      $paddingTop={paddingTop}
      $paddingRight={paddingRight}
      $paddingBottom={paddingBottom}
      $paddingLeft={paddingLeft}
    >
      {Children.toArray(children)}
    </StyledContainer>
  );
});
export default Container;
