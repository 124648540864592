import React, { FC, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  AnchorButton,
  Card,
  Container,
  Footer,
  Header,
  Heading,
  Logo,
  Main,
  Paragraph,
  Section,
} from '@domain/components';
import { cpURL, routes, steeringNetworkURLs } from '@domain/constants';
import { browserStorage, buildURL } from '@domain/helpers';
import { Company, SteeringNetwork } from '@domain/interfaces';
import { RootState } from '@domain/root-reducer';
import { confirmCase, page } from '@domain/services';
import { Navigate } from 'react-router-dom';


const ExtraInformation: FC = () => {
  const cookieConsent = useSelector((state: RootState) => state.cookieConsent)
  // tracking
  useEffect(() => {
    page('Steering', cookieConsent);
  }, [cookieConsent]);

  const intermediaries = useSelector((state: RootState) => state.intermediaries)
  const caseDetails = useSelector((state: RootState) => state.caseDetails)


  const company: Company = useMemo(() => {
    if (!caseDetails.companyToken || !intermediaries) {
      return {
        brandName: '',
        companyToken: '',
      };
    }
    const { companyToken } = caseDetails;
    return intermediaries.find(c => c.companyToken === companyToken) as Company;
  }, [caseDetails, intermediaries]);

  const consentCode = JSON.stringify(browserStorage.cookieConsentStorage.get())
  const { uuid: excludedUUID, ...redirectCaseDetails } = caseDetails
  const cpLink = buildURL(
    `${cpURL}/nieuw?`,
    Object.entries({
      ...redirectCaseDetails,
      SPCase: `${true}`,
      ConsentCode: consentCode
    }),
  );

  if (caseDetails.vipAvailable === undefined) {
    return <Navigate to={routes.landing} />;
  }

  const handleConfirmCase = async () => {
    const caseUpdated = await confirmCase(caseDetails.uuid as string);
    if (caseUpdated.errorCode === 0) {
      window.open(cpLink, '_blank');
    }
  };
  const { brandName } = company;
  const riskCarrier = caseDetails.riskCarrier;
  const companyName = !!riskCarrier ? riskCarrier : !!brandName ? brandName : 'jouw verzekeraar';
  const steeringNetwork = caseDetails.bodyshopNetwork;
  const steeringNetworkKnown = !!steeringNetwork && steeringNetwork !== 'others';
  const vipAvailable = caseDetails.vipAvailable;
  return (
    <>
      <Header />
      <Main className="no-vip">
        <Section alignItems="flex-start">
          <Heading level={1} marginBottom="80px">
            Zelf een herstelbedrijf kiezen
          </Heading>
          <Card
            flexGrow="1"
            flexShrink="1"
            flexBasis="auto"
            margin="0 auto"
            width="100%"
            maxWidth="720px"
            shadow={4}
          >
            {!steeringNetwork ? (
              <>
                <Heading level={3} marginBottom="40px">
                  Bedankt voor de melding
                </Heading>
                <Container marginBottom="5px">
                  <Paragraph level={4}>
                    Jammer genoeg kunnen wij op dit moment op basis van je gegevens nog niet automatisch een herstelbedrijf aanbieden. Neem contact op met {companyName}, om de voor jouw beschikbare opties te bespreken.
                  </Paragraph>
                </Container>
              </>
            ) : vipAvailable ? (
              <>
                <Heading level={3} marginBottom="40px">
                  Herstel je auto bij een {riskCarrier} hersteller
                </Heading>
                <Container marginBottom="60px">
                  <Paragraph level={4}>
                    Volgens je polis kan je jouw schade laten repareren door een hersteller uit het {steeringNetwork} netwerk van {riskCarrier}. Klik op de onderstaande link om de dichtstbijzijnde vestiging te zoeken. Je kan hier een herstelbedrijf uitkiezen en zelf contact opnemen om een afspraak te maken. De factuur van je reparatie zal door het herstelbedrijf naar je verzekeraar worden gestuurd. Vergeet niet je groene kaart mee te nemen als je naar het herstelbedrijf gaat.
                  </Paragraph>
                </Container>
              </>
            ) : (
              <>
                <Heading level={3} marginBottom="40px">
                  Bedankt voor de melding
                </Heading>
                <Container marginBottom="60px">
                  <Paragraph level={4}>
                    Helaas bieden wij op dit moment nog geen hersteller binnen je postcode gebied. Je kunt daarom nog geen gebruik maken van de VIP schadeservice.
                    <br />
                    <br />
                    Neem contact op met jouw verzekeringsadviseur voor het afhandelen van deze schade.
                  </Paragraph>
                </Container>
              </>
            )}
            {steeringNetworkKnown && (
              <AnchorButton
                href={!!steeringNetwork ? steeringNetworkURLs[steeringNetwork as SteeringNetwork] : ''}
                variant="outline"
                target="_blank"
              >
                {steeringNetwork} schadebedrijven
              </AnchorButton>
            )}
          </Card>
        </Section>
        {vipAvailable && (
          <Section dark={true}>
            <Card
              flexGrow="1"
              flexShrink="1"
              flexBasis="auto"
              margin="0 auto"
              width="100%"
              maxWidth="720px"
              shadow={4}
            >
              <Container marginBottom="40px">
                <Logo />
              </Container>
              <Container marginBottom="60px">
                <Paragraph level={4}>
                  Wil je toch liever gebruik maken van de VIP schadeservice,
                  klik dan op onderstaande button.
                </Paragraph>
              </Container>
              <AnchorButton onClick={handleConfirmCase}>
                Start VIP Schadeservice
              </AnchorButton>
            </Card>
          </Section>
        )}
        <Section dark={!vipAvailable}>
          <Card
            flexGrow="1"
            flexShrink="1"
            flexBasis="auto"
            margin="0 auto"
            width="100%"
            maxWidth="720px"
            shadow={4}
          >
            {steeringNetworkKnown ?
              <>
                <Heading level={3} marginBottom="40px">
                  Geen {riskCarrier} hersteller?
                </Heading>
                <Container marginBottom="20px">
                  <Paragraph level={4}>
                    Heb je een hersteller op het oog die geen onderdeel is van het {steeringNetwork} netwerk van {riskCarrier}? Houd er dan rekening mee dat je, naast dat je alles zelf moet regelen, kans hebt op een verhoogd eigen risico. Neem contact op met {brandName} voor advies.
                  </Paragraph>
                </Container>
              </> :
              <>
                <Heading level={3} marginBottom="40px">
                  Eigen hersteller
                </Heading>
                <Container marginBottom="20px">
                  <Paragraph level={4}>
                    Houdt er dan rekening mee dat je, naast dat je alles zelf moet regelen, kans hebt op een verhoogd eigen risico. Neem contact op met {brandName} voor advies.
                  </Paragraph>
                </Container>
              </>
            }
          </Card>
        </Section>
      </Main>
      <Footer />
    </>
  );
};

export default ExtraInformation;
