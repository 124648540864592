import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { StyledEngineProvider } from '@mui/material';

import { ThemeProvider, Theme } from '@mui/material';

import './app.scss';
import Flow from './Flow';
import Error from './Flow/Error'

import { Consent, Cookie, Loader, } from '@domain/components';
import { companyNames } from '@domain/constants';
import { generateThemeValues } from '@domain/helpers';
import { RootState } from '@domain/root-reducer';

import { useCookieConsent, useGlobalEffects, useSubdomain } from '@domain/hooks';


declare module '@mui/material/styles' {
  interface Theme {
    type: String,
    isThemed: Boolean,
    isLight: Boolean,
    logo: String,
    fontFamily: String,
    logoSize?: 'XS' | 'S' | 'M' | 'L' | 'XL'
    backgroundImage?: string
  }
}


interface ExtendedTheme extends Theme {
  type: String,
  isThemed: Boolean,
  isLight: Boolean,
  logo: String,
  fontFamily: String,
  logoSize?: 'XS' | 'S' | 'M' | 'L' | 'XL'
  backgroundImage?: string
}

const App: FC = () => {
  const subdomain = useSubdomain();
  const branding = useSelector((state: RootState) => state.branding)
  const processCheck = useSelector((state: RootState) => state.processCheck)
  const httpError = useSelector((state: RootState) => state.httpError)
  const cookieConsentHook = useCookieConsent()
  const [theme, setTheme] = useState<Partial<ExtendedTheme> | null>(null);

  useGlobalEffects()

  useEffect(() => {
    const isCustomThemed = !!branding && branding.objectType === companyNames.Schadegarant
    const isThemed = !!branding && branding.brand !== 'VIP';
    if (processCheck === false) {
      const generatedTheme = generateThemeValues(branding, isThemed, isCustomThemed)
      if (!theme && generatedTheme) {
        setTheme(generatedTheme);
      }
      return
    }
    if (!branding && subdomain) return;
    // should just check if there is a branding or not but we do not get one for VIP yet
    if (theme && ((branding && theme.type === branding.brand) || (!branding && !subdomain))) return;

    if (subdomain === '') {
      const generatedTheme = generateThemeValues(branding, isThemed, isCustomThemed)
      if (!theme && generatedTheme) {
        setTheme(generatedTheme);
      }
      return
    }
    if (subdomain) {
      const generatedTheme = generateThemeValues(branding, isThemed, isCustomThemed)
      if ((!theme && generatedTheme)) {
        setTheme(generatedTheme);
      }
      return
    }
  }, [branding, subdomain, theme, processCheck])

  if (httpError) {
    return (<Error />)
  }
  if (!theme) {
    return <Loader />;
  }

  return (
    <StyledEngineProvider>
      <ThemeProvider theme={theme}>
        <>
          <Flow />
          <Cookie {...cookieConsentHook} />
          <Consent />
        </>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
