import React, {
    ChangeEvent,
    FC,
    FormEvent,
    ReactNode,
    useCallback,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment'
import ReCAPTCHA from 'react-google-recaptcha';

import { validationSchema } from './validationSchema';

import { postCaseDI, urlCreationDelete } from '@domain/action-creators';
import { Container, ErrorMessage, Recaptcha } from '@domain/components';

import { useLocalStorage, useValidation, useWindowSize } from '@domain/hooks';
import { CaseDataCreation, ContentStructureFormBlock, FormDataCreation, InputName } from '@domain/interfaces';
import { RootState } from '@domain/root-reducer';
import FormDI from './FormDI';
import { captchaKey } from '@domain/constants';
import { Scaling } from '@domain/theming';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { useSearchParams } from 'react-router-dom';

interface Props {
    content: ContentStructureFormBlock;
}
const FormWrapperDI: FC<Props> = ({ content }) => {

    interface EventTarget {
        name: InputName;
        value: string;
    }
    const [searchP, setSearchP] = useSearchParams()
    const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>()
    const { vwWidth } = useWindowSize();
    const redirectUrls = useSelector((state: RootState) => state.redirectUrls)
    const pid = useSelector((state: RootState) => state.pid)
    const fid = useSelector((state: RootState) => state.fid)

    const [formDataCreation, setFormDataCreation] = useState<FormDataCreation>({
        licensePlateCreation: !!searchP.get('LicensePlate') ? searchP.get('LicensePlate')
            : JSON.parse(localStorage.getItem('licensePlateCreation') || '""'),
        customerEmailCreation: !!searchP.get('CustomerEmail') ? searchP.get('CustomerEmail')
            : JSON.parse(localStorage.getItem('customerEmailCreation') || '""')
    });

    const [licenseCreationBlurred, setLicenseCreationBlurred] = useState<boolean>(false);
    const [emailCreationBlurred, setEmailCreationBlurred] = useState<boolean>(false);

    const updateFormDataCreation = useCallback(
        (name: InputName, value: string) =>
            setFormDataCreation((d: any) => ({ ...d, [name]: value })),
        [],
    );

    const { customerEmailCreation, licensePlateCreation } = formDataCreation;

    useLocalStorage('licensePlateCreation', licensePlateCreation);
    useLocalStorage('customerEmailCreation', customerEmailCreation);

    const errors = useValidation(validationSchema, formDataCreation)

    const saving = useRef(false);
    const captchaRef = useRef<ReCAPTCHA | null>(null);

    const onChangeCaptcha = (captchaCode: string | null) => {
        submitCreation(captchaCode, formDataCreation);
        const captcha = captchaRef.current;
        if (!!captcha) {
            captcha.reset();
        }
    };

    const submitCreation = async (
        captchaCode: string | null,
        formDataCreation: FormDataCreation,
    ) => {
        if (saving.current) return;

        if (captchaCode === null) {
            return;
        }
        const { licensePlateCreation, customerEmailCreation } = formDataCreation;

        const caseDataCreation: CaseDataCreation = {
            customerEmailCreation: customerEmailCreation || "",
            licensePlateCreation,
            captchaCode,
            siteKey: captchaKey,
        };

        saving.current = true;

        const header = fid
            ? { PID: pid || '', FID: fid } : { PID: pid || '' }

        dispatch(postCaseDI(caseDataCreation, header));
        saving.current = false;
    };

    const onChangeCreation = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            if (event == null) {
                return
            }
            if (event instanceof Date) {
                const dateFormat = 'YYYY-MM-DD';
                const formattedDate = moment(event).format(dateFormat);

                updateFormDataCreation('damageDateCreation', formattedDate)
                return
            }
            const { name, value } = event.target as EventTarget;

            if (name === 'licensePlateCreation') {
                const nonAlphaNumRegExp = /[^0-9A-Z]/gi;
                const newValue = value
                    .toUpperCase()
                    .replace(nonAlphaNumRegExp, '')
                    .slice(0, 6);
                updateFormDataCreation(name, newValue);
                return;
            }
            updateFormDataCreation(name, value);
        },
        [updateFormDataCreation],
    );

    const onBlurLicensePlate = () => setLicenseCreationBlurred(true)
    const onBlurEmail = () => setEmailCreationBlurred(true)

    const [captchaExecuted, setCaptchaExecuted] = useState<boolean>(false);

    const handleResetCreation = () => {
        dispatch(urlCreationDelete())
        if (!captchaExecuted) {
            return;
        }
        const captcha = captchaRef.current
        if (!!captcha) {
            captcha.reset();
            setCaptchaExecuted(false);
        }
    };


    const shouldShowError = (name: InputName, blurred: boolean): boolean => {
        if (!blurred) {
            return false;
        }
        if (errors === null) {
            return false
        }
        return !!errors[name]
    };

    const showError = (name: InputName, blurred: boolean): ReactNode => {
        if (!shouldShowError(name, blurred)) {
            return <></>;
        }
        if (errors === null) {
            return <></>;
        }
        return (
            <Container marginTop={'-' + Scaling.scaleUpTo4K(14, vwWidth) + 'px'} maxHeight={Scaling.scaleUpTo4K(14, vwWidth) + 'px'}>
                <ErrorMessage dynamicFontSize={true}>{errors[name]}</ErrorMessage>
            </Container>
        );
    };

    const verifyAndSubmit = async (event?: FormEvent<HTMLFormElement>) => {
        event && event.preventDefault();
        const captcha = captchaRef.current;
        if (!captcha) {
            return;
        }
        if (errors === null) {
            setCaptchaExecuted(true);
            captcha.execute();
        }
    }

    return (
        <>
            <FormDI
                onChangeCreation={onChangeCreation}
                shouldShowError={shouldShowError}
                showError={showError}
                formDataCreation={formDataCreation}
                onBlurLicensePlate={onBlurLicensePlate}
                onBlurEmail={onBlurEmail}
                redirectUrls={redirectUrls}
                verifyAndSubmit={verifyAndSubmit}
                handleResetCreation={handleResetCreation}
                licenseCreationBlurred={licenseCreationBlurred}
                emailCreationBlurred={emailCreationBlurred}
                errors={errors}
                content={content}
            />
            <Recaptcha
                onChange={(captchaCode) => onChangeCaptcha(captchaCode)}
                captchaRef={captchaRef}
                siteKey={captchaKey}
            />
        </>
    )
}

export default FormWrapperDI
