import { combineReducers } from 'redux';
import branding from '../../branding/reducer';
import caseDetails from '../../caseDetails/reducer';
import companyID from '../../companyID/reducer';
import errorCode from '../../errorCode/reducer';
import fid from '../../fid/reducer';
import httpError from '../../httpError/reducer';
import intermediaries from '../../intermediaries/reducer';
import pid from '../../pid/reducer';
import processCheck from '../../processCheck/reducer';
import redirectUrls from '../../redirectUrls/reducer';
import cookieConsent from '../../cookieConsent/reducer'

export const rootReducer = combineReducers({
  branding,
  caseDetails,
  companyID,
  cookieConsent,
  errorCode,
  fid,
  httpError,
  intermediaries,
  pid,
  processCheck,
  redirectUrls,
});

export type RootState = ReturnType<typeof rootReducer>;
