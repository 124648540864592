export default function dectIds(address: string) {
  const url = new URL(address);
  const pid = getPid(url);
  return { pid, fid: getFid(url, pid) };
}

function getFid(url: URL, pid: string) {
  if (pid === '') {
    return '';
  }
  const pathParts = url.pathname.split('/');
  const fid = pathParts[1];
  const invalidFids = [
    'bevestigen',
    'embed',
    'er-is-iets-misgegaan',
    'start',
    'welkom',
    'andere-opties',
    'nieuw',
  ];

  if (invalidFids.includes(fid)) {
    return '';
  }
  return fid;
}

function extractPid(url: URL) {
  const slicedUrl = url.host.split('.');

  if (slicedUrl.length < 3) {
    return '';
  }
  return slicedUrl[0] === 'www' ? slicedUrl[1] : slicedUrl[0];
}

function getPid(url: URL) {
  const pid = extractPid(url);

  const invalidPids = [
    '',
    '192',
    'undefined',
    'test',
    'accp',
    'www',
    'testing',
    'steering-portal-test',
    'steering-portal-testing',
    'steering-portal-accp',
    'steering-portal',
    'vindmijnhersteller',
    'mijnschade',
    'mijnschades',
  ];

  if (invalidPids.includes(pid)) {
    return '';
  }
  return pid;
}
