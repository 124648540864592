import { GET_INTERMEDIARIES, GET_INTERMEDIARY } from '../shared/action-names';
import { intermediariesActionTypes, IntermediariesState } from './types';

const initialState: IntermediariesState = null;

export default function (
  state = initialState,
  action: intermediariesActionTypes,
): IntermediariesState {
  switch (action.type) {
    case GET_INTERMEDIARIES:
      return action.payload;
    case GET_INTERMEDIARY:
      return action.payload;
    default:
      return state;
  }
}
