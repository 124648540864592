const url = process.env.REACT_APP_ENDPOINTS_URL;
const urlCustomerPortal = process.env.REACT_APP_ENDPOINT_CP;

const endpoints = {
  getBranding: `${urlCustomerPortal}/branding`,
  getCase: `${url}/case`,
  getIntermediaries: `${url}/companies`,
  postCaseDICreation: `${url}/dicase`,
  postCaseDIInvite: `${url}/dicase/invite`,
  postCaseVIP: `${url}/case`,
  updateCase: `${url}/case/update`,
  licensePlateCheck: `${urlCustomerPortal}/incidents/licenseplate`,
  processcheck: `${urlCustomerPortal}/processcheck`,
};

export default endpoints;
