import React, {
  FC,
  ReactNode,
  useState,
  useMemo,
  useCallback,
  memo,
} from 'react';
import { styled } from '@mui/material/styles';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { isMobile } from 'react-device-detect';
import { Theme } from '@mui/material';
import { Colors, Scaling } from '@domain/theming';
import { useWindowSize, useInputBlur } from '@domain/hooks';
import { Company } from '@domain/interfaces';


interface Props {
  disabled?: boolean | undefined;
  disableOpenOnFocus?: boolean;
  dynamicFontSize?: boolean;
  error: boolean;
  getOptionLabel: (option: unknown) => string;
  hasTooltip?: boolean;
  label: string | ReactNode;
  minChar?: number;
  onBlur: () => void;
  onChange(event: object, value: any): void;
  options: (object | string)[];
  value: Company | string;
  vwWidth?: any;
}

interface StyledProps {
  $dynamicFontSize?: boolean;
  $hasTooltip?: boolean;
  $vwWidth?: any;
}

const PREFIX = 'index';

const classes = {
  root: `${PREFIX}-root`,
  popupIndicator: `${PREFIX}-popupIndicator`,
  textField: `${PREFIX}-textField`,
  inputRoot: `${PREFIX}-inputRoot`,
  inputInput: `${PREFIX}-inputInput`,
  inputError: `${PREFIX}-inputError`,
  inputLabelRoot: `${PREFIX}-inputLabelRoot`,
  inputLabelShrink: `${PREFIX}-inputLabelShrink`,
  inputUnderline: `${PREFIX}-inputUnderline`,
  inputLabelClasses: `${PREFIX}-inputLabelClasses`,
  inputDisabled: `${PREFIX}-inputDisabled`,
  inputLabelDisabled: `${PREFIX}-inputLabelDisabled`,
  focused: `${PREFIX}-focused`,
  inputLabelError: `${PREFIX}-inputLabelError`,
  dense: `${PREFIX}-dense`,
  menu: `${PREFIX}-menu`
};


const StyledAutocomplete
  = styled(Autocomplete, {
    shouldForwardProp: (propName) => !propName.toString().startsWith('$')
  }
  )(({ $dynamicFontSize, $vwWidth, $hasTooltip }: StyledProps) => ({
    [`& .${classes.root}`]: {
      width: '100%',
    },

    [`& .${classes.popupIndicator}`]: {
      display: 'none',
    },

    [`& .${classes.textField}`]: {
      //main container
      width: '100%',
      margin: '20px 0',
      paddingTop: '22px',
      height: 'auto',
    },

    [`& .${classes.inputRoot}`]: {
      fontFamily: 'Gabriel Sans',
      borderColor: Colors.BLACK,
      color: Colors.BLACK,
    },

    [`& .${classes.inputInput}`]: {
      textAlign: 'center',
      padding: 0,
      fontSize: $dynamicFontSize ?
        (isMobile
          ? Scaling.textFontSizeMobile(32, $vwWidth)
          : Scaling.textFontSize(32, $vwWidth)) + 'px' :
        '32px',
      fontWeight: 'bold',
      lineHeight: $dynamicFontSize ?
        (isMobile
          ? Scaling.textFontSizeMobile(36, $vwWidth)
          : Scaling.textFontSize(36, $vwWidth)) + 'px' :
        '36px',
      height:
        $dynamicFontSize ?
          (isMobile
            ? Scaling.textFontSizeMobile(48, $vwWidth)
            : Scaling.textFontSize(48, $vwWidth)) + 'px' :
          '48px',
    },

    [`& .${classes.inputError}`]: {
      color: Colors.RED,
    },

    [`& .${classes.inputLabelRoot}`]: {
      height:
        $dynamicFontSize ?
          (isMobile
            ? Scaling.textFontSizeMobile(48, $vwWidth)
            : Scaling.textFontSize(48, $vwWidth)) + 'px' :
          '48px',
      maxWidth: '100%',
      top: 'calc(50% + 20px)',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      paddingLeft: $hasTooltip ? '30px' : '0px',
      color: Colors.BLACK,
      fontFamily: 'inherit',
      fontSize: $dynamicFontSize ?
        (isMobile
          ? Scaling.textFontSizeMobile(32, $vwWidth)
          : Scaling.textFontSize(32, $vwWidth)) + 'px' :
        '32px',
      fontWeight: 'bold',
      lineHeight: '36px',
      letterSpacing: '0.03125em',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      transition: '200ms cubic-bezier(0.0, 0.0, 0.2, 1), padding 0s 0s',
      '&$focused': {
        color: Colors.BLACK,
        fontWeight: 'bold',
      },
      '&$inputLabelError': {
        color: Colors.RED,
      },
      '&+$inputRoot': {
        marginTop: 0,
      },
    },

    [`& .${classes.inputLabelShrink}`]: {
      top: 0,
      left: 0,
      transform: 'translate(0, 0) scale(1)',
      color: Colors.BLACK,
      lineHeight: '14px',
      letterSpacing: '0.0625em',
      fontSize: $dynamicFontSize ?
        (isMobile
          ? Scaling.textFontSizeMobile(16, $vwWidth)
          : Scaling.textFontSize(16, $vwWidth)) + 'px' :
        '16px',
      transition: '200ms cubic-bezier(0.0, 0.0, 0.2, 1), padding 0s 0s',
    },

    [`& .${classes.inputUnderline}`]: {
      '&$inputError': {
        '&:before': {
          borderColor: Colors.RED,
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: `1px solid ${Colors.RED}`,
        },
        '&:after': {
          borderBottom: `2px solid ${Colors.RED}`,
        },
      },
      '&:before': {
        borderColor: Colors.BLACK,
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: `1px solid ${Colors.BLACK}`,
      },
      '&:after': {
        borderBottom: `2px solid ${Colors.BLACK}`,
      },
    },

    [`& .${classes.inputLabelClasses}`]: {},
    [`& .${classes.inputDisabled}`]: {},
    [`& .${classes.inputLabelDisabled}`]: {},
    [`& .${classes.focused}`]: {},
    [`& .${classes.inputLabelError}`]: {},

    [`& .${classes.dense}`]: {
      marginTop: 19,
    },

    [`& .${classes.menu}`]: {
      width: 200,
    }
  }));


const ComboBox: FC<Props> = ({
  disabled = false,
  disableOpenOnFocus = false,
  dynamicFontSize,
  error,
  getOptionLabel,
  hasTooltip,
  label,
  onBlur,
  onChange,
  minChar = 0,
  options,
  value,
}) => {
  const [inputEl, setInputEl] = useState<HTMLInputElement | null>(null);
  useInputBlur(inputEl, onBlur);
  const [openAutoComplete, setOpenAutoComplete] = useState<boolean>(false);

  const { brandName } = value as any || '';

  const setInputRef = useCallback((el: HTMLInputElement) => setInputEl(el), []);

  const { vwWidth } = useWindowSize();

  const inputLabelProps = useMemo(
    () => ({
      classes: {
        root: classes.inputLabelRoot,
        shrink: classes.inputLabelShrink,
        focused: classes.focused,
        disabled: classes.inputLabelDisabled,
        error: classes.inputLabelError,
      },
      shrink: true,
      htmlFor: 'autocomplete',
    }),
    [classes],
  );

  const inputProps = useMemo(
    () => ({
      classes: {
        underline: classes.inputUnderline,
        root: classes.inputRoot,
        input: classes.inputInput,
        error: classes.inputError,
      },
    }),
    [classes],
  );

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams): ReactNode => {
      const inputParams = { ...params } as any;
      const inputValue = inputParams.inputProps.value || '';
      if (inputValue.length >= minChar) {
        if (inputValue !== brandName) {
          setOpenAutoComplete(true);
        } else {
          setOpenAutoComplete(false);
        }
      }
      else if (inputValue.length < minChar) {
        setOpenAutoComplete(false);
      }

      return (
        <TextField
          {...params}
          inputRef={setInputRef}
          className={classes.textField}
          label={label}
          variant="standard"
          fullWidth
          InputLabelProps={inputLabelProps}
          InputProps={{ ...inputProps, ...(params as any).InputProps }}
          required
          error={error}
          disabled={disabled}
        />
      )
    },
    [brandName, classes.textField, disabled, error, inputProps, inputLabelProps, label, minChar, setInputRef],
  );

  // if value doesn't exist in options, push it as a possivle value (so material doesn't complain) 
  if (options.find(option => getOptionLabel(option) === getOptionLabel(value)) == null) {
    options.push(value)
  }

  return (
    <StyledAutocomplete
      getOptionLabel={getOptionLabel}
      onChange={onChange}
      options={options}
      value={value}
      renderInput={renderInput}
      classes={{ root: classes.root, popupIndicator: classes.popupIndicator }}
      id="autocomplete"
      open={!disabled && openAutoComplete}
      $hasTooltip={hasTooltip}
      $vwWidth={vwWidth}
      $dynamicFontSize={dynamicFontSize}
    />
  );
};

export default memo(ComboBox);
