import { useEffect } from 'react';

type onBlur = (event?: FocusEvent) => void;

const useInputBlur = (el: HTMLInputElement | null, onBlur: onBlur): void => {
  useEffect(() => {
    if (el === null) {
      return;
    }

    el.addEventListener('blur', onBlur);

    return () => el.removeEventListener('blur', onBlur);
  }, [el, onBlur]);
};

export default useInputBlur;
