import React, { FC } from 'react';
import { styled, keyframes } from '@mui/material';
import classNames from 'classnames';
import { Colors } from '@domain/theming';
import { Scaling } from '@domain/theming'
import { useWindowSize } from '@domain/hooks';

interface StyledLoaderProps {
  $vwWidth: number
  $color?: string;
  className?: string;
  $size?: 'small' | 'medium'
}

const keyframesLoader = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledLoader = styled('div', {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
}) <StyledLoaderProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  .loader__spinner {
    display: inline-block;
    position: relative;
    width: ${props => props.$size === 'small' ? Scaling.scaleUpTo4K(48, props.$vwWidth) : Scaling.scaleUpTo4K(64, props.$vwWidth)}px;
    height: ${props => props.$size === 'small' ? Scaling.scaleUpTo4K(48, props.$vwWidth) : Scaling.scaleUpTo4K(64, props.$vwWidth)}px;
    .loader__sphere {
      width: ${props => props.$size === 'small' ? '84%' : '90%'};
      height: ${props => props.$size === 'small' ? '84%' : '90%'};
      top: ${props => props.$size === 'small' ? '10%' : '5%'};
      left: ${props => props.$size === 'small' ? '8%' : '5%'};
      position: absolute;
      animation: ${keyframesLoader} 1.2s cubic-bezier(0.5,0.3,0.4,0.8) infinite;
      &:after {
        content: '';
        display: block;
        width: ${props => props.$size === 'small' ? Scaling.scaleUpTo4K(5, props.$vwWidth) : Scaling.scaleUpTo4K(6, props.$vwWidth)}px;
        height: ${props => props.$size === 'small' ? Scaling.scaleUpTo4K(5, props.$vwWidth) : Scaling.scaleUpTo4K(6, props.$vwWidth)}px;
        border-radius: 50%;
        background: ${props => props.$color === 'white' ? Colors.WHITE : Colors.GREY_DARK};
        // margin: -3px 0 0 -3px;
        margin: 0 auto;
      }
      &:nth-of-type(1) {
        // animation-delay: -0.036s;
        animation-delay: -0.5s;
        transform: rotate(45deg);
        &:after {
          // top: 50px;
          // left: 50px;
        }
      }
      &:nth-of-type(2) {
        // animation-delay: -0.072s;
        animation-delay: -0.425s;
        transform: rotate(90deg);
        &:after {
          // top: 54px;
          // left: 45px;
        }
      }
      &:nth-of-type(3) {
        // animation-delay: -0.108s;
        animation-delay: -0.375s;
        transform: rotate(135deg);
        &:after {
          // top: 57px;
          // left: 39px;
        }
      }
      &:nth-of-type(4) {
        // animation-delay: -0.144s;
        animation-delay: -0.3s;
        transform: rotate(180deg);
        &:after {
          // top: 58px;
          // left: 32px;
        }
      }
      &:nth-of-type(5) {
        // animation-delay: -0.18s;
        animation-delay: -0.225s;
        transform: rotate(225deg);
        &:after {
          // top: 57px;
          // left: 25px;
        }
      }
      &:nth-of-type(6) {
        // animation-delay: -0.216s;
        animation-delay: -0.15s;
        transform: rotate(270deg);
        &:after {
          // top: 54px;
          // left: 19px;
        }
      }
      &:nth-of-type(7) {
        // animation-delay: -0.252s;
        animation-delay: -0.075s;
        transform: rotate(315deg);
        &:after {
          // top: 50px;
          // left: 14px;
        }
      }
      &:nth-of-type(8) {
        // animation-delay: -0.288s;
        &:after {
          // top: 45px;
          // left: 10px;
        }
      }
    }
  }
`;

interface Props {
  color?: string;
  className?: string;
  size?: 'small' | 'medium'
}


const Loader: FC<Props> = ({ size, color, className }) => {
  const classes = classNames('loader', className);
  const { vwWidth } = useWindowSize();

  return (
    <StyledLoader className={classes} $size={size} $color={color} $vwWidth={vwWidth} >
      <div className="loader__spinner">
        <div className="loader__sphere" />
        <div className="loader__sphere" />
        <div className="loader__sphere" />
        <div className="loader__sphere" />
        <div className="loader__sphere" />
        <div className="loader__sphere" />
        <div className="loader__sphere" />
        <div className="loader__sphere" />
      </div>
    </StyledLoader>
  );
}

export default Loader;
