import { ERROR_CODE_CLEAR, ERROR_CODE_SET } from '../shared/action-names';
import { errorCodeActionTypes } from './types';

export function errorCodeSet(errorCode: number): errorCodeActionTypes {
  return { type: ERROR_CODE_SET, payload: errorCode };
}

export function errorCodeClear(): errorCodeActionTypes {
  return { type: ERROR_CODE_CLEAR, payload: null };
}
