import React, { useState, FC, useEffect } from 'react';

import Heading from '../Heading';

// import { useLocalStorage } from '@domain/hooks';
import { Colors, Scaling, Shadows } from '@domain/theming';
// import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { motion } from "framer-motion";
import { styled } from '@mui/material';
import { useWindowSize } from '@domain/hooks';

interface Props {
  content: string;
  theme?: 'black' | 'white' | 'transparent';
}

interface ViewportProps {
  $vwWidth?: number;
}

const StyledBanner = styled(motion.div)`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(0,0,0,0.85);
  box-shadow: ${Shadows().Shadow3};
  color: ${Colors.WHITE};
  z-index: 10;
  &.white {
    background: rgba(255,255,255,0.8);
    color: ${Colors.BLACK};
  }
  &.transparent {
    background: rgba(0,0,0,0);
  }
`;

const StyledContainer = styled('div', {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
}) <ViewportProps>`
  padding: ${props => Scaling.scaleUpTo4K(30, props.$vwWidth)}px 0;
  margin: 0 auto;
  max-width: 1200px;
  flex: 1 1 auto;
  h4 {
    color: ${Colors.WHITE};
  }
`;

const BannerBlock = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0px;
  min-width: 80px;
  @media screen and (max-width: 900px) {
    min-width: 40px;
  }
`;

const StyledButton = styled('button')`
  width: 40px;
  height: 40px;
  background: transparent;
  border: 0;
  color: inherit;
  flex: 0 0 auto;
  transition: .2s ease-in-out;
  border-radius: 8px;
  &:hover, &:focus {
    background: rgba(255,255,255,0.2);
  }
  &:active {
    background: rgba(255,255,255,0.25);
    color: rgba(255,255,255,0.75);
  }
  @media screen and (max-width: 900px) {
    width: 30px;
    height: 30px;
  }
`;

const variants = {
  open: {
    opacity: 1,
    transition: {
      duration: .3,
      delay: .3,
    }
  },
  close: {
    opacity: 0,
    transition: {
      duration: 1,
    }
  }
}

const Banner: FC<Props> = ({
  content,
  theme = 'black',
}) => {
  const { vwWidth } = useWindowSize();
  const initialBannerOpen = localStorage.getItem('bannerOpen');
  const [bannerOpen, setBannerOpen] = useState(initialBannerOpen === 'false' ? false : true);
  useEffect(() => {
    localStorage.setItem('bannerOpen', (bannerOpen ? 'true' : 'false'));
  }, [bannerOpen])

  return (
    <>
      <StyledBanner
        className={isMobile ? 'screen-mobile' : 'screen-desktop'}
        animate={bannerOpen ? 'open' : 'close'}
        variants={variants}
      >
        <BannerBlock />
        <StyledContainer $vwWidth={vwWidth}>
          <Heading level={4}>{content}</Heading>
        </StyledContainer>
        <BannerBlock>
          <StyledButton onClick={() => setBannerOpen(false)}>X</StyledButton>
        </BannerBlock>
      </StyledBanner>
    </>
  )
};

export default Banner;
