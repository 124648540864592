import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchIntermediaries, fetchIntermediary, getEmbedCase } from '@domain/action-creators';
import {
  AnchorButton,
  Card,
  ChecklistItem,
  Container,
  Footer,
  Header,
  Heading,
  Logo,
  Main,
  Paragraph,
  ReviewCard,
  ScrollDownButton,
  Section,
  UList,
} from '@domain/components';
import { cpURL, routes } from '@domain/constants';
import { browserStorage, buildURL } from '@domain/helpers';
import { useWindowSize } from '@domain/hooks';
import { Company } from '@domain/interfaces';
import { RootState } from '@domain/root-reducer';
import { cancelCase, confirmCase, page } from '@domain/services';
import { Scaling } from '@domain/theming';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material';
import { Action, } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

const CardParagraph = styled(Paragraph as any)`
  flex: 1 0 0;
  margin: 0 25px 40px;
  min-width: 220px;
`;
const CardList = styled(UList as any)`
  flex: 1 0 0;
  margin: 0 25px 20px;
  min-width: 220px;
`;
const ButtonContainer = styled('div')`
  width: 100%;
  margin: auto auto 0;
`;

const Confirmation: FC = () => {

  const cookieConsent = useSelector((state: RootState) => state.cookieConsent)
  // tracking
  useEffect(() => {
    page('Confirmation', cookieConsent);
  }, [cookieConsent]);

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>()
  const { vwWidth } = useWindowSize();

  const intermediaries = useSelector((state: RootState) => state.intermediaries)
  const caseDetails = useSelector((state: RootState) => state.caseDetails)

  const [checkedUUID, setCheckedUUID] = useState<String>('');

  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate();

  //comes from embed||has uuid in url
  const uuid = searchParams.get('uuid')
  if (caseDetails.uuid) {
    localStorage.setItem('uuid', caseDetails.uuid);
  }

  useEffect(() => {
    if (uuid && !checkedUUID) {
      setCheckedUUID(uuid);
      dispatch(getEmbedCase(uuid));
    }
    if (!uuid && !caseDetails.insuranceInformationID && !checkedUUID) {
      const uuidFromLocalStorage = localStorage.getItem('uuid')
      if (uuidFromLocalStorage) {
        setCheckedUUID(uuidFromLocalStorage);
        dispatch(getEmbedCase(uuidFromLocalStorage));
      }
    }
  }, [checkedUUID, uuid, dispatch, caseDetails.insuranceInformationID]);

  useEffect(() => {
    const companyID = localStorage.getItem('companyID');
    if (!!companyID && companyID !== '') {
      dispatch(fetchIntermediary(companyID))
    } else {
      dispatch(fetchIntermediaries())
    }

  }, [dispatch]);

  const company: Company = useMemo(() => {
    if (!caseDetails.companyToken || (intermediaries && intermediaries.length === 0) || !intermediaries) {
      return {
        brandName: '',
        companyToken: '',
        riskCarrier: '',
      };
    }
    const { companyToken } = caseDetails;
    const inter = intermediaries.find(c => c.companyToken === companyToken) as Company;
    if (inter) {
      return inter
    }
    return {
      brandName: '',
      companyToken: '',
      riskCarrier: '',
    };

  }, [caseDetails, intermediaries]);

  const consentCode = JSON.stringify(browserStorage.cookieConsentStorage.get())
  const { uuid: excludedUUID, ...redirectCaseDetails } = caseDetails
  const cpLink = buildURL(
    `${cpURL}/nieuw?`,
    Object.entries({
      ...redirectCaseDetails,
      SPCase: `${true}`,
      ConsentCode: consentCode
    }),
  );
  const handleConfirmCase = async () => {
    const caseUpdated = await confirmCase(caseDetails.uuid as string);
    if (caseUpdated.errorCode === 0) {
      window.open(cpLink, '_blank');
    }
  };

  const handleCancelCase = () => {
    cancelCase(caseDetails.uuid as string);
    navigate(routes.noVIP);
  };

  const { brandName } = company || '';
  const riskCarrier = caseDetails.riskCarrier;
  const companyName = !!riskCarrier ? riskCarrier : brandName !== '' ? brandName : 'jouw verzekeraar';

  return (
    <>
      <Header />
      <Main>
        <Section size="lg">
          <Heading level={1} marginBottom="80px">
            {!riskCarrier ? `Je bent verzekerd bij ${brandName}, dit zijn jouw herstelopties:` : `Je bent via ${brandName} verzekerd bij ${riskCarrier}, dit zijn jouw herstelopties:`}
          </Heading>
          <Container className="flex-container" justifyContent="space-between">
            <Container
              className="container-card"
              flexGrow="4"
              flexShrink="1"
              flexBasis="0"
              paddingRight={Scaling.scaleUpTo4K(40, vwWidth) + 'px'}
            >
              <Card
                className="vip"
                flexGrow="1"
                flexShrink="1"
                flexBasis={Scaling.scaleUpTo4K(540, vwWidth) + 'px'}
                padding={`${Scaling.scaleUpTo4K(50, vwWidth)}px ${Scaling.scaleUpTo4K(60, vwWidth)}px`}
                margin={`0 0 ${Scaling.scaleUpTo4K(80, vwWidth)}px`}
                maxWidth={Scaling.scaleUpTo4K(720, vwWidth) + 'px'}
                shadow={4}
              >
                <Container flexDirection="column">
                  <Container marginBottom={Scaling.scaleUpTo4K(40, vwWidth) + 'px'}>
                    <Logo />
                  </Container>
                  <Container
                    margin={`0 -${Scaling.scaleUpTo4K(25, vwWidth)}px`}
                    flexWrap="wrap"
                    justifyContent="space-between"
                  >
                    <CardParagraph level={4}>
                      Heb je geen zin om het allemaal zelf te regelen? Kies dan
                      voor de kosteloze VIP schadeservice, dan wordt alles voor
                      je geregeld én profiteer je van een fiks aantal extra
                      voordelen!
                    </CardParagraph>
                    <CardList>
                      <ChecklistItem imgWidth={20} level={4}>
                        Kosteloos aangeboden door {companyName}
                      </ChecklistItem>
                      <ChecklistItem imgWidth={20} level={4}>
                        Gratis haal- en brengservice
                      </ChecklistItem>
                      <ChecklistItem imgWidth={20} level={4}>
                        3 jaar extra VIP garantie op het herstel
                      </ChecklistItem>
                      <ChecklistItem imgWidth={20} level={4}>
                        Track & Trace herstelproces
                      </ChecklistItem>
                    </CardList>
                  </Container>
                  <ButtonContainer>
                    <AnchorButton onClick={handleConfirmCase}>
                      Start VIP Schadeservice
                    </AnchorButton>
                  </ButtonContainer>
                </Container>
              </Card>
            </Container>
            <Container
              className="container-card"
              flexGrow="3"
              flexShrink="1"
              flexBasis="0"
              justifyContent="flex-end"
              paddingLeft={Scaling.scaleUpTo4K(40, vwWidth) + 'px'}
              maxWidth={Scaling.scaleUpTo4K(400, vwWidth) + 'px'}
            >
              <Card
                className="zelf"
                flexGrow="1"
                flexShrink="1"
                flexBasis={Scaling.scaleUpTo4K(270, vwWidth) + 'px'}
                margin={`0 0 ${Scaling.scaleUpTo4K(80, vwWidth)}px`}
                maxWidth={Scaling.scaleUpTo4K(360, vwWidth) + 'px'}
                shadow={4}
              >
                <Heading level={3} marginBottom={Scaling.scaleUpTo4K(40, vwWidth) + 'px'}>
                  Het herstel zelf regelen
                </Heading>
                <Container marginBottom={Scaling.scaleUpTo4K(60, vwWidth) + 'px'}>
                  <Paragraph level={4}>
                    Regel je het herstelproces liever zelf, kies dan voor deze
                    optie om te zien met welke herstelbedrijven jouw verzekeraar
                    samenwerkt.
                  </Paragraph>
                </Container>
                <ButtonContainer>
                  <AnchorButton onClick={handleCancelCase} variant="outline">
                    Ik regel het zelf
                  </AnchorButton>
                </ButtonContainer>
              </Card>
            </Container>
          </Container>

          <ScrollDownButton iconOnly={true} />
        </Section>

        <Section dark={true}>
          <Heading level={1} margin={`${Scaling.scaleUpTo4K(20, vwWidth)}px auto ${Scaling.scaleUpTo4K(80, vwWidth)}px`}>
            Zo werkt de VIP schadeservice
          </Heading>
          <Container marginBottom={Scaling.scaleUpTo4K(80, vwWidth) + 'px'}>
            <UList>
              <ChecklistItem
                alignItems="center"
                imgIcon="onboarding"
                imgWidth={80}
                spacing={Scaling.scaleUpTo4K(40, vwWidth) + 'px'}
              >
                <Heading level={4} textAlign="left">
                  Beschrijf jouw schade eenvoudig online
                </Heading>
                <Paragraph level={4}>
                  Doordat je jouw schade online beschrijft heeft het
                  herstelbedrijf meteen alle benodigde informatie. Dit scheelt
                  je een rit naar het herstelbedrijf voor een schouwing!
                </Paragraph>
              </ChecklistItem>
              <ChecklistItem
                alignItems="center"
                imgIcon="24h"
                imgWidth={80}
                spacing={Scaling.scaleUpTo4K(40, vwWidth) + 'px'}
              >
                <Heading level={4} textAlign="left">
                  Het herstelbedrijf neemt binnen een werkdag contact op
                </Heading>
                <Paragraph level={4}>
                  Bespreek met het herstelbedrijf waar zij de auto kunnen
                  ophalen. Bijvoorbeeld thuis of op kantoor. En kies jouw
                  gewenste vervangend vervoer.
                </Paragraph>
              </ChecklistItem>
              <ChecklistItem
                alignItems="center"
                imgIcon="topRepair"
                imgWidth={80}
                spacing={Scaling.scaleUpTo4K(40, vwWidth) + 'px'}
              >
                <Heading level={4} textAlign="left">
                  Jouw auto wordt opgehaald
                </Heading>
                <Paragraph level={4}>
                  Het herstelbedrijf haalt op de afgesproken locatie de auto op
                  en levert meteen het vervangend vervoer af. Zo ben jij er
                  minimale tijd en moeite aan kwijt.
                </Paragraph>
              </ChecklistItem>
              <ChecklistItem
                alignItems="center"
                imgIcon="carFixed"
                imgWidth={80}
                spacing={Scaling.scaleUpTo4K(40, vwWidth) + 'px'}
              >
                <Heading level={4} textAlign="left">
                  Het herstelbedrijf levert jouw auto als nieuw af
                </Heading>
                <Paragraph level={4}>
                  Jouw auto wordt gerepareerd en gewassen weer afgeleverd op een
                  moment en locatie naar keuze. Je ontvangt maar liefst 7 jaar
                  garantie op het uitgevoerde herstel!
                </Paragraph>
              </ChecklistItem>
            </UList>
          </Container>
        </Section>

        <Section size="lg">
          <Heading level={1} margin={`${Scaling.scaleUpTo4K(20, vwWidth)}px auto ${Scaling.scaleUpTo4K(80, vwWidth)}px`}>
            Reacties
          </Heading>
          <Paragraph level={4} textAlign="center">
            Een greep uit de reacties van klanten die al gebruik hebben gemaakt
            van de VIP schadeservice
          </Paragraph>
          <Container
            justifyContent="space-between"
            alignItems="stretch"
            flexWrap="wrap"
            margin={`${Scaling.scaleUpTo4K(80, vwWidth)}px auto 0`}
            width="100%"
          >
            <Container justifyContent="center">
              <ReviewCard reviewValue="9" reviewName="P. R. uit Lichtenvoorde">
                Goede communicatie. Schadebedrijf komt afspraken na. Belt direct
                voor overleg wanneer er een wijziging plasts vindt.
              </ReviewCard>
            </Container>
            <Container justifyContent="center">
              <ReviewCard reviewValue="10" reviewName="M. Roozen uit Panningen">
                De service, het meedenken, de prettige en persoonlijke
                communicatie. En uiteraard het resultaat. Prachtig. Alle dank!
              </ReviewCard>
            </Container>
            <Container justifyContent="center">
              <ReviewCard reviewValue="10" reviewName="Y. Bijl uit Schiedam">
                Netjes verzorgt fijne service auto opgehaald door schade herstel
                bedrijf en een leen auto we zijn zeer tevreden.
              </ReviewCard>
            </Container>
          </Container>
          <Container justifyContent="center" marginBottom="80px">
            <AnchorButton onClick={handleConfirmCase}>
              Start VIP Schadeservice
            </AnchorButton>
          </Container>
        </Section>
      </Main>
      <Footer />
    </>
  );
};

export default Confirmation;
