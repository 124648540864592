import React from 'react'
import { StyledComponentProps, styled } from '@mui/material';

interface Props extends StyledComponentProps {
  children?: React.ReactNode
  onClick: () => void
}
const StyledTextButton = styled('span')`
  cursor: pointer;
  color: blue;
  text-decoration: underline;
`;

function TextButton(props: Props) {

  const { children } = props

  return (
    <StyledTextButton role='button'>
      {children}
    </StyledTextButton>

  );
}

export default TextButton;
