import { endpoints } from '@domain/constants';
import { makeCall } from '@domain/helpers';
import { CaseDataCreation, HeaderIdentificator, ServerResponseDICreation } from '@domain/interfaces';

type PostHeadersDI = { 'Content-Type': 'application/json' } & (HeaderIdentificator)

//need new server response interface
async function postCaseDICreation(body: CaseDataCreation, header: HeaderIdentificator): Promise<ServerResponseDICreation> {

  const { customerEmailCreation, licensePlateCreation } = body;

  const headers: PostHeadersDI = {
    'Content-Type': 'application/json',
    ...header,
  }

  const data = await makeCall<ServerResponseDICreation>(endpoints.postCaseDICreation, {
    method: 'POST',
    headers: headers,
    body: {
      'CustomerEmail': customerEmailCreation,
      'LicensePlate': licensePlateCreation,
    },
  });


  return data as ServerResponseDICreation;
}

export default postCaseDICreation;
