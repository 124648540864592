import Color from 'color';
import { Branding } from "@domain/interfaces";
import { Colors } from "@domain/theming";

import { Theme, createTheme } from '@mui/material';



const generateThemeValues = (rawBranding: Branding | null, isThemed: Boolean = true, isCustomThemed: Boolean = false) => {

  const branding = !!rawBranding ? rawBranding : {
    colorPrimaryHEX: Colors.GREY_DARK
  } as Branding;

  const color = Color(branding.colorPrimaryHEX);

  const isLight = (val: string) => {
    const baseColor = Color(val);
    const g = baseColor.green();
    const r = baseColor.red();
    const b = baseColor.blue();

    // const totalLuminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    // return totalLuminance > Math.sqrt(1.05 * 0.05) - 0.05;

    return (r * 0.299 + g * 0.587 + b * 0.114) > 186
  }

  const viewportHeight = window.innerHeight;

  const themeValues = {
    primaryColor: isThemed && !!branding.colorPrimaryHEX ? Color(branding.colorPrimaryHEX) : Color(Colors.GREY_DARK),
    fontFamily: (isThemed && branding.fontFamily ? branding.fontFamily + ' ' : '') + 'Gabriel Sans',
    favicon: branding.favicon || 'favicon',
    logo: branding.logoURL || '',
    isLight: isThemed && !!branding.colorPrimaryHEX && isLight(color.hex()),
    isThemed: isThemed,
    type: branding.brand || '',
    isCustomThemed: isCustomThemed,
    viewportHeightMobile: viewportHeight,
    colorAsBackground: true, // branding.colorAsBackground,
    headerBackground: false, // branding.headerBackground,
    backgroundImage: branding.backgroundURL,
    logoSize: branding.logoSize
  }
  const generatedTheme: Theme = {
    ...createTheme({
      palette: {
        primary: {
          main: themeValues.primaryColor.hex(),
          contrastText: themeValues.isLight ? Color(Colors.GREY_DARK).hex() : Color(Colors.WHITE).hex(),
        },
        secondary: {
          main: themeValues.isLight ? themeValues.primaryColor.lighten(0.2).hex() : themeValues.primaryColor.lightness(66).hex(),
          contrastText: themeValues.isLight ? Color(Colors.GREY_DARK).hex() : Color(Colors.WHITE).hex(),
        }
      },
      typography: {
        fontFamily: themeValues.fontFamily,
      }
    }), ...themeValues
  };


  return generatedTheme;

};

export default generateThemeValues;
