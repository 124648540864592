enum Colors {
  BLACK = '#000000',
  GREY_DARKER = '#212121',
  GREY_DARK = '#434244', // _TEXT
  GREY_PRIMARY = '#A1A0A1',
  GREY_LIGHT = '#E2E2E2',
  GREY_LIGHTER = '#F2F2F2', // _BACKGROUND
  WHITE = '#FFFFFF',
  BLUE = '#393EB9',
  RED = '#ed1c24',
  YELLOW = '#FFDD00'
}

export default Colors;
