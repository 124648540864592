import { useRef } from 'react';
import { useDispatch } from 'react-redux';

import { postCaseVMH } from '@domain/action-creators';
import { captchaKey } from '@domain/constants';
import { getIP, mapYupValidationSchemaErrors } from '@domain/helpers';
import { CaseData, FormData } from '@domain/interfaces';

import moment from 'moment';
import { AnyObject, ObjectSchema } from 'yup';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { RootState } from '@domain/root-reducer';

function useSubmitDataVMH() {
  const saving = useRef(false);

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const submitData = async (
    captchaCode: string | null,
    formData: FormData,
    validationSchema: ObjectSchema<AnyObject>,
    embed?: boolean,
  ) => {
    if (saving.current) {
      return;
    }

    try {
      await validationSchema.validate(formData);
    } catch (errorResult) {
      //@ts-ignore
      const errors = mapYupValidationSchemaErrors(errorResult?.inner);
      console.warn(
        'User attempted to submit form with invalid data: \n',
        JSON.stringify(errors),
      );
      return;
    }

    if (captchaCode === null) {
      return;
    }

    const ip = await getIP();

    const {
      licensePlate,
      customerEmail,
      customerZipCode,
      damageDate,
      intermediary,
    } = formData;

    const { companyToken } = intermediary;
    const dateTimeFormat = 'YYYY-MM-DDT12:mm:ss.sssZ';
    const damageDateTime = moment(damageDate).format(dateTimeFormat);

    const caseData: CaseData = {
      captchaCode,
      licensePlate,
      customerEmail,
      customerZipCode,
      companyToken,
      damageDate: damageDateTime,
      ip,
      siteKey: captchaKey,
    };

    saving.current = true;

    dispatch(postCaseVMH(caseData));
    saving.current = false;
  };
  return submitData;
}

export default useSubmitDataVMH;
