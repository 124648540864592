import React, { ReactNode, FC } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const PREFIX = 'index';

const classes = {
  modalRootDisabled: `${PREFIX}-modalRootDisabled`,
  modalRootEnabled: `${PREFIX}-modalRootEnabled`
};

const Root = styled('div')(() => ({
  [`& .${classes.modalRootDisabled}`]: {
    background: 'rgba(255,255,255,0)',
  },

  [`& .${classes.modalRootEnabled}`]: {
  }
}));

interface Props {
  backdrop?: boolean;
  fullScreen?: boolean;
  open: boolean;
  onYesClick?: (e?: any) => void;
  onNoClick?: () => void;
  title: string;
  content: string | ReactNode;
  yesLabel?: string;
  noLabel?: string;
  showYesButton?: boolean;
  showNoButton?: boolean;
}

const ConfirmationDialog: FC<Props> = ({
  backdrop,
  fullScreen = false,
  open,
  onYesClick = () => { },
  onNoClick = () => { },
  title,
  content,
  yesLabel = 'Ja',
  noLabel = 'Nee',
  showYesButton = true,
  showNoButton = true,
}) => {


  return (
    <Root>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{
          classes: {
            root: backdrop ? classes.modalRootEnabled : classes.modalRootDisabled,
          }
        }}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {showNoButton && (
            <Button onClick={onNoClick} autoFocus>
              {noLabel}
            </Button>
          )}
          {showYesButton && (
            <Button onClick={onYesClick}>
              {yesLabel}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Root>
  );
};

export default React.memo(ConfirmationDialog);
