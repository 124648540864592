import { useState, useEffect } from 'react';
import { AnyObject, ObjectSchema } from 'yup';
import { FormData, FormDataCreation } from '@domain/interfaces';
import { mapYupValidationSchemaErrors } from '@domain/helpers';

const useValidation = (validationSchema: ObjectSchema<AnyObject>, values: FormData | FormDataCreation) => {
  const [errors, setErrors] = useState<Record<string, string> | null>({});

  useEffect(() => {
    const validate = async () => {
      try {
        await validationSchema.validate(values, { abortEarly: false });
        setErrors(null);
      } catch (errorResult) {
        //@ts-ignore
        setErrors(mapYupValidationSchemaErrors(errorResult.inner));
      }
    };

    const id = setTimeout(validate, 100);

    return () => clearTimeout(id);
  }, [values, validationSchema]);

  return errors;
};

export default useValidation;
