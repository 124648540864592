import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { motion } from 'framer-motion';
import { styled } from '@mui/material';

import { Container, ExpandableTextBox, Heading, Loader, Paragraph } from '@domain/components';
import { RootState } from '@domain/root-reducer';
import { ContentStructureInfoBlock, Paragraphs } from '@domain/interfaces';
import { useWindowSize } from '@domain/hooks';
import { Divider } from '@mui/material';
import { isMobile } from 'react-device-detect';


const AnimatedContainer = styled(
    motion(Container) as any,
)`
  `;

const introTextVariants = {
    hidden: {
        // y: 200,
        opacity: 0,
    },
    visible: {
        // y: 0,
        opacity: 1,
        transition: {
            ease: 'easeInOut',
            opacity: {
                // delay: 0.54,
                duration: 0.66,
            },
        },
    },
}

interface Props {
    content: ContentStructureInfoBlock
    addDivider?: number
}
const InfoBlock: FC<Props> = ({ content, addDivider = -1 }) => {
    const { vwWidth } = useWindowSize();

    const branding = useSelector((state: RootState) => state.branding)

    if (!branding) {
        return <Loader />;
    }

    const showIntroTextList = content['show-intro-text-list']
    const title = content["title"]
    const introTextParagraphs = content['intro-text-paragraphs']
    const introTextList = content['intro-text-list']
    // const showSignatureContainer = content["show-signature-container"]
    // const customPaddingBottom = content["padding-bottom"]
    // const signatureText = content["signature-text"]
    // const signatureLogo = content["signature-logo"]
    // const showBackGroundImage = content['show-background-image']
    // const showHeaderInfoBLock = content['show-header-infoblock']
    // const logoURL = (!!branding && branding.logoURL) || '';

    const generateParagraphs = introTextParagraphs.map((item: Paragraphs, i) => {
        return (
            <React.Fragment key={Math.random()}>
                <Paragraph paddingTop="15px" textAlign="left" level={4} >
                    {item.text}
                </Paragraph>
                {addDivider === i && <Divider />}
            </React.Fragment>
        )
    })

    const generateIntroTextList = introTextList ? introTextList.map((item: Paragraphs, i: number) => {
        return (
            <li key={i}><Paragraph marginTop="20px" textAlign="left" level={4}> {item.text}</Paragraph></li>
        )
    }) : null;

    return (
        <AnimatedContainer
            className="landing__intro-text--content"
            flexDirection="column"
            flexGrow="0"
            justifyContent="flex-start"
            variants={introTextVariants}
            initial="hidden"
            animate="visible"
            margin="auto"
        >
            {title && <Heading className="h1" level={1} marginBottom="15px" textAlign="left"> {title} </Heading>}
            <ExpandableTextBox
                className="landing__intro-text--content"
                minLine={!isMobile ? 0 : 6}
                startOpen={!isMobile}
            >
                {<>{generateParagraphs}</>}
                {showIntroTextList && <ul>{generateIntroTextList}</ul>}
            </ExpandableTextBox>
        </AnimatedContainer>
    )
}

export default InfoBlock
