import React, { Children, ReactElement, ReactNode } from 'react';
import { StyledComponentProps, styled } from '@mui/material';
import { Colors, Shadows } from '@domain/theming';
import Color from 'color';


interface Props extends StyledComponentProps {
  variant?: 'primary' | 'outline';
  onClick?: () => void
  disabled?: boolean
  children: ReactElement | ReactNode
}

interface StyledProps extends StyledComponentProps {
  $variant?: 'primary' | 'outline';
}

const StyledButton = styled('button') <StyledProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  max-width: 380px;
  padding: 12px 24px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  background: ${props => props.$variant === 'outline' ? 'transparent' : props.theme.isThemed ? props.theme.palette.primary.main : Colors.BLACK};
  color: ${props => props.$variant === 'outline' ? !!props.theme.isThemed ? props.theme.palette.primary.main : Colors.BLACK : props.theme.isThemed ? props.theme.palette.primary.contrastText : Colors.WHITE};
  border: 1px solid ${props => !!props.theme.isThemed ? props.theme.palette.primary.main : Colors.BLACK};
  border-radius: 16px;
  cursor: pointer;
  transition: 160ms cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: ${props => props.$variant !== 'outline' && Shadows().Shadow6};
  @media (hover: hover) {
    &:hover {
      transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
      box-shadow: ${props => props.$variant !== 'outline' && Shadows().Shadow3};
      background: ${props => props.$variant !== 'outline' ? props.theme.isThemed ? props.theme.palette.primary.light : Color(Colors.BLACK).rgb().alpha(0.7).string() : Color(Colors.BLACK).rgb().alpha(0.08).string()}
    }
  }
  &:focus {
    transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: ${props => props.$variant !== 'outline' && Shadows().Shadow3};
  }
  &:active {
    transform: scale(0.98);
    transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: ${props => props.$variant !== 'outline' && Shadows().Shadow1};
  }
  &[disabled] {
    box-shadow: none;
  }
  &.white {
    box-shadow: none;
  }
  .card & {
    margin: auto auto 0;
  }
`;

const Button = (props: Props) => {
  const { variant, children, ...otherprops } = props

  return (
    <StyledButton
      $variant={variant}
      {...otherprops}
    >
      {Children.toArray(children)}
    </StyledButton>
  );
}

Button.displayName = 'Button'
export default Button;
