type convertString = (str: string) => string;

const convertKeys = (obj: any, convertKey: convertString): any => {
  if (['number', 'boolean', 'string'].includes(typeof obj)) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(val => convertKeys(val, convertKey));
  }

  const newObject: any = {};
  for (let key in obj) {
    const newKey = convertKey(key);
    const value: any = obj[key];
    const isObject = typeof value === 'object';
    const newValue = isObject ? convertKeys(value, convertKey) : value;
    newObject[newKey] = newValue;
  }

  return newObject;
};

export default convertKeys;
