import { useCallback, useEffect, useState } from 'react';
import { ParamsUrl } from '@domain/interfaces';

import { useSearchParams } from 'react-router-dom';

const useGetParamsFromUrl = (
  acceptedAttributes: Array<string>,
  location: any,
) => {
  const [params, setParams] = useState<Record<string,string>>({});
  const [attributesFromUrl, setAttributesFromUrl] = useState<ParamsUrl>({});
  const [searchRetrieved, setSearchRetrieved] = useState<Boolean>(false);
 const [searchP, setSearchP] = useSearchParams()

  const search = location.search;

  const makeCamelCase = (text: string) =>
    !text ? '' : text[0].toLowerCase() + text.slice(1);

  const retrieveAttrFromSearch = useCallback(
    (fields: Array<string>) => {
      const incident = Object.entries(params).reduce((acc, [key, value]) => {
        const attrKey = ['uuid', 'fid', 'rid', 'pid'].includes(
          key.toLowerCase(),
        )
          ? key.toLowerCase()
          : makeCamelCase(key);

        if (!value || !fields.includes(attrKey)) {
          return acc;
        }

        return { ...acc, [attrKey]: decodeURIComponent(value as string) };
      }, {});
      if (Object.keys(incident).length > 0) {
        setAttributesFromUrl(incident);
      }
      setSearchRetrieved(true);
    },
    [params],
  );

  useEffect(() => {
    if (searchRetrieved) {
      return;
    }
    if (Object.keys(attributesFromUrl).length > 0) {
      return;
    }
    if (search && Object.keys(attributesFromUrl).length === 0) {
      const searchAttributes = Object.fromEntries(searchP.entries())
      setParams(searchAttributes);
      if (Object.keys(params).length > 0) {
        retrieveAttrFromSearch(acceptedAttributes);
        return;
      }
      return;
    }
  }, [
    acceptedAttributes,
    retrieveAttrFromSearch,
    search,
    attributesFromUrl,
    searchRetrieved,
    params,
  ]);

  return { attributesFromUrl };
};
export default useGetParamsFromUrl;
