import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';

import Color from 'color'
import { Colors, Scaling, Shadows } from '@domain/theming';
import { useWindowSize } from '@domain/hooks';
import { styled } from '@mui/material';

interface StyledAnchorButtonProps extends React.ComponentPropsWithoutRef<'a'> {
  $variant?: 'primary' | 'outline',
  target?: '_blank' | '',
  className?: string,
  $dynamicFontSize?: boolean,
  $vwWidth: number
}

interface Props extends React.ComponentPropsWithoutRef<'a'> {
  variant?: 'primary' | 'outline',
  target?: '_blank' | '',
  className?: string,
  dynamicFontSize?: boolean,
  href?: string,
  onClick?: any,
}

const StyledAnchorButton = styled('a', {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
}) <StyledAnchorButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${props => Scaling.scaleUpTo4K(64, props.$vwWidth)}px;
  max-width: ${props => Scaling.scaleUpTo4K(380, props.$vwWidth)}px;
  padding: ${props => Scaling.scaleUpTo4K(12, props.$vwWidth)}px ${props => Scaling.scaleUpTo4K(24, props.$vwWidth)}px;
  font-size: ${props => props.$dynamicFontSize ? (isMobile
    ? Scaling.textFontSizeMobile(12, props.$vwWidth)
    : Scaling.textFontSize(12, props.$vwWidth)) + 'px' : '12px'}
  font-weight: 700;
  line-height: ${props => props.$dynamicFontSize ? (isMobile
    ? Scaling.textFontSizeMobile(14, props.$vwWidth)
    : Scaling.textFontSize(14, props.$vwWidth)) + 'px' : '14px'}
  letter-spacing: 0.02em;
  text-transform: uppercase;
  background: ${props => props.$variant === 'outline' ? 'transparent' : props.theme.isThemed ? props.theme.palette.primary.main : Colors.BLACK};
  color: ${props => props.$variant === 'outline' ? !!props.theme.isThemed ? props.theme.palette.primary.main : Colors.BLACK : props.theme.isThemed ? props.theme.palette.primary.contrastText : Colors.WHITE};
  border: ${props => Scaling.scaleUpTo4K(1, props.$vwWidth)}px solid ${props => !!props.theme.isThemed ? props.theme.palette.primary.main : Colors.BLACK};
  border-radius: ${props => Scaling.scaleUpTo4K(16, props.$vwWidth)}px;
  cursor: pointer;
  transition: 160ms cubic-bezier(0.4, 0, 0.2, 1);
  text-decoration: none;
  box-shadow: ${props => props.$variant !== 'outline' && Shadows().Shadow6};
  @media (hover: hover) {
    &:hover {
      transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
      box-shadow: ${props => props.$variant !== 'outline' && Shadows().Shadow3};
      background: ${props => props.$variant !== 'outline' ? props.theme.isThemed ? props.theme.palette.primary.light : Color(Colors.BLACK).rgb().alpha(0.7).string() : Color(Colors.BLACK).rgb().alpha(0.08).string()}
    }
  }
  &:focus {
    transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: ${props => props.$variant !== 'outline' && Shadows().Shadow3};
  }
  &:active {
    transform: scale(0.98);
    transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: ${props => props.$variant !== 'outline' && Shadows().Shadow1};
  }
  &[disabled] {
    box-shadow: none;
  }
  &.white {
    box-shadow: none;
  }
  .card & {
    margin: auto auto 0;
  }
  &.black-and-white {
    position:relative;
    max-width: ${props => Scaling.scaleUpTo4K(580, props.$vwWidth)}px;
    background: ${Colors.GREY_LIGHTER};
    border: 0;
    color: ${Colors.BLACK};
    margin: ${props => Scaling.scaleUpTo4K(30, props.$vwWidth)}px 0; 
    justify-content: center;
    box-shadow: none;
    &.secondary_button {
      opacity: 0.7;
    }
    &.open {
      box-shadow: ${props => props.$variant !== 'outline' && Shadows().Shadow6};
      background: ${Colors.WHITE};
    }
    .button__icon {
      color: ${Colors.BLACK};
      border-radius: ${props => Scaling.scaleUpTo4K(16, props.$vwWidth)}px;
      margin-left: auto;
    }
    @media (hover: hover) {
      &:hover {
        transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
        background: ${Colors.WHITE}
        color: ${Colors.BLACK}
        box-shadow: ${Shadows().Shadow1};
        &.open {
          box-shadow: ${Shadows().Shadow6};
          background: ${Colors.GREY_LIGHTER}
        }
        .button__icon {
          color: ${Colors.BLACK};
          border-radius: ${props => Scaling.scaleUpTo4K(16, props.$vwWidth)}px;
          margin-left: auto;
        }
      }
    }
  }
  &.volgende-button {
    max-width: ${props => Scaling.scaleUpTo4K(580, props.$vwWidth)}px;
    width: ${props => Scaling.scaleUpTo4K(240, props.$vwWidth)}px;
    justify-content: space-between;
    background: ${Colors.WHITE};
    border: 0;
    &.disabled {
        color: ${Colors.GREY_LIGHT};
        pointer-events: none;
        cursor: default;
        .button__icon {
          background: ${Colors.GREY_LIGHT};
          color: ${Colors.WHITE};
          border-radius: ${props => Scaling.scaleUpTo4K(16, props.$vwWidth)}px;
          margin-left: auto;
        }
      }
    &.enabled {
      color: ${Colors.WHITE};
      border: 0;
      box-shadow: ${Shadows().Shadow6};
      background: ${Colors.BLACK};
      .button__icon {
        background: ${Colors.WHITE};
        color: ${Colors.BLACK};
        border-radius: ${props => Scaling.scaleUpTo4K(16, props.$vwWidth)}px;
        margin-left: auto;
      }
    }
  }
`;
const AnchorButton: FC<Props> = ({
  variant,
  target,
  className,
  dynamicFontSize,
  children,
  href,
  onClick
}) => {

  const { vwWidth } = useWindowSize();
  return (
    <StyledAnchorButton
      $variant={variant}
      target={target}
      className={className}
      $dynamicFontSize={dynamicFontSize}
      $vwWidth={vwWidth}
      href={href}
      onClick={onClick}
    >
      {React.Children.toArray(children)}
    </StyledAnchorButton>
  );
};

export default AnchorButton;
