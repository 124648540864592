import { browserStorage } from '../browserStorage';
import buildURL from '../buildURL';

import { CaseInfo } from '@domain/interfaces';

const generateRedirectURL = (caseDetails: CaseInfo, pid: string, fid: string, extraInfo: object) => {
const consentCode = JSON.stringify(browserStorage.cookieConsentStorage.get())
  const isSpCaseAndPid = { ...caseDetails, ...extraInfo, SPCase: true, PID: pid, FID: fid, ConsentCode: consentCode }

  const redirectUrl = buildURL(
    `${process.env.REACT_APP_CP_DI_URL}/nieuw?`,
    Object.entries(isSpCaseAndPid),
  );

  const url = redirectUrl;
  return url;
};

export default generateRedirectURL;
