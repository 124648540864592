import {
  FormRhion,
  RedirectLinkSG,
  UrlCreation,
  VmhEmbedRedirectLink,
} from '@domain/interfaces';
import {
  REDIRECT_URL_SET,
  URL_CREATION_DELETE,
  URL_CREATION_SET,
  URL_SG_SET,
  URL_VMH_EMBED_SET,
} from '../shared/action-names';
import { redirectUrlsActionTypes } from './types';

export function redirectUrlsSet(
  redirectUrl: FormRhion,
): redirectUrlsActionTypes {
  return { type: REDIRECT_URL_SET, payload: redirectUrl };
}

export function urlCreationSet(
  redirectUrl: UrlCreation,
): redirectUrlsActionTypes {
  return { type: URL_CREATION_SET, payload: redirectUrl };
}

export function urlCreationDelete(): redirectUrlsActionTypes {
  return { type: URL_CREATION_DELETE, payload: null };
}

export function urlSGSet(redirectUrl: RedirectLinkSG): redirectUrlsActionTypes {
  return { type: URL_SG_SET, payload: redirectUrl };
}

export function vmhEmbedRedirectSet(
  redirectUrl: VmhEmbedRedirectLink,
): redirectUrlsActionTypes {
  return { type: URL_VMH_EMBED_SET, payload: redirectUrl };
}
