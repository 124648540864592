import { Reducer, applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import {thunk} from 'redux-thunk';
import { RootState, rootReducer } from './shared/root-reducer';
import { ActionTypes } from './shared/action-types';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(applyMiddleware(thunk));

const store = createStore(rootReducer as unknown as Reducer<RootState, ActionTypes>, enhancer);

export default store;
