import React, { FC, useEffect, } from 'react';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material';

import { ErrorDialogs } from '@domain/blocks';
import { Container, Heading, Main, Section, } from '@domain/components';
import { detectContentType, detectLayoutType } from '@domain/helpers';
import { useWindowSize } from '@domain/hooks';
import { ContentOptions, LayoutOptions } from '@domain/interfaces';
import { GenerateLayout } from '@domain/layouts';
import { RootState } from '@domain/root-reducer';
import { page } from '@domain/services';
import { Scaling } from '@domain/theming';
import { ErrorCode } from '../../shared/constants';
import NoSGPage from '../NoSGPage';

const StyledMain = styled(Main as any)`
height: 100vh;
`
const FrontOffice: FC = () => {
  const cookieConsent = useSelector((state: RootState) => state.cookieConsent)
  useEffect(() => {
    page('Start pagina', cookieConsent);
  }, [cookieConsent]);

  const { vwWidth } = useWindowSize();
  const branding = useSelector((state: RootState) => state.branding)
  const processCheck = useSelector((state: RootState) => state.processCheck)
  const errorCode = useSelector((state: RootState) => state.errorCode)

  const contentType: ContentOptions = detectContentType(branding)
  const layoutType: LayoutOptions = detectLayoutType(branding)

  if (processCheck === false) {
    return (
      <>
        <StyledMain>
          <Section>
            <Container justifyContent="center" alignItems="center" padding={Scaling.scaleUpTo4K(10, vwWidth) + 'px'}>
              <Heading level={1}>
                Sorry er is iets misgegaan. Neem contact op met uw verzekeraar.
              </Heading>
            </Container>
          </Section>
        </StyledMain>
      </>
    )
  }

  if (errorCode === ErrorCode.NO_EXPERIENCE) {
    return <NoSGPage />
  }

  return (
    <>
      <ErrorDialogs />
      <GenerateLayout contentType={contentType} layoutType={layoutType} />
    </>
  );
};

export default FrontOffice;
