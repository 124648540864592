import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { HtmlHead } from '@domain/components';
import { routes } from '@domain/constants';
import { RootState } from '@domain/root-reducer';

import Confirmation from './Confirmation';
import Landing from './Landing';
import NoVIP from './NoVIP';

import Embed from './Embed';
import FrontOffice from './FrontOffice';



const Flow: FC = () => {
  const { confirmation, embed, frontOffice, landing, nieuw, noVIP } = routes;

  const pid = useSelector((state: RootState) => state.pid)
  const branding = useSelector((state: RootState) => state.branding)
  const location = useLocation();

  const redirectToPortal = useCallback(() => {
    const portalRedirectURL = process.env.REACT_APP_CP_DI_URL + nieuw + '?PID=' + pid + '&BSID=' + pid;
    !!pid && pid !== '' && window.location.replace(portalRedirectURL);
    return undefined
  }, [pid, nieuw]);

  if (pid === null) {
    return <> </>;
  }

  const faviconCustom = !!branding && !!branding.favicon ? branding.favicon : undefined;
  if (location.pathname === nieuw) {
    return (
      <Routes>
        <Route path={nieuw} element={redirectToPortal()} />
      </Routes>
    )
  }
  if (pid === '') {
    return (
      <React.Fragment>
        <HtmlHead faviconCustom={'no-branding-call'} />
        <Routes>
          <Route path={embed} element={<Embed />} />
          <Route path={embed + '/:company'} element={<Embed />} />
          <Route path={`${landing}/:company`} element={<Landing />} />
          <Route path={landing} element={<Landing />} />
          <Route path={confirmation} element={<Confirmation />} />
          <Route path={noVIP} element={<NoVIP />} />
          <Route path="/:company" element={<Landing />} />
          <Route path="*" element={<Navigate to={landing} replace />} />
        </Routes>
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      <HtmlHead faviconCustom={faviconCustom} />
      <Routes>
        <Route path={frontOffice} element={<FrontOffice />} />
        <Route path=":fid" element={<FrontOffice />} />
        <Route path="*" element={<Navigate to={frontOffice} replace />} />
      </Routes>
    </React.Fragment>
  )
};

export default Flow;
