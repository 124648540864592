import React, { FC, Children } from 'react';
import { TickSVG } from '..';
import { useWindowSize } from '@domain/hooks';
import { Scaling } from '@domain/theming';
import { isMobile } from 'react-device-detect';
import { styled } from '@mui/material';
// Have to import directly to avoid circlular logic
// :(
import Text from '../Text';

interface Props {
  width?: string;
  gutterWidth?: string;
  imgIcon?: string;
  imgWidth?: number;
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  alignItems?: string;
  spacing?: string;
  vwWidth?: number;
  children?: React.ReactNode
}

interface StyledProps {
  $width?: string;
  $gutterWidth?: string;
  $imgIcon?: string;
  $imgWidth?: number;
  $alignItems?: string;
  $spacing?: string;
  $vwWidth?: number;
}

const ListItem = styled('li', {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
}) <StyledProps>`
  display: flex;
  flex-direction: row;
  align-items: ${props => props.$alignItems || 'flex-start'};
  width: ${props => props.$width || '100%'};
  padding-bottom: ${props => props.$spacing || '20px'};
`;

const IconContainer = styled('div', {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
}) <StyledProps>`
  flex: 0 0
    ${props =>
    !!props.$imgWidth
      ? (isMobile
        ? Scaling.textFontSizeMobile(
          props.$imgWidth,
          props.$vwWidth,
        )
        : Scaling.textFontSize(
          props.$imgWidth,
          props.$vwWidth,
        )) + 'px'
      : 'auto'};
  height: ${props =>
    !!props.$imgWidth
      ? (isMobile
        ? Scaling.textFontSizeMobile(props.$imgWidth, props.$vwWidth)
        : Scaling.textFontSize(props.$imgWidth, props.$vwWidth)) + 'px'
      : '20px'};
  margin-right: ${props => props.$gutterWidth || '20px'};
  ${props =>
    props.$imgIcon &&
    `background-image: url("/img/icon-${props.$imgIcon}.png");
    background-repeat: no-repeat;
    background-size: contain;`}
`;

const Icon = () => (
  <TickSVG
    borderRadius="50%"
    width="100%"
    height="auto"
  />
);

const ChecklistItem: FC<Props> = ({
  alignItems,
  children,
  gutterWidth,
  imgIcon,
  imgWidth,
  level,
  spacing,
  width,
}) => {
  const { vwWidth } = useWindowSize();
  return (
    <ListItem $alignItems={alignItems} $spacing={spacing} $width={width}>
      <IconContainer
        $imgIcon={imgIcon}
        $imgWidth={imgWidth}
        $gutterWidth={gutterWidth}
        $vwWidth={vwWidth}
      >
        {!imgIcon && <Icon />}
      </IconContainer>
      <Text $level={level}>{Children.toArray(children)}</Text>
    </ListItem>
  );
};

IconContainer.defaultProps = {
  $imgWidth: 20,
};
export default ChecklistItem;
