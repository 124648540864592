import React, { FC } from 'react';
import { styled } from '@mui/material';

interface Props {
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  imgURL?: string;
}

interface StyledProps {
  $width?: string;
  $height?: string;
  $maxWidth?: string;
  $maxHeight?: string;
}


const LogoContainer = styled('div') <StyledProps>`
  width: ${props => props.$width || '250px'};
  height: ${props => props.$height || 'auto'};
  max-width: ${props => props.$maxWidth};
  max-height: ${props => props.$maxHeight};
  margin: auto;
`;

const LogoImg = styled('img') <Props>`
  width: 100%;
  height: auto;
`;

const Logo: FC<Props> = ({
  width,
  height,
  maxWidth,
  maxHeight,
  imgURL
}) => {
  return (
    <LogoContainer $width={width} $height={height} $maxWidth={maxWidth} $maxHeight={maxHeight} className="logo">
      <LogoImg src={imgURL || '/img/vip-logo.png'} />
    </LogoContainer>
  );
}

export default Logo;
