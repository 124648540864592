import React, { FC, MutableRefObject } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

interface Props {
  captchaRef: MutableRefObject<ReCAPTCHA | null>;
  siteKey: string;
  onChange: (value: string | null) => void;
  // https://developers.google.com/recaptcha/docs/language
  hl?: 'nl' | 'en-GB';
  size?: 'compact' | 'normal' | 'invisible';
  // Only relevant for invisible captchas
  badge?: 'bottomright' | 'bottomleft' | 'inline';
}

const Recaptcha: FC<Props> = ({
  captchaRef,
  siteKey,
  onChange,
  hl = 'nl',
  size = 'invisible',
  badge = 'bottomright',
}) => {
  return (
    <ReCAPTCHA
      onChange={onChange}
      ref={captchaRef}
      sitekey={siteKey}
      size={size}
      hl={hl}
      badge={badge}
    />
  );
};

export default Recaptcha;
