import { Branding, DefaultContent } from '@domain/interfaces';

const defaultContent: (branding: Branding) => DefaultContent = (branding) => {
  const content = {
    infoBlock: {
      title: `Meld uw schade bij ${branding.brandName}`,
      'signature-text': null,
      'signature-logo': '',
      'intro-text-paragraphs': [
        { text: 'Vind een aangesloten herstelbedrijf bij u in de buurt.' },
        {
          text:
            'Heeft u auto- of ruitschade? Geen zorgen, met onze bedrijvenzoeker vindt u eenvoudig een herstelbedrijf bij u in de buurt.',
        },
        {
          text:
            'Deze bedrijven zijn allemaal aangesloten bij ons netwerk en voldoen daarom aan hoge kwaliteitseisen.',
        },
        {
          text:
            'Daarnaast levert het u ook voordeel op, zoals korting op uw eigen risico.',
        },
      ],
      'show-intro-text-list': false,
      'intro-text-list': null,
    },
    formBlock: {
      'card-one': {
        button: 'Schadedossier aanmaken',
        title: null,
        subtitle: null,
        paragraphs: null,
        list: null,
        'closing-paragraphs': null,
      },
      'card-two': {
        button: 'Schadedossier aanvullen',
        title: 'Dank voor uw informatie!',
        subtitle: 'We hebben u een bevestigingsmail gestuurd.',
        paragraphs: [
          {
            text:
              'Rond direct uw schadedossier af met onderstaande knop of doe dit op een later moment via de link in de bevestigingsmail.',
          },
        ],
        list: null,
        'closing-paragraphs': null,
      },
    },
  };
  return content;
};

export default defaultContent;
