import { endpoints } from '@domain/constants';
import { makeCall } from '@domain/helpers';
import { ServerResponseLPCheck, LPCheckRequestBody } from '@domain/interfaces';

type HeaderWithFID = { PID: String; FID: String }
type HeaderDefault = { PID: String }
type HeaderIdentificator =
  | HeaderWithFID
  | HeaderDefault

type PostHeadersLP = { 'Content-Type': 'application/json' } & (HeaderWithFID | HeaderDefault)

// call example:  licensePlateCheck({ PID: pid }, { damageDate: "2019-12-30T11:00:00.000Z", licensePlate: "09SPDN", captchaCode: "string", siteKey: "string" })

async function licensePlateCheck(
  header: HeaderIdentificator,
  body: LPCheckRequestBody,
): Promise<ServerResponseLPCheck> {
  const headers: PostHeadersLP = {
    'Content-Type': 'application/json',
    ...header,
  }

  const data = await makeCall<ServerResponseLPCheck>(
    endpoints.licensePlateCheck,
    {
      method: 'POST',
      headers: headers,
      body: body,
    },
  );
  return data as ServerResponseLPCheck;
}

export default licensePlateCheck;
