import React, {
  FC,
  ChangeEvent,
  useMemo,
  memo,
  useState,
  useCallback,
} from 'react';
import { styled } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';
import { useWindowSize, useInputBlur } from '@domain/hooks';
import { InputName } from '@domain/interfaces';
import { Colors, Scaling } from '@domain/theming';
import TextField from '@mui/material/TextField';
const PREFIX = 'index';

const classes = {
  textField: `${PREFIX}-textField`,
  inputRoot: `${PREFIX}-inputRoot`,
  inputInput: `${PREFIX}-inputInput`,
  inputError: `${PREFIX}-inputError`,
  inputLabelRoot: `${PREFIX}-inputLabelRoot`,
  inputLabelShrink: `${PREFIX}-inputLabelShrink`,
  inputUnderline: `${PREFIX}-inputUnderline`,
  inputLabelClasses: `${PREFIX}-inputLabelClasses`,
  inputDisabled: `${PREFIX}-inputDisabled`,
  inputLabelDisabled: `${PREFIX}-inputLabelDisabled`,
  focused: `${PREFIX}-focused`,
  inputLabelError: `${PREFIX}-inputLabelError`,
  dense: `${PREFIX}-dense`,
  menu: `${PREFIX}-menu`
};

const StyledTextField
  = styled(TextField, {
    shouldForwardProp: (propName) => !propName.toString().startsWith('$')
  }
  )((props: StyledProps) => ({
    [`&.${classes.textField}`]: {
      //main container
      width: '100%',
      margin: props.$dynamicFontSize ? Scaling.scaleUpTo4K(20, props.$vwWidth) + 'px 0' : '20px 0',
      paddingTop: props.$dynamicFontSize ? Scaling.scaleUpTo4K(22, props.$vwWidth) + 'px' : '22px',
      height: 'auto',
    },

    [`& .${classes.inputRoot}`]: {
      //input container
      borderColor: Colors.BLACK,
      color: Colors.BLACK,
      fontFamily: 'Gabriel Sans',
    },

    [`& .${classes.inputInput}`]: {
      textAlign: 'center',
      padding: 0,
      fontSize:
        props.$dynamicFontSize ?
          (isMobile
            ? Scaling.textFontSizeMobile(
              32,
              props.$vwWidth,
            )
            : Scaling.textFontSize(
              32,
              props.$vwWidth,
            )) + 'px' :
          '32px',
      fontWeight: 'bold',
      lineHeight:
        props.$dynamicFontSize ?
          (isMobile
            ? Scaling.textFontSizeMobile(36, props.$vwWidth)
            : Scaling.textFontSize(36, props.$vwWidth)) + 'px' :
          '36px',
      height:
        props.$dynamicFontSize ?
          (isMobile
            ? Scaling.textFontSizeMobile(48, props.$vwWidth)
            : Scaling.textFontSize(48, props.$vwWidth)) + 'px' :
          '48px',
      textTransform: (props.$capitals ? 'uppercase' : 'initial'),
      '&::placeholder': {
        fontSize:
          props.$dynamicFontSize ?
            (isMobile
              ? Scaling.textFontSizeMobile(16, props.$vwWidth)
              : Scaling.textFontSize(16, props.$vwWidth)) + 'px' :
            '16px',
      },
    },

    [`& .${classes.inputError}`]: {
      color: Colors.RED,
    },

    [`& .${classes.inputLabelRoot}`]: {
      height:
        props.$dynamicFontSize ?
          (isMobile
            ? Scaling.textFontSizeMobile(48, props.$vwWidth)
            : Scaling.textFontSize(48, props.$vwWidth)) + 'px' :
          '48px',
      maxWidth: '100%',
      top: 'calc(50% + 20px)',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: Colors.BLACK,
      fontFamily: 'inherit',
      fontSize:
        props.viewportWidth ?
          (isMobile
            ? Scaling.textFontSizeMobile(32, props.$vwWidth)
            : Scaling.textFontSize(32, props.$vwWidth)) + 'px' :
          '32px',
      fontWeight: 'bold',
      lineHeight: '36px',
      letterSpacing: '0.03125em',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      transition: '200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
      '&$focused': {
        color: Colors.BLACK,
        fontWeight: 'bold',
      },
      '&$inputLabelError': {
        color: Colors.BLACK,
      },
      '&+$inputRoot': {
        marginTop: 0,
      },
    },

    [`& .${classes.inputLabelShrink}`]: {
      top: 0,
      left: 0,
      transform: 'translate(0, 0) scale(1)',
      color: Colors.BLACK,
      lineHeight: '14px',
      letterSpacing: '0.0625em',
      fontSize:
        props.$dynamicFontSize ?
          (isMobile
            ? Scaling.textFontSizeMobile(16, props.$vwWidth)
            : Scaling.textFontSize(16, props.$vwWidth)) + 'px' :
          '16px',
      transition: '200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
    },

    [`& .${classes.inputUnderline}`]: {
      '&$inputError': {
        '&:before': {
          borderColor: Colors.RED,
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: `1px solid ${Colors.RED}`,
        },
        '&:after': {
          borderBottom: `2px solid ${Colors.RED}`,
        },
      },
      '&:before': {
        borderColor: Colors.BLACK,
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: `1px solid ${Colors.BLACK}`,
      },
      '&:after': {
        borderBottom: `2px solid ${Colors.BLACK}`,
      },
    },

    [`& .${classes.inputLabelClasses}`]: {},
    [`& .${classes.inputDisabled}`]: {},
    [`& .${classes.inputLabelDisabled}`]: {},
    [`& .${classes.focused}`]: {},
    [`& .${classes.inputLabelError}`]: {
      color: Colors.BLACK
    },

    [`& .${classes.dense}`]: {
      marginTop: 19,
    },

    [`& .${classes.menu}`]: {
      width: 200,
    }
  }));

interface Props {
  dynamicFontSize?: boolean;
  label: string;
  name: InputName;
  onChange(e: ChangeEvent<HTMLInputElement>): void;
  placeholder: string;
  // textAlign?: 'left' | 'center';
  type: 'email' | 'text' | 'date';
  vwWidth?: any;
  viewportWidth?: number;
  onBlur?: () => void;
  error: boolean;
  disabled?: boolean;
  capitals?: boolean;
  value: string;
}

interface StyledProps {
  $dynamicFontSize?: boolean;
  $vwWidth?: any;
  viewportWidth?: number;
  $capitals?: boolean;
}

const Input: FC<Props> = ({
  dynamicFontSize,
  label,
  name,
  onChange,
  disabled,
  type,
  placeholder,
  onBlur = (() => { return }),
  error,
  capitals = false,
  value,
}) => {
  const [inputEl, setInputEl] = useState<HTMLInputElement | null>(null);

  useInputBlur(inputEl, onBlur);

  const { vwWidth } = useWindowSize();


  const inputProps = React.useMemo(
    () => ({
      classes: {
        root: classes.inputRoot,
        underline: classes.inputUnderline,
        disabled: classes.inputDisabled,
        error: classes.inputError,
        input: classes.inputInput,
      },
      spellCheck: false,
    }),
    [classes],
  );

  const setInputRef = useCallback((el: HTMLInputElement) => setInputEl(el), []);

  const inputLabelProps = useMemo(
    () => ({
      htmlFor: name,
      classes: {
        root: classes.inputLabelRoot,
        shrink: classes.inputLabelShrink,
        focused: classes.focused,
        disabled: classes.inputLabelDisabled,
        error: classes.inputLabelError,
      },
      shrink: true,
    }),
    [classes, name],
  );

  return (
    <StyledTextField
      $dynamicFontSize={dynamicFontSize}
      $vwWidth={vwWidth}
      $capitals={capitals}
      required
      variant='standard'
      id={name}
      disabled={disabled || false}
      label={label}
      className={classes.textField}
      name={name}
      onChange={onChange}
      type={type}
      inputRef={setInputRef}
      placeholder={placeholder}
      error={error}
      InputProps={inputProps}
      InputLabelProps={inputLabelProps}
      value={value}
    />
  );
};

export default memo(Input);
