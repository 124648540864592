import React, { FC } from 'react';
import { styled } from '@mui/material';

import { Colors, Shadows } from '@domain/theming';

interface Props {
  iconOnly?: boolean,
  onClick?: () => void,
}

const ButtonContainer = styled('button') <Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  padding: 2px 0 0;

  background: ${Colors.WHITE};
  border: 0;
  border-radius: 50%;
  box-shadow: ${props => props.iconOnly ? '' : Shadows().Shadow6};

  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 2;
  -webkit-transform: translate(-50%, -150%);
  transform: translate(-50%, -150%);
  text-align: center;
  
  &:hover {
    .chevron-down {
      transform: translateY(2px);
      + .chevron-down {
        transform: translateY(-2px);
      }
    }
  }
`;

const SVGIcon = styled('svg')`
  max-width: 22px;
  margin: 0 auto;
  transition: .33s ease-in-out;
`;


const ScrollDownButton: FC<Props> = ({
  iconOnly = false,
  onClick,
}) => {

  const chevronPath = (
    <path fill={Colors.GREY_DARK} d="M32,3.77A3.59,3.59,0,0,1,31,6.3L18.42,18.82a3.62,3.62,0,0,1-5,0L.85,6.21a3.58,3.58,0,0,1,5.09-5l10,10,9.88-10a3.56,3.56,0,0,1,5,0A3.53,3.53,0,0,1,32,3.77Z" />
  );

  return (
    <ButtonContainer
      iconOnly={iconOnly}
      onClick={onClick}
    >
      <SVGIcon
        className='chevron-down'
        viewBox='0 0 32 20'
      >{chevronPath}</SVGIcon>
      <SVGIcon
        className='chevron-down'
        viewBox='0 0 32 20'
      >{chevronPath}</SVGIcon>
    </ButtonContainer>
  )
}


export default ScrollDownButton;
